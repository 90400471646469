import { createInitialState as createInitialBusinessDisplayOptions } from './BusinessDisplayOptionsFilters';
import { createInitialState as createInitialEmployeeDisplayOptions } from './EmployeeDisplayOptionsFilters';
import { createInitialState as createInitialFilterGroups } from './FilterGroups';
import { createInitialState as createInitialStatistics } from './StatisticsFilters';
import { createInitialState as createInitialViewMode } from './ViewFilters';

export const createInitialState = (filters, prevFilters) => ({
  ...filters,
  scheduleSelectedFilter: {
    ...filters.scheduleSelectedFilter,
    ...createInitialViewMode(filters, prevFilters),
    ...createInitialStatistics(filters),
    filterGroups: createInitialFilterGroups(filters),
    employeeListDisplayOptions: createInitialEmployeeDisplayOptions(filters),
    businessListDisplayOptions: createInitialBusinessDisplayOptions(filters),
  },
});
