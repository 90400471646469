import { useCallback } from 'react';

import { ShiftItem } from '../../Main/types';

export const useRemoveShifts = (shiftsData: ShiftItem[]) =>
  useCallback(
    (shiftsToDelete: ShiftItem[] = []) => {
      const shiftIdsToDelete = shiftsToDelete.map(({ id }) => id);
      const existingShifts = shiftsData;
      const shiftsWithoutDeleted = existingShifts.filter(
        ({ id }) => !shiftIdsToDelete.includes(id),
      );

      const updatedShifts = shiftsWithoutDeleted;

      return updatedShifts;
    },
    [shiftsData],
  );
