import React from 'react';

import {
  globalHistory as reachGlobalHistory,
  navigate as reachGlobalNavigate,
} from '@reach/router-original';

import { reactRouterDevToggle } from '@ManagerPortal/featureToggles';

import {
  BrowserLocationProvider,
  navigate as reactGlobalNavigate,
} from './BrowserLocationProvider';

const BrowserLocationProviderWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  if (reactRouterDevToggle) {
    return <BrowserLocationProvider>{children}</BrowserLocationProvider>;
  }

  return <>{children}</>;
};

/**
 * @deprecated Do not use global navigation, this is a backwards compatibility hack for reach router
 * @param {string} to the route to navigate to
 * @param {object | undefined} options NavigateOptions
 * @returns {void}
 */
const navigate = (to: string, options?: object) => {
  if (reactRouterDevToggle) {
    return reactGlobalNavigate(to, options);
  }

  return reachGlobalNavigate(to, options);
};

export { BrowserLocationProviderWrapper as BrowserLocationProvider };

export { navigate };

export const getGlobalHistory = () => {
  if (reactRouterDevToggle) {
    return false;
  }

  return reachGlobalHistory;
};
