export const SHIFT_PROPS = Object.freeze({
  EMPLOYEE_ID: 'employee_id',
  GROUP_ID_UNIT: 'groupIdUnit',
  GROUP_ID_SECTION: 'groupIdSection',
  COMMENT: 'comment',
  CHANGE_OPTION: 'changeOption',
  EVENT_NAME: 'eventName',
  STATUS: 'status',
  SHIFT_ID: 'id',
  START_TIME_SHIFT: 'startTimeShift',
  END_TIME_SHIFT: 'endTimeShift',
  FROM_DATE: 'fromDate',
  TO_DATE: 'toDate',
  ERROR_MESSAGES: 'errorMessages',
});

export const SHIFT_UPDATE_STATUS = Object.freeze({
  ERROR: 'error',
  SUCCESS: 'success',
});

export const SHIFT_EVENTS = Object.freeze({
  CREATE: 'schedule_shift_create',
  DELETE: 'schedule_shift_delete',
  UPDATE: 'schedule_shift_edit',
});

export const SHIFT_UPDATE_RETURN_TYPES = Object.freeze({
  SINGLE: 'single',
  BULK: 'bulk',
});
