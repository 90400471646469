import { useCallback } from 'react';

import { TIsoFormat } from '@shared/utils/time/types';

import { fetchPunchesByEmployeeIds } from '@ManagerPortal/api/schedule/punches';
import { PunchApiData } from '@ManagerPortal/types/apiResponses/punch';

interface InvalidateForEmployeesProps {
  groupId: number;
  startDate: TIsoFormat;
  endDate: TIsoFormat;
  punchesData: PunchApiData[] | undefined;
  setPunches: (punches: PunchApiData[]) => void;
}

export const useInvalidateForEmployees = ({
  groupId,
  startDate,
  endDate,
  punchesData,
  setPunches,
}: InvalidateForEmployeesProps) =>
  useCallback(
    (employeeIds: number[] = []) => {
      if (employeeIds.length !== 0) {
        return fetchPunchesByEmployeeIds(
          groupId,
          startDate,
          endDate,
          employeeIds,
        ).then((res) => {
          if (!res) throw new Error('No response');
          const employeePunches = res.body || [];
          const updatedPunches = (punchesData || [])
            .filter(
              ({ employeeId }) =>
                employeeId && !employeeIds.includes(employeeId),
            )
            .concat(employeePunches);
          setPunches(updatedPunches);
        });
      }
    },
    [endDate, groupId, punchesData, setPunches, startDate],
  );
