import { useMemo, useState } from 'react';

import { useDeepEqualEffect } from '@shared/components/hooks/useDeepEqualEffect';
import {
  addDays,
  addUnits,
  difference,
  endOf,
  format,
  isSameOrAfter,
  newDate,
  startOf,
  subtractDays,
  subtractUnits,
} from '@shared/utils/time';
import { ISO_FORMAT } from '@shared/utils/time/constants';

import useDebounce from '@ManagerPortal/components/hooks/useDebounce';

import { Timeframes as TIME_FRAME } from '../../../../../../../shared/constants';
import { useFilters } from '../FiltersContext';

const formatToISO = (date) => format(date, ISO_FORMAT);

export const VIEW_MODE = Object.freeze({
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  CUSTOM: 'custom',
});

const getState = (filters = {}) => filters?.scheduleSelectedFilter ?? {};

export const createInitialState = (filters = {}, prevFilters = {}) => {
  const defaultDateTime = newDate();
  const prevState = getState(prevFilters);
  const {
    viewMode = prevState.viewMode || VIEW_MODE.DAY,
    startDateTime = prevState.startDateTime ||
      formatToISO(startOf(defaultDateTime, viewMode)),
    endDateTime = prevState.endDateTime ||
      formatToISO(endOf(defaultDateTime, viewMode)),
  } = getState(filters);

  return { viewMode, startDateTime, endDateTime };
};

const TIME_FRAME_VIEW_MODE_DICT = Object.freeze({
  [TIME_FRAME.SINGLEDAY]: VIEW_MODE.DAY,
  [TIME_FRAME.MULTIDAY_WEEK]: VIEW_MODE.WEEK,
  [TIME_FRAME.MULTIDAY_MONTH]: VIEW_MODE.MONTH,
  [TIME_FRAME.MULTIDAY]: VIEW_MODE.CUSTOM,
  [VIEW_MODE.DAY]: TIME_FRAME.SINGLEDAY,
  [VIEW_MODE.WEEK]: TIME_FRAME.MULTIDAY_WEEK,
  [VIEW_MODE.MONTH]: TIME_FRAME.MULTIDAY_MONTH,
  [VIEW_MODE.CUSTOM]: TIME_FRAME.MULTIDAY,
});

export const timeFrameToViewMode = (timeFrame) =>
  TIME_FRAME_VIEW_MODE_DICT[timeFrame];
export const viewModeToTimeFrame = (viewMode) =>
  TIME_FRAME_VIEW_MODE_DICT[viewMode];

const DATE_ACTION = Object.freeze({
  NEXT: 'NEXT',
  PREV: 'PREV',
  GO_TO_DATE: 'GO_TO_DATE',
  GO_TO_TODAY: 'GO_TO_TODAY',
  FOCUS_DATE: 'FOCUS_DATE',
});

const createUnitOfTimeReducer = (unit) => (state, action) => {
  switch (action.type) {
    case DATE_ACTION.NEXT: {
      const nextDate = addUnits(state.startDateTime, 1, unit);
      const startDate = startOf(nextDate, unit);
      const endDate = endOf(nextDate, unit);
      return {
        ...state,
        startDateTime: formatToISO(startDate),
        endDateTime: formatToISO(endDate),
      };
    }

    case DATE_ACTION.PREV: {
      const nextDate = subtractUnits(state.startDateTime, 1, unit);
      const startDate = startOf(nextDate, unit);
      const endDate = endOf(nextDate, unit);
      return {
        ...state,
        startDateTime: formatToISO(startDate),
        endDateTime: formatToISO(endDate),
      };
    }

    case DATE_ACTION.GO_TO_DATE: {
      const { startDate: nextDate } = action.payload;
      const startDate = startOf(nextDate, unit);
      const endDate = endOf(nextDate, unit);
      return {
        ...state,
        startDateTime: formatToISO(startDate),
        endDateTime: formatToISO(endDate),
      };
    }

    case DATE_ACTION.GO_TO_TODAY: {
      const nextDate = newDate();
      const startDate = startOf(nextDate, unit);
      const endDate = endOf(nextDate, unit);
      return {
        ...state,
        startDateTime: formatToISO(startDate),
        endDateTime: formatToISO(endDate),
      };
    }

    case DATE_ACTION.FOCUS_DATE: {
      const { date: nextDate } = action.payload;
      const startDate = startOf(nextDate, 'day');
      const endDate = endOf(nextDate, 'day');
      return {
        ...state,
        viewMode: VIEW_MODE.DAY,
        startDateTime: formatToISO(startDate),
        endDateTime: formatToISO(endDate),
      };
    }

    default:
      throw new Error(`Unhandled action type "${action.type}"`);
  }
};

const dailyReducer = createUnitOfTimeReducer('day');
const weeklyReducer = createUnitOfTimeReducer('week');
const monthlyReducer = createUnitOfTimeReducer('month');

export const MAX_CUSTOM_PERIOD = 62; // days

const getDayRange = (a, b) => Math.abs(difference(a, b, 'days'));

const customReducer = (state, action) => {
  switch (action.type) {
    case DATE_ACTION.NEXT: {
      const range = getDayRange(state.endDateTime, state.startDateTime);
      const nextStartDate = startOf(addDays(state.endDateTime, 1), 'day');
      const nextEndDate = endOf(addDays(nextStartDate, range), 'day');
      return {
        ...state,
        startDateTime: formatToISO(nextStartDate),
        endDateTime: formatToISO(nextEndDate),
      };
    }

    case DATE_ACTION.PREV: {
      const range = getDayRange(state.endDateTime, state.startDateTime);
      const nextEndDate = endOf(subtractDays(state.startDateTime, 1), 'day');
      const nextStartDate = startOf(subtractDays(nextEndDate, range), 'day');
      return {
        ...state,
        startDateTime: formatToISO(nextStartDate),
        endDateTime: formatToISO(nextEndDate),
      };
    }

    case DATE_ACTION.GO_TO_DATE: {
      const { startDate, endDate } = action.payload;
      let nextEndDate = endDate ?? state.endDateTime;
      if (isSameOrAfter(startDate, nextEndDate)) {
        nextEndDate = addDays(startDate, 1);
      } else if (getDayRange(startDate, nextEndDate) >= MAX_CUSTOM_PERIOD - 1) {
        nextEndDate = addDays(startDate, MAX_CUSTOM_PERIOD - 1);
      }
      return {
        ...state,
        startDateTime: formatToISO(startOf(startDate, 'day')),
        endDateTime: formatToISO(endOf(nextEndDate, 'day')),
      };
    }

    case DATE_ACTION.GO_TO_TODAY: {
      const nextStartDate = startOf(newDate(), 'day');
      const range = getDayRange(state.startDateTime, state.endDateTime);
      const nextEndDate = endOf(addDays(nextStartDate, range), 'day');
      return {
        ...state,
        startDateTime: formatToISO(nextStartDate),
        endDateTime: formatToISO(nextEndDate),
      };
    }

    case DATE_ACTION.FOCUS_DATE: {
      const { date: nextDate } = action.payload;
      const startDate = startOf(nextDate, 'day');
      const endDate = endOf(nextDate, 'day');
      return {
        ...state,
        viewMode: VIEW_MODE.DAY,
        startDateTime: formatToISO(startDate),
        endDateTime: formatToISO(endDate),
      };
    }

    default:
      throw new Error(`Unhandled action type "${action.type}"`);
  }
};

// state machine
const datesReducer = (state, action) => {
  switch (state.viewMode) {
    case VIEW_MODE.DAY:
      return dailyReducer(state, action);
    case VIEW_MODE.WEEK:
      return weeklyReducer(state, action);
    case VIEW_MODE.MONTH:
      return monthlyReducer(state, action);
    case VIEW_MODE.CUSTOM:
      return customReducer(state, action);
    default:
      throw new Error(`unhandled viewMode: ${state.viewMode}`);
  }
};

const viewModeReducer = (state, nextViewMode) => {
  switch (nextViewMode) {
    case VIEW_MODE.DAY:
      return {
        ...state,
        viewMode: VIEW_MODE.DAY,
        endDateTime: formatToISO(endOf(state.startDateTime, 'day')),
      };

    case VIEW_MODE.WEEK: {
      const startDate = startOf(state.startDateTime, 'week');
      const endDate = endOf(state.startDateTime, 'week');
      return {
        ...state,
        viewMode: VIEW_MODE.WEEK,
        startDateTime: formatToISO(startDate),
        endDateTime: formatToISO(endDate),
      };
    }

    case VIEW_MODE.MONTH: {
      const startDate = startOf(state.startDateTime, 'month');
      const endDate = endOf(state.startDateTime, 'month');
      return {
        ...state,
        viewMode: VIEW_MODE.MONTH,
        startDateTime: formatToISO(startDate),
        endDateTime: formatToISO(endDate),
      };
    }

    case VIEW_MODE.CUSTOM:
      return {
        ...state,
        viewMode: VIEW_MODE.CUSTOM,
      };

    default:
      throw new Error(`Received invalid viewMode: ${nextViewMode}`);
  }
};

// TODO: Not happy with the name :/
export const useScheduleDates = () => {
  const { filters, handleChange } = useFilters();

  const datesState = useMemo(() => {
    const initialDateState = createInitialState(filters?.data);
    if (initialDateState.viewMode === 'week') {
      const start = format(
        startOf(initialDateState.startDateTime, 'week'),
        ISO_FORMAT,
      );
      if (start !== initialDateState.startDateTime) {
        return {
          ...initialDateState,
          startDateTime: start,
          endDateTime: format(endOf(addDays(start, 6), 'day'), ISO_FORMAT),
        };
      }
    }
    return initialDateState;
  }, [filters?.data]);

  // Use this state for reducer when we want to update the dates displayed to user, but not trigger api calls based on start/end time until debounce timer is done.
  const [currentViewDates, setCurrentViewDates] = useState(datesState);

  useDeepEqualEffect(() => {
    setCurrentViewDates(datesState);
  }, [datesState]);

  const { endDateTime, startDateTime, viewMode } = datesState;

  const handleChangeDebounced = useDebounce(
    (update) => handleChange(update),
    200,
  );

  const nextDate = () => {
    const viewDatesUpdate = datesReducer(currentViewDates, {
      type: DATE_ACTION.NEXT,
    });
    setCurrentViewDates(viewDatesUpdate);
    handleChangeDebounced(viewDatesUpdate);
    return viewDatesUpdate;
  };

  const prevDate = () => {
    const viewDatesUpdate = datesReducer(currentViewDates, {
      type: DATE_ACTION.PREV,
    });
    setCurrentViewDates(viewDatesUpdate);
    handleChangeDebounced(viewDatesUpdate);
    return viewDatesUpdate;
  };

  const goToDate = ({ startDate, endDate }) =>
    handleChange(
      datesReducer(datesState, {
        type: DATE_ACTION.GO_TO_DATE,
        payload: { startDate, endDate },
      }),
    );

  const goToToday = () =>
    handleChange(datesReducer(datesState, { type: DATE_ACTION.GO_TO_TODAY }));

  const focusDate = (date) =>
    handleChange(
      datesReducer(datesState, {
        type: DATE_ACTION.FOCUS_DATE,
        payload: { date },
      }),
    );

  const setViewMode = (nextViewMode) =>
    handleChange(viewModeReducer(datesState, nextViewMode));

  return {
    viewMode,
    setViewMode,
    nextDate,
    prevDate,
    goToDate,
    goToToday,
    focusDate,
    endDateTime,
    startDateTime,
    isPlaceholderData: !filters.data.scheduleSelectedFilter,
  };
};
