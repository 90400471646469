import React, { useState } from 'react';

import { Button, Checkbox, Icon, Stack, Text } from '@quinyx/ui';
import DOMPurify from 'dompurify';

import { logout } from '@shared/api/authentication';
import { useApi, useFetch } from '@shared/containers/hooks/api';
import { getCompanyAlias } from '@shared/utils/groups';

import {
  acceptTerms as acceptTermsApi,
  fetchTermsByToken,
} from '@ManagerPortal/api/terms';

import styles from './index.module.scss';

const HtmlRenderer = ({ html }: { html: string }) => {
  const purifiedHtml = DOMPurify.sanitize(html, {
    ADD_ATTR: ['target', 'rel'],
  });

  return (
    <div className={styles.terms__content}>
      <div
        data-test-id="terms-content"
        className={styles.terms__content__inner}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={
          purifiedHtml ? { __html: purifiedHtml } : undefined
        }
      />
    </div>
  );
};

export const TermsAndConditions = ({ token }: { token: string }) => {
  const [accept, setAccept] = useState(false);
  const response = useFetch(fetchTermsByToken, [token]);

  const { terms } = response?.data || {};

  const [acceptTermsStatus, acceptTerms] = useApi(acceptTermsApi);
  const error = acceptTermsStatus?.errors?.mainMessage;

  const companyAlias = getCompanyAlias();

  const handleContinue = () => {
    acceptTerms(token)
      .then(() => window.location.reload())
      .catch((e) => e);
  };

  const handleCancel = () => {
    sessionStorage.clear();
    logout()
      .then(() =>
        window.location.assign(companyAlias ? `/${companyAlias}` : '/'),
      )
      .catch((e) => e);
  };

  return (
    <Stack spacing="m" className={styles.terms} alignItems="center">
      {terms && <HtmlRenderer html={terms} />}

      <Stack
        spacing="m"
        p="xl"
        alignItems="center"
        className={styles.terms__actions}>
        {error && (
          <Stack
            data-test-id="terms-error"
            direction="row"
            spacing="s"
            justifyContent="center">
            <Icon
              icon="warningNoOverride"
              height={16}
              width={16}
              color="DangerMain"
            />
            <Text color="DangerMain" variant="xSmallRegular">
              {error}
            </Text>
          </Stack>
        )}

        {/* TODO Matt Muirhead: Add language provider to shared context */}
        <Checkbox
          data-test-id="terms-checkbox"
          label="I confirm that I have read, consent and agree to the Terms and Conditions of use."
          checked={accept}
          onChange={() => setAccept(!accept)}
        />

        <Stack direction="row" spacing="m" justifyContent="center">
          <Button
            data-test-id="terms-cancel"
            text="Cancel"
            variant="outlined"
            onClick={handleCancel}
          />
          <Button
            data-test-id="terms-continue"
            text="Continue"
            disabled={!accept || error}
            onClick={handleContinue}
            isLoading={acceptTermsStatus.isLoading}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
