import { useCallback, useEffect, useMemo } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import { errorMessageNot403 } from '@shared/api/utils';
import { notify } from '@shared/components/Notifications/utils';
import { isOriginalError } from '@shared/containers/hooks/api/utils';
import { indexArrayByPropName, isNil } from '@shared/utils';

import {
  fetchAbsences,
  fetchAbsencesByEmployeeIds,
} from '@ManagerPortal/api/schedule/absences';

import { useFilterFeatures } from '../../Main/Filters/FiltersProvider';
import { AbsenceItem } from '../../Main/types';
import { SCHEDULE_DATA_CACHE_TIME } from '../useFetchScheduleData';
import { useGetScheduleDataHookParams } from '../utils/useGetScheduleDataHookParams';

export const QUERY_KEY = '/v1/absences/by-group';

export const useAbsencesQuery = () => {
  const { isPlaceholderFilterData, currentGroupId, start, end } =
    useGetScheduleDataHookParams();
  const features = useFilterFeatures();
  const queryClient = useQueryClient();
  const enabled = !isPlaceholderFilterData && features.includes('absences');

  const absencesQuery = useQuery({
    queryKey: [QUERY_KEY, currentGroupId, start, end],
    queryFn: () =>
      fetchAbsences(currentGroupId, start, end).then((res) => res?.body),
    retry: (failureCount, error) => {
      if (isOriginalError(error) && error.status === 402) {
        return false;
      }
      return failureCount < 2;
    },
    enabled,
    cacheTime: SCHEDULE_DATA_CACHE_TIME,
  });

  const indexedAbsences = useMemo(
    () => (absencesQuery.data ? indexArrayByPropName(absencesQuery.data) : {}),
    [absencesQuery.data],
  );

  useEffect(() => {
    const errorMessage = errorMessageNot403(absencesQuery.error);
    if (errorMessage) {
      notify(`Absences: ${errorMessage}`, 'error');
    }
  }, [absencesQuery?.error]);

  const updateEmployeeAbsencesInState = useCallback(
    (employeeIds: number[], newAbsences: AbsenceItem[]) => {
      if (isNil(indexedAbsences)) {
        queryClient.setQueryData<typeof absencesQuery.data>(
          [QUERY_KEY, currentGroupId, start, end],
          newAbsences,
        );
        return;
      }
      const updatedAbsences = Object.values(indexedAbsences)
        .filter(({ employeeId }) => !employeeIds.includes(employeeId))
        .concat(newAbsences);

      queryClient.setQueryData<typeof absencesQuery.data>(
        [QUERY_KEY, currentGroupId, start, end],
        updatedAbsences,
      );
    },
    [absencesQuery, start, currentGroupId, indexedAbsences, queryClient, end],
  );

  const invalidateForEmployees = useCallback(
    (employeeIds: number[]) => {
      fetchAbsencesByEmployeeIds(currentGroupId, start, end, employeeIds)
        // @ts-expect-error Will work when fetchAbsencesByEmployeeIds is
        // converted to ts
        .then(({ body: employeeAbsences }) => {
          updateEmployeeAbsencesInState(employeeIds, employeeAbsences);
        })
        .catch((e) => e);
    },
    [start, currentGroupId, end, updateEmployeeAbsencesInState],
  );

  const updateState = useCallback(
    (absencesToUpdate: AbsenceItem[]) => {
      const indexedAbsencesToUpdate = indexArrayByPropName(absencesToUpdate);
      if (isNil(indexedAbsences)) {
        queryClient.setQueryData(
          [QUERY_KEY, currentGroupId, start, end],
          indexedAbsencesToUpdate,
        );
        return;
      }

      const updatedAbsences = Object.values(indexedAbsences).map((absence) => {
        return indexedAbsencesToUpdate[absence.id] || absence;
      });

      queryClient.setQueryData(
        [QUERY_KEY, currentGroupId, start, end],
        updatedAbsences,
      );
    },
    [start, currentGroupId, indexedAbsences, queryClient, end],
  );

  const invalidate = useCallback(
    () => queryClient.invalidateQueries([QUERY_KEY]),
    [queryClient],
  );

  return useMemo(
    () => ({
      isLoading: absencesQuery.isLoading,
      isError: absencesQuery.isError,
      isSuccess: absencesQuery.isSuccess,
      errors: absencesQuery.error,
      data: indexedAbsences,
      invalidate,
      invalidateForEmployees,
      updateState,
    }),
    [
      absencesQuery.error,
      absencesQuery.isError,
      absencesQuery.isLoading,
      absencesQuery.isSuccess,
      indexedAbsences,
      invalidate,
      invalidateForEmployees,
      updateState,
    ],
  );
};
