import { getRequestUrl } from '@shared/api/utils';
import { StrictOmit } from '@shared/types';
import { stringifyUrl } from '@shared/utils/route';
import type { TIsoFormat } from '@shared/utils/time/types';

import { get, post, put } from '@ManagerPortal/api';
import { TScheduleableProps } from '@ManagerPortal/containers/Schedule/Main/SchedulerActionsProvider/types';
import {
  ShiftItem,
  ShiftItemCompleteFromApi,
  ShiftItemDraftFromApi,
  WorkerItem,
} from '@ManagerPortal/containers/Schedule/Main/types';

import { TDraftShiftMetaData, TDraftShiftOptions } from './types';

export * from './index-old';

/**
 * We need to do this since properties that are not present on the shift object may be
 * relevant to the getDraftShift call, such as employeeIds.
 * Properties passed in via optionsFromArgs
 * represent state that does not yet exist on the form since getDraftShift is called
 * before react state updates occur on the component.
 * @param {TDraftShiftOptions | undefined} optionsFromArgs TDraftShiftOptions | undefined
 * @param {TDraftShiftOptions | undefined} optionsFromForm TDraftShiftOptions | undefined
 * @returns {TDraftShiftOptions | undefined} TDraftShiftOptions | undefined
 */
export const getDraftShiftOptions = (
  optionsFromArgs?: TDraftShiftOptions,
  optionsFromForm?: TDraftShiftOptions,
): TDraftShiftOptions | undefined => {
  if (!optionsFromArgs && !optionsFromForm) {
    return undefined;
  }

  return { ...optionsFromForm, ...optionsFromArgs };
};

const getDraftShiftMetaData = (
  changedAttributes: string[],
  groupId: number,
  options?: TDraftShiftOptions,
): TDraftShiftMetaData => {
  return options?.isMultipleShiftsAssigned?.state
    ? {
        changedAttributes,
        groupId,
        employeeIds: options?.isMultipleShiftsAssigned?.data,
      }
    : {
        changedAttributes,
        groupId,
      };
};

export const getDraftShift = <
  T extends StrictOmit<TScheduleableProps, 'beginTZ' | 'endTZ'>,
>(
  shift: T,
  changedAttributes: string[],
  groupId: number,
  options?: TDraftShiftOptions,
) => {
  const metaData = getDraftShiftMetaData(changedAttributes, groupId, options);

  return put<
    ShiftItemDraftFromApi & {
      affectedProperties: (keyof ShiftItemDraftFromApi)[];
      autoSelectCostCentre?: boolean;
    }
  >(getRequestUrl('schedule:shift:draft'), {
    metaData,
    shift,
  });
};

export const fetchAllShifts = (
  groupId: number,
  startDate: TIsoFormat,
  endDate: TIsoFormat,
) =>
  // TODO Michal Sadowski:
  // This should use Shift, not ShiftItem type
  // Needs to be fixed together with overall data processing overhaul
  get<ShiftItem[]>(
    stringifyUrl(`/v2/schedule/shifts/by-group/${groupId}`, {
      startDate,
      endDate,
    }),
  );

const evaluateUrl = (
  groupId: number,
  startDate: TIsoFormat | undefined,
  endDate: TIsoFormat | undefined,
) =>
  startDate && endDate
    ? stringifyUrl(`/v1/employee/groups/${groupId}/workersof/by-ids`, {
        startDate,
        endDate,
      })
    : `/v1/employee/groups/${groupId}/workersof/by-ids`;

export const fetchWorkersOfByEmployeeIds = (
  groupId: number,
  employeeIds: number[],
  startDate?: TIsoFormat,
  endDate?: TIsoFormat,
) => {
  // endpoint accepts optional query params startDate and endDate (see https://quinyx.atlassian.net/browse/QRND-37532)
  const url = evaluateUrl(groupId, startDate, endDate);
  return post<WorkerItem[]>(url, employeeIds);
};

export const fetchShiftsByEmployeeIds = (
  groupId: number,
  start: TIsoFormat,
  end: TIsoFormat,
  employeeIds: number[],
) => {
  return post<ShiftItem[]>(
    stringifyUrl(`/v1/schedule/shifts/by-group/${groupId}/by-employee-ids`, {
      start,
      end,
    }),
    employeeIds,
  );
};

export const fetchCompleteShiftsByIds = (shiftIds: (string | number)[]) =>
  post<ShiftItemCompleteFromApi[]>(
    '/v2/schedule/shifts/by-shift-ids',
    shiftIds,
  );
