import { useCallback, useEffect } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import { ApiResponse } from '@shared/types';

import { salaryWarningsStoreToggle } from '@ManagerPortal/featureToggles';

import { indexArrayByPropName } from '../../../../../shared/utils';
import { fetchSalaryWarnings } from '../../../../api/schedule/salary';
import { SCHEDULE_DATA_CACHE_TIME } from '../useFetchScheduleData';
import { useGetScheduleDataHookParams } from '../utils/useGetScheduleDataHookParams';
import { SalaryWarningDictionaries, SalaryWarnings } from './types';

export const QUERY_KEY = '/v1/schedule/additional-warnings/by-group';
export const isSalaryWarningsStoreToggleOn = salaryWarningsStoreToggle;
const PLACEHOLDER_DATA: SalaryWarningDictionaries = {
  punchWarnings: {},
  shiftWarnings: {},
  absenceWarnings: [],
  absenceWarningsObj: {},
};

const queryFunction = async (groupId: number, from: string, to: string) => {
  const response: ApiResponse<SalaryWarnings> = await fetchSalaryWarnings(
    groupId,
    from,
    to,
  );
  if (!response) return;

  const indexedWarnings = {
    shiftWarnings: indexArrayByPropName(response.body.shiftWarnings, 'shiftId'),
    punchWarnings: indexArrayByPropName(response.body.punchWarnings, 'punchId'),
    absenceWarnings: response.body.absenceWarnings,
    absenceWarningsObj: indexArrayByPropName(
      response.body.absenceWarnings,
      'leaveApplicationId',
    ),
  };

  return indexedWarnings;
};

// eslint-disable-next-line valid-jsdoc
/** This hook will primarily try to use cached data from React Query.
 * If you want to refetch, please specify second parameter staleTime: 0 */
export const useSalaryWarnings = <TData = SalaryWarningDictionaries>(
  select?: (data?: SalaryWarningDictionaries) => TData,
  staleTime = Infinity,
) => {
  const {
    start: from,
    end: to,
    currentGroupId: groupId,
  } = useGetScheduleDataHookParams();

  const { data } = useQuery({
    queryKey: [QUERY_KEY, groupId, from, to],
    queryFn: () => queryFunction(groupId, from, to),
    placeholderData: PLACEHOLDER_DATA,
    staleTime,
    select,
    enabled: isSalaryWarningsStoreToggleOn,
    cacheTime: SCHEDULE_DATA_CACHE_TIME,
  });
  return data;
};

export const usePrefetchSalaryWarnings = () => {
  const {
    start: from,
    end: to,
    currentGroupId: groupId,
    isPlaceholderFilterData,
  } = useGetScheduleDataHookParams();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!isPlaceholderFilterData && isSalaryWarningsStoreToggleOn) {
      queryClient.prefetchQuery({
        queryKey: [QUERY_KEY, groupId, from, to],
        queryFn: () => queryFunction(groupId, from, to),
        initialData: PLACEHOLDER_DATA,
        cacheTime: SCHEDULE_DATA_CACHE_TIME,
      });
    }
  }, [from, groupId, isPlaceholderFilterData, queryClient, to]);
};

export const useInvalidateSalaryWarnings = () => {
  const queryClient = useQueryClient();
  const {
    start: from,
    end: to,
    currentGroupId: groupId,
  } = useGetScheduleDataHookParams();

  const invalidateSalaryWarningsForEmployees = useCallback(
    (employeeIds: number[]) =>
      fetchSalaryWarnings(groupId, from, to, employeeIds).then(
        (res: ApiResponse<SalaryWarnings>) => {
          const cache = queryClient.getQueryData<SalaryWarningDictionaries>([
            QUERY_KEY,
            groupId,
            from,
            to,
          ]);
          if (!res) return;
          if (!cache) return;

          const { punchWarnings, shiftWarnings, absenceWarnings } = res.body;
          const updatedPunchWarnings = Object.values(cache.punchWarnings)
            .filter(({ employeeId }) => !employeeIds.includes(employeeId))
            .concat(punchWarnings);

          const updatedShiftWarnings = Object.values(cache.shiftWarnings)
            .filter(({ employeeId }) => !employeeIds.includes(employeeId))
            .concat(shiftWarnings);
          const updatedAbsenceWarnings = Object.values(cache.absenceWarnings)
            .filter(({ employeeId }) => !employeeIds.includes(employeeId))
            .concat(absenceWarnings);

          const updatedWarnings = {
            shiftWarnings: indexArrayByPropName(
              updatedShiftWarnings,
              'shiftId',
            ),
            punchWarnings: indexArrayByPropName(
              updatedPunchWarnings,
              'punchId',
            ),
            absenceWarnings: updatedAbsenceWarnings,
            absenceWarningsObj: indexArrayByPropName(
              updatedAbsenceWarnings,
              'leaveApplicationId',
            ),
          };

          // Sync react query cache
          queryClient.setQueryData<SalaryWarningDictionaries>(
            [QUERY_KEY, groupId, from, to],
            updatedWarnings,
          );
        },
      ),
    [from, groupId, queryClient, to],
  );

  const invalidateSalaryWarnings = useCallback(() => {
    queryClient.invalidateQueries([QUERY_KEY, groupId, from, to]);
  }, [from, groupId, queryClient, to]);

  return { invalidateSalaryWarnings, invalidateSalaryWarningsForEmployees };
};
