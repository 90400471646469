import { useCallback, useMemo } from 'react';

import { isNil } from '../../../../../../../shared/utils';
import { FILTER_GROUP, FILTER_NAME } from '../../constants';
import { useFilters } from '../FiltersContext';

export const normalizeFilterGroups = (groups) => {
  return groups.reduce((result, group) => {
    return {
      ...result,
      [group.type]: {
        ...group,
        filters: (group.filters ?? []).reduce((acc, filter) => {
          return {
            ...acc,
            [filter.name]: filter,
          };
        }, {}),
      },
    };
  }, {});
};

export const deNormalizeFilterGroups = (groups) => {
  return Object.values(groups).map((group) => ({
    ...group,
    filters: Object.values(group.filters ?? {}),
  }));
};

// https://javascript.plainenglish.io/javascript-check-if-a-variable-is-an-object-and-nothing-else-not-an-array-a-set-etc-a3987ea08fd7
const isObject = (variable) => {
  return Object.prototype.toString.call(variable) === '[object Object]';
};

export const deepMerge = (target, source) => {
  const destination = {};
  Object.entries(target).forEach(([key, value]) => {
    destination[key] = isObject(value) ? deepMerge({}, value) : value;
  });

  Object.entries(source).forEach(([key, value]) => {
    if (!isObject(value) || !target[key]) {
      destination[key] = isObject(value) ? deepMerge({}, value) : value;
    } else {
      destination[key] = deepMerge(target[key], value);
    }
  });

  return destination;
};

const defaultFilterGroups = Object.values(FILTER_GROUP).reduce(
  (groups, groupType) => {
    return {
      ...groups,
      [groupType]: {
        active: true,
        type: groupType,
        operator: 'OR',
        filters: Object.values(FILTER_NAME)
          .filter((filterName) => filterName.startsWith(groupType))
          .reduce(
            (filters, filterName) => ({
              ...filters,
              [filterName]: {
                name: filterName,
                operator: [FILTER_NAME.PEOPLE_SKILLS].includes(filterName)
                  ? 'AND'
                  : 'OR',
                values: [],
              },
            }),
            {},
          ),
      },
    };
  },
  {},
);

const getState = (filters = {}) =>
  filters.scheduleSelectedFilter?.filterGroups ?? [];

export const createInitialState = (filters = {}) =>
  deNormalizeFilterGroups(
    deepMerge(defaultFilterGroups, normalizeFilterGroups(getState(filters))),
  );

export const useFilterValues = () => {
  const { filters, handleChange } = useFilters();
  const filterGroups = getState(filters?.data);

  const clearFilterValues = useCallback(() => {
    handleChange({
      filterGroups: deNormalizeFilterGroups(defaultFilterGroups),
    });
  }, [handleChange]);

  const filterValues = useMemo(
    () => normalizeFilterGroups(filterGroups),
    [filterGroups],
  );

  return useMemo(
    () => ({
      filterValues,
      clearFilterValues,
    }),
    [clearFilterValues, filterValues],
  );
};

export const useFilterGroups = () => {
  const { filters, handleChange } = useFilters();

  const patchFilters = (partial) => {
    const nextState = deNormalizeFilterGroups(
      deepMerge(normalizeFilterGroups(getState(filters?.data)), partial),
    );

    handleChange({
      filterGroups: nextState,
    });
  };

  const setFilterGroups = (...batch) => {
    const update = batch.reduce((res, { name, isActive, operator }) => {
      res[name] = {};
      if (!isNil(isActive)) {
        res[name].active = isActive;
      }
      if (operator) {
        res[name].operator = operator;
      }
      return res;
    }, {});
    patchFilters(update);
  };

  const filterGroups = getState(filters?.data).reduce(
    (acc, { type, active, operator }) => {
      acc[type] = { isActive: active, operator };
      return acc;
    },
    {},
  );

  return {
    filterGroups,
    setFilterGroups,
  };
};

const filterGroupNames = new Set(Object.values(FILTER_GROUP));
// TODO: memoize
const getFilterGroupName = (filterName) => {
  if (!filterName) return undefined;

  if (filterGroupNames.has(filterName)) return filterName;

  return getFilterGroupName(
    filterName.substring(0, filterName.lastIndexOf('-')),
  );
};

export const useFilterFields = () => {
  const { filters, handleChange } = useFilters();

  const filterGroups = getState(filters?.data);

  const patchFilters = (partial) => {
    const nextState = deNormalizeFilterGroups(
      deepMerge(normalizeFilterGroups(getState(filters?.data)), partial),
    );

    handleChange({
      filterGroups: nextState,
    });
  };

  const setFilterFields = ({ name: filterName, values }) => {
    const filterGroupName = getFilterGroupName(filterName);
    if (filterGroupName) {
      patchFilters({
        [filterGroupName]: { filters: { [filterName]: { values } } },
      });
    }
  };

  const filterFields = filterGroups.reduce((acc, filterGroup) => {
    filterGroup.filters.forEach(({ name, values, operator }) => {
      acc[name] = { values, operator };
    });
    return acc;
  }, {});

  return {
    filterFields,
    setFilterFields,
  };
};

const FILTER_TO_FEATURE = {
  [FILTER_GROUP.SHIFTS]: 'shifts',
  [FILTER_GROUP.PUNCHES]: 'punches',
  [FILTER_GROUP.ABSENCE_REQUESTS]: 'leaveRequests',
  [FILTER_GROUP.ABSENCES]: 'absences',
  [FILTER_GROUP.TASKS]: 'tasks',
  [FILTER_GROUP.UNAVAILABILITY]: 'unavailability',
  [FILTER_GROUP.AVAILABILITY]: 'availability',
};
export const useFilterFeatures = () => {
  const { filterGroups } = useFilterGroups();
  const features = [];
  Object.keys(filterGroups).forEach((filterGroup) => {
    if (filterGroups[filterGroup].isActive) {
      features.push(FILTER_TO_FEATURE[filterGroup]);
    }
  });
  return features;
};

export const useNumberOfAppliedFilters = () => {
  const { filters } = useFilters();
  const filterGroups = getState(filters?.data);

  return filterGroups.reduce((sum, filterGroup) => {
    const n = filterGroup.active
      ? filterGroup.filters.filter(({ values }) => values.length).length
      : 1;
    return sum + n;
  }, 0);
};
