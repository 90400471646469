/* eslint-disable valid-jsdoc */
import { del, get, post, put } from '../..';
import { getRequestUrl } from '../../../../shared/api/utils';
import { stringifyUrl } from '../../../../shared/utils/route';

export const fetchAbsenceReasons = (groupId) => {
  const requestUrl = getRequestUrl('absenceTypes', { groupId });
  return get(requestUrl);
};

export const fetchAbsenceReasonsByEmployee = (
  groupId,
  employeeId,
  start,
  end,
) =>
  get(
    stringifyUrl(
      `/v1/absence-types-settings/absence-types/by-group-id/${groupId}/by-employee-id/${employeeId}`,
      {
        start,
        end,
      },
    ),
  );

export const fetchAbsenceSchedules = (groupId) =>
  get(`/v1/absence-schedule/by-group-id/${groupId}`);

export const fetchAffectedShifts = (
  employeeId,
  start,
  end,
  absenceScheduleId,
  absenceId,
) =>
  get(
    stringifyUrl('/v1/absences/shifts', {
      employeeId,
      start,
      end,
      absenceScheduleId,
      absenceId,
    }),
  );

export const fetchLockedItems = (groupId, from, to, employeeId) =>
  get(
    stringifyUrl(`/v1/schedule/locked-items/by-group/${groupId}`, {
      from,
      to,
      employeeId,
    }),
  );

export const deleteAbsence = (absenceId, urlParams = {}) =>
  del(stringifyUrl(`/v1/schedule/absences/${absenceId}`, { ...urlParams }));

export const fetchAbsenceById = (absenceId) => {
  return get(stringifyUrl(`/v1/absences/${absenceId}`));
};

export const addAbsence = (absence, ignoreValidationRules) => {
  return post(
    stringifyUrl('/v1/schedule/absences', { ignoreValidationRules }),
    absence,
  );
};

export const updateAbsence = (absence, absenceId, ignoreValidationRules) =>
  put(
    stringifyUrl(`/v1/schedule/absences/${absenceId}`, {
      ignoreValidationRules,
    }),
    absence,
  );

/**
 * @param {object} param0
 * @param {Array<number>} param0.absenceIds
 * @param {boolean} [param0.includeEmployeeAttest]
 */
export const attestAbsences = ({ absenceIds, includeEmployeeAttest }) => {
  const url = stringifyUrl('/v1/schedule/absences/by-ids/attest', {
    includeEmployeeAttest,
  });
  return put(url, absenceIds);
};

/**
 * @param {object} param0
 * @param {Array<number>} param0.absenceIds
 * @param {boolean} [param0.includeEmployeeAttest]
 */
export const denyAbsences = ({ absenceIds, includeEmployeeAttest }) => {
  const url = stringifyUrl('/v1/schedule/absences/by-ids/deny', {
    includeEmployeeAttest,
  });
  return put(url, absenceIds);
};

export const fetchAbsencesByEmployeeIds = (
  groupId,
  start,
  end,
  employeeIds,
) => {
  return post(
    stringifyUrl(`/v1/absences/by-group/${groupId}/by-employee-ids`, {
      start,
      end,
    }),
    employeeIds,
  );
};
