import { stringifyUrl } from '@shared/utils/route';

import { del, get, post, put } from '../..';

export const createAvailability = (scheduleAvailability) => {
  const requestUrl = '/v1/schedule-availability/';
  return post(requestUrl, scheduleAvailability);
};

export const updateAvailability = ({ option, ...payload }) => {
  const requestUrl = stringifyUrl('/v1/schedule-availability', {
    changeOption: option,
  });
  return put(requestUrl, payload);
};

export const fetchAvailabilityById = (availabilityId, availabilityDateId) => {
  const requestUrl = `/v1/schedule-availability/${availabilityId}/availability-date/${availabilityDateId}`;
  return get(requestUrl);
};

export const deleteAvailability = (
  availabilityDateId,
  availabilityId,
  changeOption = 'SINGLE',
) =>
  del(
    stringifyUrl(
      `/v1/schedule-availability/${availabilityId}/availability-date/${availabilityDateId}`,
      {
        changeOption,
      },
    ),
  );

export const updateLockPeriodAvailability = (payload) => {
  const requestUrl = '/v1/schedule-availability/locking-period';
  return put(requestUrl, payload);
};

export const fetchSectionsAndUnits = (groupId, employeeId, begin, end) => {
  const requestUrl = `/v1/schedule-availability/groups/${groupId}/employees/${employeeId}/selectable-groups`;
  return get(stringifyUrl(requestUrl, { start: begin, end }));
};

export const getLockPeriodAvailability = (groupId) => {
  const requestUrl = `/v1/schedule-availability/locking-period/groups/${groupId}`;
  return get(stringifyUrl(requestUrl));
};
export const getLockPeriodAvailabilityInfo = (groupId) => {
  const requestUrl = `/v1/schedule-availability/locking-period/groups/${groupId}/info`;
  return get(stringifyUrl(requestUrl));
};
