import { useCallback, useMemo } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import { indexArrayByPropName } from '@shared/utils';

import { fetchShiftTypes } from '@ManagerPortal/api/schedule/shifttypes';
import { useCurrentGroup } from '@ManagerPortal/containers/hooks/account';

import { SCHEDULE_DATA_CACHE_TIME } from '../useFetchScheduleData';

export const QUERY_KEY = '/v1/schedule/shifttypes/by-group';

export const useShiftTypesQuery = () => {
  const { id: groupId } = useCurrentGroup();
  const queryClient = useQueryClient();

  const shiftTypesQuery = useQuery({
    queryKey: [QUERY_KEY, groupId],
    queryFn: () => fetchShiftTypes(groupId).then((res) => res?.body),
    cacheTime: SCHEDULE_DATA_CACHE_TIME,
  });

  const invalidate = useCallback(
    () => queryClient.invalidateQueries([QUERY_KEY]),
    [queryClient],
  );

  const indexedShiftTypes = useMemo(
    () =>
      shiftTypesQuery.data ? indexArrayByPropName(shiftTypesQuery.data) : {},
    [shiftTypesQuery.data],
  );

  return useMemo(
    () => ({
      isLoading: shiftTypesQuery.isLoading,
      isError: shiftTypesQuery.isError,
      isSuccess: shiftTypesQuery.isSuccess,
      errors: shiftTypesQuery.error,
      data: indexedShiftTypes,
      invalidate,
    }),
    [
      shiftTypesQuery.isLoading,
      shiftTypesQuery.isError,
      shiftTypesQuery.isSuccess,
      shiftTypesQuery.error,
      indexedShiftTypes,
      invalidate,
    ],
  );
};
