import { useCallback } from 'react';

import { PunchApiData } from '@ManagerPortal/types/apiResponses/punch';

interface UseUpdateShiftPunchesProps {
  punchesData: PunchApiData[];
  setPunches: (punches: PunchApiData[]) => void;
}

export const useUpdateShiftPunches = ({
  punchesData,
  setPunches,
}: UseUpdateShiftPunchesProps) =>
  useCallback(
    (shiftId: number | string, shiftPunches: PunchApiData[] = []) => {
      if (shiftPunches.length === 0) {
        return;
      }
      const updatedPunches = punchesData
        .filter(({ shiftId: id }) => id !== shiftId)
        .concat(shiftPunches);

      setPunches(updatedPunches);
    },
    [punchesData, setPunches],
  );
