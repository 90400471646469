import { stringifyUrl } from '@shared/utils/route';
import { TIsoFormat } from '@shared/utils/time/types';

import { get } from '@ManagerPortal/api';
import { TLeaveRequest } from '@ManagerPortal/containers/Schedule/Main/ShiftControlCentre/LeaveRequests/types';

export * from './index-old';

export const fetchLeaveRequests = (
  groupId: number,
  startDate?: TIsoFormat,
  endDate?: TIsoFormat,
) =>
  get<TLeaveRequest[]>(
    stringifyUrl(`/v1/absences/requests/by-group/${groupId}`, {
      startDate,
      endDate,
    }),
  );
