export const FILTER_GROUP = Object.freeze({
  ABSENCES: 'absences',
  ABSENCE_REQUESTS: 'absence-requests',
  AVAILABILITY: 'availability',
  PEOPLE: 'people',
  PUNCHES: 'punches',
  SHIFTS: 'shifts',
  TASKS: 'tasks',
  UNAVAILABILITY: 'unavailability',
});

export const FILTER_NAME = Object.freeze({
  ABSENCE_REQUEST: 'absence-request',
  ABSENCE_REQUESTS_TYPE: 'absence-requests-type',
  ABSENCES_ABSENCE_TYPE: 'absences-absence-type',
  ABSENCES_WARNINGS: 'absences-warnings',
  ABSENCES_ATTEST: 'absences-attest',
  AVAILABILITY: 'availability',
  PEOPLE_AGREEMENT_TEMPLATE: 'people-agreement-template',
  PEOPLE_EMPLOYEE: 'people-employee',
  PEOPLE_EMPLOYEE_STATUS: 'people-employee-status',
  PEOPLE_HOME_UNIT: 'people-home-unit',
  PEOPLE_SECTION: 'people-section',
  PEOPLE_REPORTING_TO: 'people-reporting-to',
  PEOPLE_SKILLS: 'people-skills',
  PEOPLE_STAFF_CATEGORY: 'people-staff-category',
  PUNCHES_ATTEST: 'punches-attest',
  PUNCHES_SHIFT_TYPE: 'punches-shift-type',
  PUNCHES_WARNINGS: 'punches-warnings',
  SHIFTS_BASE_SCHEDULE: 'shifts-base-schedule',
  SHIFTS_SECTION: 'shifts-section',
  SHIFTS_SHIFT_TYPE: 'shifts-shift-type',
  SHIFTS_STATUS: 'shifts-status',
  SHIFTS_WARNINGS: 'shifts-warnings',
  TASKS_BASE_SCHEDULE: 'tasks-base-schedule',
  TASKS_SECTION: 'tasks-section',
  TASKS_SHIFT_TYPE: 'tasks-shift-type',
  TASKS_STATUS: 'tasks-status',
  UNAVAILABILITY: 'unavailability',
});

export const SHIFT_STATUS = Object.freeze({
  UNASSIGNED: 0,
  ASSIGNED: 1,
});

export const SHIFT_WARNING = Object.freeze({
  NO_WARNINGS: 'SHIFT_WITHOUT_WARNINGS',
  SHIFT_HAS_ADDITIONAL_TIME_SALARY: 'SHIFT_HAS_ADDITIONAL_TIME_SALARY',
  SHIFT_HAS_OVERTIME_SALARY: 'SHIFT_HAS_OVERTIME_SALARY',
  SHIFT_HAS_TIME_IN_LIEU_SALARY: 'SHIFT_HAS_TIME_IN_LIEU_SALARY',
  SHIFT_SALARY_REQUIRE_APPROVAL: 'SHIFT_SALARY_REQUIRE_APPROVAL',
  PUNCH_MISSING: 'PUNCH_MISSING',
});

export const SCHEDULE_ITEM = Object.freeze({
  SHIFT: 1,
  PUNCH: 2,
  LEAVE_REQUEST: 4,
  ABSENCE: 5,
  TASK: 6,
  UNAVAILABILITY: 7,
  AVAILABILITY: 8,
  EMPTY: 9,
  NO_ITEMS: 11,
});

export const BS_SCHEDULE_ITEM = Object.freeze({
  SHIFT: 1,
  TASK: 6,
  UNAVAILABILITY: 7,
  EMPTY: 9,
});

export const PUNCH_WARNING = Object.freeze({
  PUNCH_HAS_ADDITIONAL_TIME_SALARY: 0,
  AUTOPUNCH: 1,
  PUNCH_NOT_APPROVED_BY_EMPLOYEE: 2,
  PUNCH_NOT_APPROVED_BY_MANAGER: 3,
  PUNCH_HAS_OVERTIME_SALARY: 4,
  PUNCH_COMMENTED_BY_EMPLOYEE: 5,
  PUNCH_LONGER_THAN_SHIFT: 6,
  PUNCH_SHIFT_MISSING: 8,
  PUNCH_EDITED_BY_EMPLOYEE: 9,
  PUNCH_SHORTER_THAN_SHIFT: 10,
  PUNCH_SALARY_REQUIRE_APPROVAL: 11,
  PUNCH_TIME_CHANGED: 12,
  PUNCH_HAS_TIME_IN_LIEU_SALARY: 13,
  NO_WARNING: 14,
  ANSWER_REQUIRES_APPROVAL: 15,
});

export const ATTESTATION_STATUS = Object.freeze({
  HAS_EMPLOYEE_APPROVAL: 0,
  HAS_MANAGER_APPROVAL: 1,
  EMPLOYEE_APPROVAL_MISSING: 2,
  MANAGER_APPROVAL_MISSING: 3,
});

export const ABSENCE_WARNING = Object.freeze({
  NO_WARNINGS: 'ABSENCE_WITHOUT_WARNINGS',
  ABSENCE_HAS_ADDITIONAL_TIME_SALARY: 'ABSENCE_HAS_ADDITIONAL_TIME_SALARY',
  ABSENCE_HAS_OVERTIME_SALARY: 'ABSENCE_HAS_OVERTIME_SALARY',
  ABSENCE_SALARY_REQUIRE_APPROVAL: 'ABSENCE_SALARY_REQUIRE_APPROVAL',
  ABSENCE_HAS_TIME_IN_LIEU_SALARY: 'ABSENCE_HAS_TIME_IN_LIEU_SALARY',
});

export const FILTER_OPERATOR = Object.freeze({
  AND: 'AND',
  OR: 'OR',
});

export const ITEMS_IN_CURRENT_VIEW = 10;
