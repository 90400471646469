import { useCallback, useMemo } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import { useApi } from '@shared/containers/hooks/api';
import { indexArrayByPropName } from '@shared/utils';

import { useCurrentGroup } from '@ManagerPortal/containers/hooks/account';
import { SCHEDULE_DATA_CACHE_TIME } from '@ManagerPortal/containers/Schedule/Data/useFetchScheduleData';

import {
  fetchReadyForPayrollByEmployeeIds as fetchReadyForPayrollByEmployeeIdsApi,
  fetchReadyForPayrollList as fetchReadyForPayrollListApi,
} from '../../../../../api/schedule/transfertopayroll';
import { useScheduleDates } from '../../Filters/FiltersProvider/ViewFilters';
import { EmployeePayrollStatus } from './types';

export const QUERY_KEY = '/v1/schedule/transfer-to-payroll/by-group';

const updatePayrollStatusList = (
  currentList: EmployeePayrollStatus[],
  newList: EmployeePayrollStatus[],
) =>
  currentList.map((currentEmployee) => {
    const updatedEmployee = newList.find(
      (employee) => employee.employeeId === currentEmployee.employeeId,
    );
    return updatedEmployee ?? currentEmployee;
  });

export const useReadyForPayrollStatusQuery = () => {
  const { id: currentGroupId } = useCurrentGroup();
  const { startDateTime: start, endDateTime: end } = useScheduleDates();

  const queryClient = useQueryClient();

  const payrollStatusQuery = useQuery({
    queryKey: [QUERY_KEY, start, end, currentGroupId],
    queryFn: () =>
      fetchReadyForPayrollListApi(currentGroupId, start, end).then(
        (res) => res?.body || [],
      ),
    cacheTime: SCHEDULE_DATA_CACHE_TIME,
  });

  const [, fetchReadyForPayrollByEmployeeIds] = useApi(
    fetchReadyForPayrollByEmployeeIdsApi,
  );

  const indexedPayrollStatus = useMemo(
    () => indexArrayByPropName(payrollStatusQuery.data || [], 'employeeId'),
    [payrollStatusQuery.data],
  );

  const invalidate = useCallback(
    () =>
      queryClient.invalidateQueries([QUERY_KEY, start, end, currentGroupId]),
    [end, currentGroupId, queryClient, start],
  );

  const invalidateReadyForPayrollByEmployees = useCallback(
    (employeeIds: number[]) =>
      fetchReadyForPayrollByEmployeeIds(currentGroupId, start, end, employeeIds)
        .then(({ data: updatedEmployees }) => {
          if (updatedEmployees) {
            queryClient.setQueryData<EmployeePayrollStatus[]>(
              [QUERY_KEY, start, end, currentGroupId],
              (prevState) =>
                updatePayrollStatusList(prevState || [], updatedEmployees),
            );
          }
        })
        .catch((error) => error),
    [
      end,
      fetchReadyForPayrollByEmployeeIds,
      currentGroupId,
      queryClient,
      start,
    ],
  );

  return useMemo(
    () => ({
      data: indexedPayrollStatus,
      invalidate,
      invalidateReadyForPayrollByEmployees,
    }),
    [indexedPayrollStatus, invalidate, invalidateReadyForPayrollByEmployees],
  );
};
