import { del, get, post, put } from '../..';
import { getRequestUrl } from '../../../../shared/api/utils';
import { stringifyUrl } from '../../../../shared/utils/route';

export const createShift = (shift, ignoreValidationRules) =>
  post(
    getRequestUrl(
      'schedule:shift:add',
      {},
      {
        serializeAs: 'COMPACT',
        ignoreValidationRules: ignoreValidationRules ? 'true' : undefined,
      },
    ),
    shift,
  );

export const fetchShiftsByIds = (shiftIds, groupId) => {
  const requestUrl = getRequestUrl('schedule:shifts', { groupId });
  return post(requestUrl, { shiftIds });
};

export const fetchShiftsInShiftAssignmentRequests = (shiftIds, groupId) =>
  post(`/v1/schedule/groups/${groupId}/assignment_request_shifts/by-ids`, {
    shiftIds,
  });

export const updateShift = (shift, ignoreValidationRules) => {
  const { id: shiftId } = shift;

  return put(
    getRequestUrl(
      'schedule:shift:update',
      { shiftId },
      {
        serializeAs: 'COMPACT',
        ignoreValidationRules,
      },
    ),
    shift,
  );
};

export const deleteShift = (
  shiftsId,
  groupId,
  deletePunches = false,
  ignoreValidationRules = false,
) => {
  return del(
    stringifyUrl(`/v1/schedule/shifts/${shiftsId}/groups/${groupId}`, {
      deletePunches,
      ignoreValidationRules,
    }),
  );
};

export const fetchShiftsByPeriod = (groupId, startDate, endDate) => {
  const requestUrl = getRequestUrl(
    'schedule:shift',
    { groupId },
    {
      startDate,
      endDate,
      serializeAs: 'COMPACT',
      displayFormat: 'DETAILED',
    },
  );

  return get(requestUrl);
};

export const fetchShiftsByEmployee = (
  groupId,
  employeeId,
  startDate,
  endDate,
) =>
  get(
    stringifyUrl(
      `/v1/schedule/shifts/by-group/${groupId}/assigned/${employeeId}`,
      {
        startDate,
        endDate,
      },
    ),
  );

export const deleteShifts = (shiftIds, groupId, deletePunches = false) =>
  post(
    stringifyUrl(`/v1/schedule/shifts/groups/${groupId}/batch-delete`, {
      deletePunches,
    }),
    shiftIds,
  );

export const createShifts = (shifts) =>
  post('/v1/schedule/shifts/batch', shifts);

export const updateShifts = (shifts) =>
  put('/v1/schedule/shifts/batch', shifts);

export const fetchUnassignedShifts = (groupId) =>
  get(`/v1/schedule/shifts/unassigned/by-group/${groupId}`);

export const correctBreaksOnShift = (shiftId, groupId) =>
  put(`/v1/schedule/timepunches/groups/${groupId}/correct-breaks/${shiftId}`);

export const splitPunchesOnShift = (shiftId, groupId) =>
  put(`/v1/schedule/timepunches/groups/${groupId}/split-on-shift/${shiftId}`);

export const fetchShiftById = (shiftId) =>
  get(`/v2/schedule/shifts/${shiftId}`);

// delete createShiftOfferOld after testing
export const createShiftOfferOld = (data, ignoreValidationRules) =>
  post(
    stringifyUrl('/v1/schedule/shift-offers/create', { ignoreValidationRules }),
    data,
  );

export const createShiftOffer = (data, ignoreValidationRules) =>
  post(
    stringifyUrl('/v1/schedule/shift-offers/multiple/create', {
      ignoreValidationRules,
    }),
    data,
  );

export const fetchShiftOffers = (shiftId) =>
  get(stringifyUrl(`/v1/schedule/shift-offers/shifts/${shiftId}`));

export const deleteShiftOffer = (shiftId) =>
  del(stringifyUrl(`/v1/schedule/shift-offers/shifts/${shiftId}`));
