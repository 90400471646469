import { useCallback, useMemo } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import { isOriginalError } from '@shared/containers/hooks/api/utils';
import { newDate } from '@shared/utils/time';

import { fetchUnavailabilityList } from '@ManagerPortal/api/schedule/unavailability';

import { useFilterFeatures } from '../../Main/Filters/FiltersProvider';
import { TUnavailability, UnavailabilityItem } from '../../Main/types';
import { SCHEDULE_DATA_CACHE_TIME } from '../useFetchScheduleData';
import { useGetScheduleDataHookParams } from '../utils/useGetScheduleDataHookParams';

export const QUERY_KEY = '/v2/employee/unavailability/groups';

const unnestAndReverseUnavailabilityList = (
  unavailabilityItems?: Record<number, TUnavailability[]>,
): UnavailabilityItem[] | undefined =>
  unavailabilityItems
    ? Object.entries(unavailabilityItems)
        .flatMap(([key, values]) =>
          values.map((value) => ({
            ...value,
            beginTZ: newDate(value.begin),
            endTZ: newDate(value.end),
            employeeId: parseInt(key),
            componentType: 'SCHEDULE_UNAVAILABILITY' as const,
          })),
        )
        .reverse()
    : undefined;

export const useUnavailabilitiesQuery = () => {
  const { currentGroupId, start, end, isPlaceholderFilterData } =
    useGetScheduleDataHookParams();
  const features = useFilterFeatures();
  const queryClient = useQueryClient();
  const enabled =
    !isPlaceholderFilterData && features.includes('unavailability');

  const unavailabilitiesQuery = useQuery({
    queryKey: [QUERY_KEY, start, end, currentGroupId],
    queryFn: () =>
      fetchUnavailabilityList(start, end, currentGroupId).then(
        (res) => res?.body,
      ),
    retry: (failureCount, error) => {
      if (isOriginalError(error) && error.status === 402) {
        return false;
      }
      return failureCount < 2;
    },
    enabled,
    cacheTime: SCHEDULE_DATA_CACHE_TIME,
  });

  const invalidate = useCallback(
    () => queryClient.invalidateQueries([QUERY_KEY]),
    [queryClient],
  );

  const setUnavailabilities = useCallback(
    (newData: UnavailabilityItem[]) => {
      queryClient.setQueryData(
        [QUERY_KEY, start, end, currentGroupId],
        newData,
      );
    },
    [start, currentGroupId, queryClient, end],
  );

  const indexedUnavailabilities = useMemo(
    () =>
      (unavailabilitiesQuery.data &&
        unnestAndReverseUnavailabilityList(unavailabilitiesQuery.data)) ||
      [],
    [unavailabilitiesQuery.data],
  );

  return useMemo(
    () => ({
      isLoading: unavailabilitiesQuery.isLoading,
      isError: unavailabilitiesQuery.isError,
      isSuccess: unavailabilitiesQuery.isSuccess,
      errors: unavailabilitiesQuery.error,
      data: indexedUnavailabilities,
      invalidate,
      setData: setUnavailabilities,
    }),
    [
      indexedUnavailabilities,
      invalidate,
      setUnavailabilities,
      unavailabilitiesQuery.error,
      unavailabilitiesQuery.isError,
      unavailabilitiesQuery.isLoading,
      unavailabilitiesQuery.isSuccess,
    ],
  );
};
