import { isNil } from '@shared/utils';

export const scheduleDayComponentTypes = {
  SCHEDULE_SHIFT_ITEM: 'SCHEDULE_SHIFT_ITEM',
  SCHEDULE_PUNCH_ITEM: 'SCHEDULE_PUNCH_ITEM',
  SCHEDULE_LEAVE_REQUEST: 'SCHEDULE_LEAVE_REQUEST',
  SCHEDULE_ABSENCE: 'SCHEDULE_ABSENCE',
  SCHEDULE_UNAVAILABILITY: 'SCHEDULE_UNAVAILABILITY',
  SCHEDULE_AVAILABILITY: 'SCHEDULE_AVAILABILITY',
  SCHEDULE_AWAY_GROUP_PUNCH_ITEM: 'SCHEDULE_AWAY_GROUP_PUNCH_ITEM',
} as const;

export type TScheduleDayComponentTypes =
  (typeof scheduleDayComponentTypes)[keyof typeof scheduleDayComponentTypes];

export const isAvailability = ({
  componentType,
}: {
  componentType: TScheduleDayComponentTypes;
}) => componentType === scheduleDayComponentTypes.SCHEDULE_AVAILABILITY;
export const isUnavailability = ({
  componentType,
}: {
  componentType: TScheduleDayComponentTypes;
}) => componentType === scheduleDayComponentTypes.SCHEDULE_UNAVAILABILITY;
export const isShift = ({
  componentType,
}: {
  componentType: TScheduleDayComponentTypes;
}) => componentType === scheduleDayComponentTypes.SCHEDULE_SHIFT_ITEM;
export const isPunch = ({
  componentType,
}: {
  componentType: TScheduleDayComponentTypes;
}) => componentType === scheduleDayComponentTypes.SCHEDULE_PUNCH_ITEM;
export const isLeaveRequest = ({
  componentType,
}: {
  componentType: TScheduleDayComponentTypes;
}) => componentType === scheduleDayComponentTypes.SCHEDULE_LEAVE_REQUEST;
export const isAbsence = ({
  componentType,
}: {
  componentType: TScheduleDayComponentTypes;
}) => componentType === scheduleDayComponentTypes.SCHEDULE_ABSENCE;
export const isShiftOrPunch = (component: {
  componentType: TScheduleDayComponentTypes;
}) => isShift(component) || isPunch(component);
export const hasSubshift = ({ subShifts }: { subShifts: any }) =>
  !isNil(subShifts) && !!subShifts?.length;
