import { useCallback, useMemo } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import { isOriginalError } from '@shared/containers/hooks/api/utils';

import { fetchLeaveRequests } from '@ManagerPortal/api/schedule/leaverequests';

import { indexArrayByPropName } from '../../../../../shared/utils';
import { useFilterFeatures } from '../../Main/Filters/FiltersProvider';
import { SCHEDULE_DATA_CACHE_TIME } from '../useFetchScheduleData';
import { useGetScheduleDataHookParams } from '../utils/useGetScheduleDataHookParams';

export const QUERY_KEY = '/v1/absences/requests/by-group';

export const useLeaveRequestsQuery = () => {
  const { currentGroupId, start, end, isPlaceholderFilterData } =
    useGetScheduleDataHookParams();

  const features = useFilterFeatures();
  const queryClient = useQueryClient();
  const enabled =
    !isPlaceholderFilterData && features.includes('leaveRequests');
  const leaveRequestsQuery = useQuery({
    queryKey: [QUERY_KEY, currentGroupId, start, end],
    queryFn: () =>
      fetchLeaveRequests(currentGroupId, start, end).then((res) => res?.body),
    retry: (failureCount, error) => {
      if (isOriginalError(error) && error.status === 402) {
        return false;
      }
      return failureCount < 2;
    },
    enabled,
    cacheTime: SCHEDULE_DATA_CACHE_TIME,
  });

  const invalidate = useCallback(
    () => queryClient.invalidateQueries([QUERY_KEY]),
    [queryClient],
  );

  const indexedLeaveRequests = useMemo(
    () =>
      leaveRequestsQuery.data && indexArrayByPropName(leaveRequestsQuery.data),
    [leaveRequestsQuery.data],
  );

  const leaveRequests = useMemo(
    () => ({
      isLoading: leaveRequestsQuery.isLoading,
      isError: leaveRequestsQuery.isError,
      isSuccess: leaveRequestsQuery.isSuccess,
      errors: leaveRequestsQuery.error,
      invalidate,
      data: indexedLeaveRequests,
    }),
    [
      leaveRequestsQuery.isLoading,
      leaveRequestsQuery.isError,
      leaveRequestsQuery.isSuccess,
      leaveRequestsQuery.error,
      invalidate,
      indexedLeaveRequests,
    ],
  );

  return leaveRequests;
};
