import {
  addDays,
  endOf,
  format,
  isSame,
  newDateWithTZ,
  setHours,
  setMinutes,
  stringToDate,
} from '../../../shared/utils/time';
import {
  DATE_FORMAT,
  ISO_FORMAT,
  LOCALE_DATE_MONTH_NAME_FORMAT,
  LOCALE_DATE_MONTH_NAME_SHORT_FORMAT,
  LOCALE_DATE_NAME_FORMAT,
  TIME_FORMAT,
} from '../../../shared/utils/time/constants';

// Pattern to match dates on format YYYY-MM-DD
const idPattern = () => /(\d{4})[/-](\d{2})[/-](\d{2})$/;

export const isCustomId = (id) =>
  typeof id === 'string' && idPattern().test(id);

const isObject = (id) => typeof id === 'object' && id !== null;

export const getCustomDates = (id) => {
  if (!isCustomId(id) && (isObject(id) ? !format(id, DATE_FORMAT) : !id)) {
    return null;
  }
  return {
    start: isObject(id) ? id : stringToDate(id.substr(0, 10), DATE_FORMAT),
    end: isObject(id)
      ? endOf(id, 'day')
      : endOf(stringToDate(id.substr(11, 16), DATE_FORMAT), 'day'),
  };
};

export const getFormatedTimeperiod = (timeperiod, isShortFormat) => {
  if (!timeperiod) {
    return;
  }
  const { start, end } = timeperiod;
  if (!start || !end) {
    return;
  }

  if (isSame(end, start, 'day')) {
    return format(start, LOCALE_DATE_NAME_FORMAT);
  }
  if (isShortFormat) {
    return `${format(start, LOCALE_DATE_MONTH_NAME_SHORT_FORMAT)} - ${format(
      end,
      LOCALE_DATE_MONTH_NAME_SHORT_FORMAT,
    )}`;
  }

  return `${format(start, LOCALE_DATE_MONTH_NAME_FORMAT)} - ${format(
    end,
    LOCALE_DATE_MONTH_NAME_FORMAT,
  )}`;
};

export const getTimeperiodWithDaybreak = (timeperiod, daybreak) => {
  const hour = daybreak.substr(0, 2);
  const minute = daybreak.substr(3, 2);
  const from = timeperiod.substr(0, 10);
  const to = timeperiod.substr(11, 10) || from;
  const start = setHours(
    setMinutes(stringToDate(from, DATE_FORMAT), minute),
    hour,
  );
  const end = setHours(
    setMinutes(addDays(stringToDate(to, DATE_FORMAT), 1), minute),
    hour,
  );

  return { start, end };
};

export const mangleTimeTZChange = (
  startingTime,
  hoursAndMinutesFormat,
  timeZone,
) => {
  const hoursAndMinutesArr = hoursAndMinutesFormat.split(':');
  return setHours(
    setMinutes(newDateWithTZ(timeZone, startingTime), hoursAndMinutesArr[1]),
    hoursAndMinutesArr[0],
  );
};

export const findClosestTZAfterReference = (beginReference, time, timeZone) => {
  const formattedTime = time.format(TIME_FORMAT);
  const endTZ = mangleTimeTZChange(beginReference, formattedTime, timeZone);
  const beginTZ = newDateWithTZ(timeZone, beginReference);
  const formattedBegin = format(beginTZ, ISO_FORMAT);
  const formattedEnd = format(endTZ, ISO_FORMAT);
  if (formattedEnd <= formattedBegin) {
    endTZ.add(1, 'days');
  }
  return endTZ;
};

const covertDateToTimeperiodString = (date) => {
  return typeof date === 'string'
    ? date.slice(0, 10)
    : format(date, DATE_FORMAT);
};
export const getTimeperiodString = (start, end = start) => {
  const convertedStart = covertDateToTimeperiodString(start);
  const convertedEnd =
    start === end ? convertedStart : covertDateToTimeperiodString(end);

  return `${convertedStart}-${convertedEnd}`;
};
