export { default as reportTypes } from './reportTypes';

export const Timeframes = Object.freeze({
  SINGLEDAY: 'singleday',
  MULTIDAY: 'multiday',
  MULTIDAY_WEEK: 'multiday_week',
  MULTIDAY_MONTH: 'multiday_month',
});

export const SORT_CRITERIA = {
  EMPLOYEE_NAME: 'EMPLOYEENAME',
  EMPLOYEE_LAST_NAME: 'EMPLOYEELASTNAME',
  METRICS_DIFFERENCE: 'NOMINALSCHEDULEDHOURS',
  START_TIME: 'STARTTIME',
  ADDITIONAL_FIELD: 'ADDITIONALFIELD',
} as const;

// TODO Michal Sadowski: rename to SORT_ORDER
export const SORT_ORDER = {
  ASC: 'ASC',
  DESC: 'DESC',
} as const;

export const GROUP_TYPES = {
  NONE: 1,
  SYSTEM: 2,
  DOMAIN: 3,
  DEFAULT: 4,
  UNIT: 5,
  DISTRICT: 6,
  SECTION: 7,
  SHARING: 8,
  CUSTOM_GROUP: 29,
} as const;

export type GroupTypeId = (typeof GROUP_TYPES)[keyof typeof GROUP_TYPES];

export const ACTION_BUTTON_TYPE = {
  positive: 'POSITIVE',
  negative: 'NEGATIVE',
};

export const ROLE_IDS = {
  10: { id: 10, name: 'ROLE_SYSTEM', master: true },
  12: { id: 9, name: 'ROLE_ACCOUNT', master: true },
  4: { id: 8, name: 'ROLE_MANAGER', master: true },
  3: { id: 7, name: 'ROLE_DISTRICT', master: false },
  2: { id: 6, name: 'ROLE_LOCAL', master: false },
  6: { id: 5, name: 'ROLE_SECTION', master: false },
  1: { id: 4, name: 'ROLE_STAFF', master: false },
  7: { id: 3, name: 'ROLE_ASS', master: false },
  11: { id: 2, name: 'ROLE_READER', master: false },
  5: { id: 1, name: 'ROLE_MONITOR', master: false },
} as const;

export const REPORTS_GROUP_BY_TYPES = {
  1: { id: 1, name: 'label.employees' },
  2: { id: 2, name: 'reports.generateReportForm.shiftType' },
  3: { id: 3, name: 'reports.generateReportForm.section' },
  4: { id: 4, name: 'label.costCentre' },
  5: { id: 5, name: 'label.project' },
  6: { id: 6, name: 'Account' },
} as const;

export const PERIOD_TYPES = {
  day: { id: 0, name: 'day' },
  week: { id: 1, name: 'week' },
  month: { id: 2, name: 'month' },
} as const;

export const REPORT_STATUSES = {
  created: { id: 0, name: 'FILE_VIEW_TYPE_CREATED' },
  inQueue: { id: 1, name: 'FILE_VIEW_TYPE_IN_QUEUE' },
  processing: { id: 2, name: 'FILE_VIEW_TYPE_PROCESSING' },
  finished: { id: 3, name: 'FILE_VIEW_TYPE_FINISHED' },
  failed: { id: 4, name: 'FILE_VIEW_TYPE_FAILED' },
  revertProcessing: { id: 5, name: 'REVERT_FILE_PROCESSING' },
  revertFinished: { id: 6, name: 'REVERT_FILE_FINISHED' },
  revertFailed: { id: 7, name: 'REVERT_FILE_FAILED' },
  revertInQueue: { id: 8, name: 'REVERT_FILE_IN_QUEUE' },
  processingMerge: { id: 10, name: 'FILE_VIEW_TYPE_PROCESSING_MERGE' },
  transactionLock: { id: 11, name: 'FILE_VIEW_TYPE_PROCESSING_LOCKING' },
} as const;

export const WEEK_DAYS = {
  0: { id: 0, name: 'label.sunday' },
  1: { id: 1, name: 'label.monday' },
  2: { id: 2, name: 'label.tuesday' },
  3: { id: 3, name: 'label.wednesday' },
  4: { id: 4, name: 'label.thursday' },
  5: { id: 5, name: 'label.friday' },
  6: { id: 6, name: 'label.saturday' },
} as const;

export const allWeekDays = {
  '-1': { id: 6, name: 'all.days.of.the.week' },
  ...WEEK_DAYS,
} as const;

export const PAYROLL_STATUSES = {
  QUEUED: 'QUEUED',
  IN_PROGRESS: 'IN_PROGRESS',
  DONE: 'DONE',
  UNKNOWN: 'UNKNOWN',
} as const;

export const ERROR_SEVERITY = Object.freeze({
  ERROR: 'ERROR',
  OVERRIDABLE: 'OVERRIDABLE',
});

export const SHIFT_STATUS = Object.freeze({
  UNASSIGNED: 'UNASSIGNED',
  ASSIGNED: 'ASSIGNED',
});

export const PASSWORD = Object.freeze({
  MAX_LENGTH: 128,
});

export enum MODULE_NAMES {
  SCHEDULE = 'SCHEDULE',
  BASE_SCHEDULE = 'BASE_SCHEDULE',
}

export const ENVIRONMENT = Object.freeze({
  PRODUCTION: 'production',
  DEVELOPMENT: 'development',
});

export const HOSTNAME = Object.freeze({
  LOCAL_TEST: 'local.web-test.quinyx.com',
  TEST: 'web-test.quinyx.com',
  STAGE: 'web-staging.quinyx.com',
  STAGE_RC: 'web-rc.quinyx.com',
  Q_DAILY: 'web-qdaily.quinyx.com',
  PRODUCTION: 'web.quinyx.com',
  VERCEL: 'quinyx-fullstack',
});

export const LOGIN_ERROR_MESSAGE = Object.freeze({
  WRONG_CREDENTIALS_BACKEND: 'Bad credentials',
  WRONG_CREDENTIALS:
    'Whoops, it looks like your email or password is incorrect',
  SSO: 'You can only log in via SSO',
  EXPIRED_CREDENTIALS_BACKEND: 'User credentials have expired',
  EXPIRED_CREDENTIALS: 'Looks like your password has been reset or expired.',
  ACCOUNT_LOCKED_BACKEND: 'User account is locked',
  ACCOUNT_LOCKED:
    'Your account is locked. Contact your manager to have it unlocked.',
  NO_MANAGER_RIGHTS: "User doesn't have manager rights!",
  DEFAULT: 'Sorry! We have been unable to log you in',
  NO_PHONE_NUMBER: 'NO_PHONE_NUMBER',
});

export const COMPANY_ALIAS = 'COMPANY_ALIAS';

export const REGIONS = Object.freeze({
  eu: { id: 1, name: 'Europe' },
  us: { id: 3, name: 'North America' },
});

export const PAGES = [
  'account',
  'audit-logs',
  'autoassign',
  'autoschedule',
  'baseschedule',
  'dashboard',
  'events',
  'flp',
  'forecast',
  'group',
  'news',
  'optimization',
  'passwordLogin',
  'people',
  'reports',
  'schedule',
  'staffPortal',
  'transfertopayroll',
  'setnewpassword',
];

export const ANALYTICS_SDK_DOMAIN = 'https://analytics-sdk.quinyx.com';
export const ANALYTICS_ESP_DOMAIN = 'https://analytics-esp.quinyx.com';
