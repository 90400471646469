import errorreporting from '@shared/utils/errorreporting';

import { API_ENDPOINT as QUERY_KEY_EMPLOYEE_HOURS } from '@ManagerPortal/containers/hooks/employeeHours/useEmployeeHoursQuery/prefetch';
import { QUERY_KEY as QUERY_KEY_ABSENCES } from '@ManagerPortal/containers/Schedule/Data/useAbsencesQuery';
import { QUERY_KEY as QUERY_KEY_AVAILABILITY } from '@ManagerPortal/containers/Schedule/Data/useAvailabilitiesQuery';
import { QUERY_KEY as QUERY_KEY_AWAY_GROUP_PUNCHES } from '@ManagerPortal/containers/Schedule/Data/useAwayGroupPunchesQuery';
import { QUERY_KEY as QUERY_KEY_EMPLOYEES } from '@ManagerPortal/containers/Schedule/Data/useEmployeesQuery';
import { SCHEDULE_DATA_CACHE_TIME } from '@ManagerPortal/containers/Schedule/Data/useFetchScheduleData';
import { QUERY_KEY as QUERY_KEY_LEAVE_REQUESTS } from '@ManagerPortal/containers/Schedule/Data/useLeaveRequestsQuery';
import { PUNCHES_QUERY_KEY as QUERY_KEY_PUNCHES } from '@ManagerPortal/containers/Schedule/Data/usePunchesQuery';
import { QUERY_KEY as QUERY_KEY_SALARY_WARNINGS } from '@ManagerPortal/containers/Schedule/Data/useSalaryWarnings';
import { QUERY_KEY as QUERY_KEY_SHIFTS } from '@ManagerPortal/containers/Schedule/Data/useShiftsQuery';
import { QUERY_KEY as QUERY_KEY_SHIFT_TYPES } from '@ManagerPortal/containers/Schedule/Data/useShiftTypesQuery';
import { QUERY_KEY as QUERY_KEY_UNAVAILABILITY } from '@ManagerPortal/containers/Schedule/Data/useUnavailabilitiesQuery';
import { QUERY_KEY as QUERY_KEY_READY_FOR_PAYROLL } from '@ManagerPortal/containers/Schedule/Main/hooks/useReadyForPayrollStatusQuery';

import { idbPersister } from '..';

export const reportPerstistenceLayerError = (
  title: string,
  error?: unknown,
) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.error(title, error);
  }

  errorreporting.reportError(error);
};

export const cleanupExpiredCache = async () => {
  try {
    const persistedClient = await idbPersister.restoreClient();
    if (!persistedClient) return;

    const now = Date.now();
    const originalLength = persistedClient.clientState.queries.length;

    const cleanedQueryCache = persistedClient.clientState.queries.filter(
      (query) => now - query.state.dataUpdatedAt < SCHEDULE_DATA_CACHE_TIME,
    );

    if (cleanedQueryCache.length !== originalLength) {
      persistedClient.clientState.queries = cleanedQueryCache;
      await idbPersister.persistClient(persistedClient);
    }
  } catch (error) {
    reportPerstistenceLayerError('Failed to cleanup expired cache', error);
  }
};

export const shouldPersistQuery = (queryKey: unknown) => {
  if (typeof queryKey !== 'string') {
    return false;
  }

  switch (queryKey) {
    case QUERY_KEY_ABSENCES:
    case QUERY_KEY_AVAILABILITY:
    case QUERY_KEY_EMPLOYEES:
    case QUERY_KEY_LEAVE_REQUESTS:
    case QUERY_KEY_SHIFTS:
    case QUERY_KEY_SHIFT_TYPES:
    case QUERY_KEY_UNAVAILABILITY:
    case QUERY_KEY_SALARY_WARNINGS:
    case QUERY_KEY_READY_FOR_PAYROLL:
    case QUERY_KEY_EMPLOYEE_HOURS:
    case QUERY_KEY_PUNCHES:
    case QUERY_KEY_AWAY_GROUP_PUNCHES:
      return true;

    default:
      return false;
  }
};
