import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

import { isEqual } from '@shared/utils';

export const useDeepEqualEffect = (
  effect: EffectCallback,
  deps: DependencyList,
) => {
  const prevDepsRef = useRef(deps);
  if (!isEqual(prevDepsRef.current, deps)) prevDepsRef.current = deps;
  useEffect(effect, prevDepsRef.current);
};
