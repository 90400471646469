import { useCallback } from 'react';

import { PunchApiData } from '@ManagerPortal/types/apiResponses/punch';

interface UseUpdateStateProps {
  punchesData: PunchApiData[] | undefined;
  setPunches: (punches: PunchApiData[]) => void;
}

export const useUpdateState = ({
  punchesData,
  setPunches,
}: UseUpdateStateProps) =>
  useCallback(
    (update: PunchApiData[] = []) => {
      const updateIds = new Set(update.map(({ id }) => id));
      const punchesToRetain = (punchesData || []).filter(
        (punch) => punch.id && !updateIds.has(punch.id),
      );
      return setPunches([...punchesToRetain, ...update]);
    },
    [punchesData, setPunches],
  );
