import { EmployeePayrollStatus } from '@ManagerPortal/containers/Schedule/Main/hooks/useReadyForPayrollStatusQuery/types';

import { get, post } from '../..';
import { stringifyUrl } from '../../../../shared/utils/route';

export const fetchReadyForPayrollList = (
  groupId: number,
  startDate: string,
  endDate: string,
) =>
  get<EmployeePayrollStatus[]>(
    stringifyUrl(
      `/v1/schedule/transfer-to-payroll/by-group/${groupId}/ready-for-payroll`,
      { startDate, endDate },
      {
        arrayFormat: 'none',
      },
    ),
  );

export const fetchReadyForPayrollByEmployeeIds = (
  groupId: number,
  startDate: string,
  endDate: string,
  employeeIds: number[],
) =>
  post<EmployeePayrollStatus[]>(
    stringifyUrl(
      `/v1/schedule/ready-for-payroll/groups/${groupId}/by-employees`,
      {
        endDate,
        startDate,
      },
    ),
    employeeIds,
  );
