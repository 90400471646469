import { isNil } from '@shared/utils';

import { fetchPunchesByIds } from '@ManagerPortal/api/schedule/punches';
import { ShiftItem } from '@ManagerPortal/containers/Schedule/Main/types';
import { PunchApiData } from '@ManagerPortal/types/apiResponses/punch';

interface DeleteShiftPunchesProps {
  groupId: number;
  deletedShifts: [ShiftItem, ...ShiftItem[]];
  punchesData: PunchApiData[];
  setPunches: (punches: PunchApiData[]) => void;
}

export function deleteShiftPunches({
  groupId,
  deletedShifts,
  punchesData,
  setPunches,
}: DeleteShiftPunchesProps) {
  const { punchIds } = deletedShifts[0];
  if (!punchIds) return;

  return fetchPunchesByIds(punchIds, groupId).then((response) => {
    if (!response) throw new Error('No response for fetchPunchesByIds');
    const punchesForDeletedShift = response.body;
    const updatedPunchesList = (punchesData || [])
      .map((punch) => {
        const { id } = punch;
        if (!punchIds.includes(id)) {
          return punch;
        }

        return punchesForDeletedShift.find(
          ({ id: punchIdForDeletedShift }) => id === punchIdForDeletedShift,
        );
      })
      .filter((item) => !isNil(item)) as PunchApiData[];

    return setPunches(updatedPunchesList);
  });
}
