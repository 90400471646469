import { MODULE_NAMES } from '@shared/constants';

import { SCHEDULE_VIEW_MODE_VALUES } from '@ManagerPortal/containers/Schedule/Main/const';
import {
  TBusinessDisplayOptionsState,
  TDisplayOptionsState,
} from '@ManagerPortal/containers/Schedule/Main/Filters/FiltersProvider/DisplayOptionsFilters/types';

import { get, post, put } from '..';
import {
  FilterGroup,
  SavedScheduleFilters,
  ScheduleFilterById,
  ScheduleSelectedFilter,
} from './types';

export * from './index-old';

export const getScheduleFilterById = (
  groupId: number,
  moduleName: keyof typeof MODULE_NAMES,
  filterId: string,
) => {
  const url = `/v1/schedule-filters/schedule-view-filters/groups/${groupId}/module/${moduleName}/${filterId}`;
  return get<ScheduleFilterById>(url);
};

export const getSavedFilters = (
  groupId: number,
  moduleName: keyof typeof MODULE_NAMES,
) => {
  const url = `/v1/schedule-filters/schedule-view-filters/groups/${groupId}/module/${moduleName}`;
  return get<SavedScheduleFilters>(url);
};

interface CreateScheduleFilterParams {
  groupId: number;
  moduleName: keyof typeof MODULE_NAMES;
  data: {
    name: string | undefined;
    filterGroups: FilterGroup[];
    employeeListDisplayOptions: TDisplayOptionsState;
    businessListDisplayOptions: TBusinessDisplayOptionsState;
    scheduleViewMode: SCHEDULE_VIEW_MODE_VALUES;
    selectedView: string; // TODO Andrea Tota: Can be refined after Schedule/Filters refactor?
    displayStatistics: boolean;
  };
}

export const createScheduleFilter = ({
  groupId,
  moduleName,
  data,
}: CreateScheduleFilterParams) => {
  const url = `/v1/schedule-filters/schedule-view-filters/groups/${groupId}/module/${moduleName}`;
  return post<ScheduleSelectedFilter>(url, data);
};

interface UpdateScheduleFilterByIdParams {
  groupId: number;
  moduleName: keyof typeof MODULE_NAMES;
  filterId: string;
  data: {
    id: string;
    name: string | undefined;
    filterGroups: FilterGroup[];
    employeeListDisplayOptions: TDisplayOptionsState;
    businessListDisplayOptions: TBusinessDisplayOptionsState;
    scheduleViewMode: SCHEDULE_VIEW_MODE_VALUES;
    selectedView: string; // TODO Andrea Tota: Can be refined after Schedule/Filters refactor?
    displayStatistics: boolean;
  };
}

export const updateScheduleFilterById = ({
  groupId,
  moduleName,
  filterId,
  data,
}: UpdateScheduleFilterByIdParams) => {
  const url = `/v1/schedule-filters/schedule-view-filters/groups/${groupId}/module/${moduleName}/${filterId}`;
  return put<ScheduleSelectedFilter>(url, data);
};
