import { useQuery } from '@tanstack/react-query';

import { fetchAdditionalFields } from '@ManagerPortal/api/account';
import { useCurrentGroup } from '@ManagerPortal/containers/hooks/account';
import { SCHEDULE_VIEW_MODE_VALUES } from '@ManagerPortal/containers/Schedule/Main/const';

import { useBusinessDisplayOptions } from '../BusinessDisplayOptionsFilters';
import { useEmployeeDisplayOptions } from '../EmployeeDisplayOptionsFilters';
import { useScheduleViewModeFilters } from '../ScheduleViewModeFilters';
import { ADDITIONAL_FIELD_SORT_DISPLAY_OPTION } from './constants';
import { SortOptionArr } from './types';

export const useSortOptionsWithAdditionalField = (
  baseSortOptions: SortOptionArr,
) => {
  const { id: groupId } = useCurrentGroup();
  const { data: additionalSortingField } = useQuery({
    queryKey: ['/v1/employee/additional-fields/by-group/{groupId}', groupId],
    queryFn: async () => {
      const res = await fetchAdditionalFields(groupId);
      return res?.body;
    },
    select: (data) => data?.find((field) => field.useForSorting),
  });

  const additionalSortingFieldOption = {
    ...ADDITIONAL_FIELD_SORT_DISPLAY_OPTION,
    label:
      additionalSortingField?.title ??
      ADDITIONAL_FIELD_SORT_DISPLAY_OPTION.label,
  };
  return additionalSortingField
    ? [...baseSortOptions, additionalSortingFieldOption]
    : baseSortOptions;
};

export const useDisplayOptions = () => {
  const bdo = useBusinessDisplayOptions();
  const edo = useEmployeeDisplayOptions();

  const { scheduleViewMode } = useScheduleViewModeFilters();

  if (scheduleViewMode === SCHEDULE_VIEW_MODE_VALUES.BUSINESS) {
    return bdo;
  }

  return edo;
};
