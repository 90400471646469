import { useCallback, useMemo } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import { isOriginalError } from '@shared/containers/hooks/api/utils';
import { ShiftType } from '@shared/types';

import { fetchSharedEmployeesTimePunches } from '@ManagerPortal/api/schedule/employees';

import { useFilterFeatures } from '../../Main/Filters/FiltersProvider';
import {
  TAwayGroupPunchItem,
  UnavailabilityItem,
  WorkerItem,
} from '../../Main/types';
import { SCHEDULE_DATA_CACHE_TIME } from '../useFetchScheduleData';
import { useGetScheduleDataHookParams } from '../utils/useGetScheduleDataHookParams';
import { transformAwayGroupPunches } from './transformAwayGroupPunches';

type UseSharedEmployeesTimePunchesProps = {
  employees: Record<number, WorkerItem> | undefined;
  unavailabilities: UnavailabilityItem[];
  shiftTypes: Record<number, ShiftType>;
};

export const QUERY_KEY = '/v1/schedule/shared-employees-time-punches/by-group/';

export const useAwayGroupPunchesQuery = ({
  employees,
  unavailabilities,
  shiftTypes,
}: UseSharedEmployeesTimePunchesProps) => {
  const { currentGroupId, start, end, isPlaceholderFilterData } =
    useGetScheduleDataHookParams();
  const features = useFilterFeatures();
  const queryClient = useQueryClient();

  const employeesIds = useMemo(
    () =>
      employees ? Object.keys(employees).map(Number).filter(Boolean) : null,
    [employees],
  );

  const enabled =
    !isPlaceholderFilterData &&
    features.includes('unavailability') &&
    !!employeesIds;

  const awayGroupPunchesQuery = useQuery({
    queryKey: [QUERY_KEY, start, end, currentGroupId, employeesIds],
    queryFn: () =>
      employeesIds && employeesIds.length
        ? fetchSharedEmployeesTimePunches(
            {
              startDate: start,
              endDate: end,
              groupId: currentGroupId,
            },
            employeesIds,
          )
            .then((res) => res?.body)
            .catch((error) => {
              // We need this for all persisted queries, otherwise we will try to
              // persist whole superagent response that includes properties that
              // cannot be serialized
              if (isOriginalError(error)) {
                throw error.body;
              }

              throw error;
            })
        : null,
    retry: (failureCount, error) => {
      if (isOriginalError(error) && error.status === 402) {
        return false;
      }
      return failureCount < 2;
    },
    enabled,
    cacheTime: SCHEDULE_DATA_CACHE_TIME,
  });

  const invalidate = useCallback(
    () => queryClient.invalidateQueries([QUERY_KEY]),
    [queryClient],
  );

  const setData = useCallback(
    (newData: TAwayGroupPunchItem[]) => {
      queryClient.setQueryData(
        [QUERY_KEY, start, end, currentGroupId],
        newData,
      );
    },
    [start, currentGroupId, queryClient, end],
  );

  const transformedData = useMemo(
    () =>
      awayGroupPunchesQuery.data
        ? transformAwayGroupPunches(
            awayGroupPunchesQuery.data,
            unavailabilities,
            shiftTypes,
          )
        : [],
    [awayGroupPunchesQuery.data, shiftTypes, unavailabilities],
  );

  return useMemo(
    () => ({
      isLoading: awayGroupPunchesQuery.isLoading,
      isError: awayGroupPunchesQuery.isError,
      isSuccess: awayGroupPunchesQuery.isSuccess,
      errors: awayGroupPunchesQuery.error,
      data: transformedData,
      invalidate,
      setData,
    }),
    [
      awayGroupPunchesQuery.error,
      awayGroupPunchesQuery.isError,
      awayGroupPunchesQuery.isLoading,
      awayGroupPunchesQuery.isSuccess,
      invalidate,
      setData,
      transformedData,
    ],
  );
};
