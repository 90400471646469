import React, { createContext, FC, useContext } from 'react';

import { StrictOmit } from '@shared/types';

import { assertContextHasValue } from '../assertContextAvailable';

// Params describe type for props we pass to exposed provider (and object that will be passed to the logic)
// Value describes type we're going to have as a context value
export const createProviderHookPair = <
  Params extends object & { children?: never },
  Value,
>(
  /** Just a custom hook with all hooks rules applied */
  useLogic: (args: Params) => Value,
): [FC<StrictOmit<Params, 'children'>>, () => Value] => {
  const Context = createContext<Value | null>(null);

  const ContextProvider: FC<StrictOmit<Params, 'children'>> = ({
    children,
    ...rest
  }) => {
    // We're pretty sure that we don't have children in `rest`.
    // And writing type guard function inside this func each time is an overkill
    const value = useLogic(rest as Params);
    return <Context.Provider value={value}>{children}</Context.Provider>;
  };
  ContextProvider.displayName = `${useLogic.name}(ContextPairProvider)`;

  const useContextConsumer = (): Value => {
    const contextValue = useContext(Context);
    assertContextHasValue(
      contextValue,
      `Context pair hook for "${useLogic.name || 'unnamedLogic'}" logic`,
    );
    return contextValue;
  };

  return [ContextProvider, useContextConsumer];
};
