import { stringifyUrl } from '@shared/utils/route';
import { TIsoFormat } from '@shared/utils/time/types';

import { get, post, postWithAbort } from '@ManagerPortal/api';
import { WorkerItem } from '@ManagerPortal/containers/Schedule/Main/types';

import {
  EmployeeKpiByEmployee,
  EmployeeKpiByIdsRequest,
  SharedEmployeeTimePunch,
} from './types';

export * from './index-old';

export const fetchEmployeesForPeriod = (
  startDate: TIsoFormat,
  endDate: TIsoFormat,
  groupId: number,
) =>
  get<WorkerItem[]>(
    stringifyUrl(`/v2/employee/workersof/by-group/${groupId}`, {
      startDate,
      endDate,
    }),
  );

export const fetchSharedEmployeesTimePunches = (
  {
    startDate,
    endDate,
    groupId,
  }: { startDate: TIsoFormat; endDate: TIsoFormat; groupId: number },
  employeesIds: Array<number>,
) =>
  post<SharedEmployeeTimePunch[]>(
    stringifyUrl(
      `/v1/schedule/shared-employees-time-punches/by-group/${groupId}`,
      {
        startDate,
        endDate,
      },
    ),
    employeesIds,
  );

export const fetchEmployeeHoursByPeriod = ({
  periodBegin,
  periodEnd,
  groupId,
  employeeIds,
  period,
  variables,
  onlyTotals,
}: EmployeeKpiByIdsRequest) => {
  return postWithAbort<EmployeeKpiByEmployee[]>(
    `/v1/statistics/employee-kpi/by-employees/by-group/${groupId}`,
    {
      periodBegin,
      periodEnd,
      employeeIds,
      period,
      variables,
      onlyTotals,
    },
  );
};
