import { fetchCompanyAlias } from '@shared/api/organisation';
import { COMPANY_ALIAS, GROUP_TYPES } from '@shared/constants';
import { useFetch } from '@shared/containers/hooks/api';

import { NestedGroup } from '@ManagerPortal/containers/ManagerPortal/utils';

export const getCurrentDomainId = (groups?: NestedGroup[]) => {
  return groups?.find(({ typeId }) => typeId === GROUP_TYPES.DOMAIN)?.id;
};

export const useStoreCompanyAlias = (domainId?: number) => {
  const { data: { companyAlias = '' } = {} } = useFetch(
    domainId ? fetchCompanyAlias : null,
    [domainId],
  );

  if (companyAlias) sessionStorage.setItem(COMPANY_ALIAS, companyAlias);
};

export const getCompanyAlias = () => sessionStorage.getItem(COMPANY_ALIAS);
