import { isNil } from '@shared/utils';

import { fetchPunchesByShiftId } from '@ManagerPortal/api/schedule/punches';
import { ShiftItem } from '@ManagerPortal/containers/Schedule/Main/types';
import { PunchApiData } from '@ManagerPortal/types/apiResponses/punch';

import { DoesPunchBelongToPeriod, Period } from '../useInvalidateShiftPunches';

interface CreateShiftPunchesProps {
  groupId: number;
  createdShifts: ShiftItem[];
  punchesData: PunchApiData[];
  setPunches: (punches: PunchApiData[]) => void;
  period: Period;
}

export function createShiftPunches({
  groupId,
  createdShifts,
  punchesData,
  setPunches,
  period,
}: CreateShiftPunchesProps) {
  const doesPunchBelongToPeriod = DoesPunchBelongToPeriod(period);
  const updatePunchesInState = (shiftPunches: PunchApiData[]) => {
    const punchesInPeriod = shiftPunches.filter(doesPunchBelongToPeriod);

    const newPunches = punchesData.concat([...punchesInPeriod]);
    setPunches(newPunches);
  };

  const { id: shiftId, employeeId } = createdShifts[0] || {};
  if (!isNil(employeeId) && !isNil(shiftId)) {
    return fetchPunchesByShiftId(groupId, shiftId).then((res) =>
      updatePunchesInState(res?.body || []),
    );
  }
}
