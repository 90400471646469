import { useApi } from '@shared/containers/hooks/api';

import {
  getDisplayGroupsFiltersByFilterId,
  updateDisplayGroupsFiltersByFilterId,
} from '@ManagerPortal/api/filters';

import { useFilters } from '../FiltersContext';

const DEFAULT_SELECTED_VIEW = 'SUMMARY';

const getState = (filters = {}) => filters?.scheduleSelectedFilter ?? {};

export const createInitialState = (filters = {}) => {
  const { displayStatistics = false, selectedView = DEFAULT_SELECTED_VIEW } =
    getState(filters);
  return {
    displayStatistics,
    selectedView,
  };
};

const EMPTY_DISPLAY_GROUPS = Object.freeze([]);
export const useDisplayGroupsFiltersApi = ({
  groupId,
  moduleName,
  filters,
}) => {
  const filterId = filters.data?.scheduleSelectedFilter?.id;

  const [{ data }, fetchDisplayGroups, updateDisplayGroupsApiMethod] = useApi(
    getDisplayGroupsFiltersByFilterId,
    updateDisplayGroupsFiltersByFilterId,
  );

  const displayGroupsWithVariables = data || EMPTY_DISPLAY_GROUPS;

  const updateDisplayGroups = async ({
    update = displayGroupsWithVariables,
    id = filterId,
  } = {}) => {
    try {
      await updateDisplayGroupsApiMethod({
        groupId,
        moduleName,
        filterId: id,
        data: update,
      });
    } catch (error) {
      return error;
    }
  };

  return {
    displayGroupsWithVariables,
    fetchDisplayGroups,
    updateDisplayGroups,
  };
};

export const useStatisticsFilters = () => {
  const {
    filters,
    isDataReceived,
    handleChange,
    displayGroupsWithVariables,
    updateDisplayGroups,
  } = useFilters();

  const { displayStatistics, selectedView } = createInitialState(filters?.data);

  const onDisplayStatisticsChange = (newState) =>
    handleChange({
      displayStatistics: newState ?? !displayStatistics,
    });

  const onViewChange = (viewName) =>
    handleChange({
      selectedView: viewName,
    });

  const onDisplayGroupsChange = (updatedGroup) => {
    const update = [...displayGroupsWithVariables];
    const groupIndex = update.findIndex(
      (group) => group.id === updatedGroup.id,
    );
    if (groupIndex === -1) {
      update.push(updatedGroup);
    } else {
      update[groupIndex] = updatedGroup;
    }

    return updateDisplayGroups({ update });
  };

  return {
    displayStatistics,
    selectedView,
    displayGroupsWithVariables,
    isDataReceived,
    onDisplayStatisticsChange,
    onDisplayGroupsChange,
    onViewChange,
  };
};
