import { stringifyUrl } from '@shared/utils/route';

import { get } from '@ManagerPortal/api';
import { NestedGroup } from '@ManagerPortal/containers/ManagerPortal/utils';

interface CompanyAliasResponse {
  companyAlias?: string;
}

export const fetchGroups = (groupId?: string) => {
  return get<NestedGroup[]>(
    stringifyUrl('/v1/organisation/groups', { groupId }),
  );
};

export const fetchCompanyAlias = (domainId?: number) => {
  return get<CompanyAliasResponse>(
    `/v1/customer/company-alias/by-domain/${domainId}`,
  );
};
