import { useCallback, useMemo } from 'react';

import { TIsoFormat } from '@shared/utils/time/types';
import { hasAtLeastOneItem } from '@shared/utils/typeUtils';

import { ShiftActionResult } from '@ManagerPortal/api/schedule/shifts/types';
import { PunchApiData } from '@ManagerPortal/types/apiResponses/punch';

import { SHIFT_UPDATE_RETURN_TYPES } from '../../Main/ShiftDetailPanel/constants';
import { bulkUpdateShiftPunches } from './invalidateShiftPunches/bulkUpdateShiftPunches';
import { createShiftPunches } from './invalidateShiftPunches/createShiftPunches';
import { deleteShiftPunches } from './invalidateShiftPunches/deleteShiftPunches';
import { singleUpdateShiftPunches } from './invalidateShiftPunches/singleUpdateShiftPunches';

type ShiftTime = {
  begin?: string;
  end?: string;
};
export type Period = {
  startDate: string;
  endDate: string;
};

interface UseInvalidateShiftPunchesProps {
  punchesData: PunchApiData[];
  setPunches: (punches: PunchApiData[]) => void;
  invalidatePunches: () => void;
  startDate: TIsoFormat;
  endDate: TIsoFormat;
  groupId: number;
}

export const DoesPunchBelongToPeriod =
  ({ startDate, endDate }: Period) =>
  ({ begin, end }: ShiftTime) =>
    begin && end && begin < endDate && startDate < end;
export const IsOpenPunchInPeriod =
  ({ startDate, endDate }: Period) =>
  ({ open, begin, end }: ShiftTime & { open?: boolean }) =>
    open && !end && begin && begin < endDate && begin > startDate;

export const useInvalidateShiftPunches = ({
  invalidatePunches,
  ...props
}: UseInvalidateShiftPunchesProps) => {
  const period = useMemo(
    () => ({ startDate: props.startDate, endDate: props.endDate }),
    [props.endDate, props.startDate],
  );

  return useCallback(
    (...[shiftActionResponse, shiftActionType]: ShiftActionResult) => {
      const validShiftActions = ['created', 'updated', 'deleted'] as const;
      const totalAffectedShifts = validShiftActions.reduce(
        (total, action) => total + (shiftActionResponse?.[action]?.length || 0),
        0,
      );

      if (totalAffectedShifts !== 1) {
        return invalidatePunches();
      }
      if (shiftActionType === SHIFT_UPDATE_RETURN_TYPES.BULK) {
        if (hasAtLeastOneItem(shiftActionResponse?.updated)) {
          return bulkUpdateShiftPunches({
            ...props,
            period,
            updatedShifts: shiftActionResponse.updated,
          });
        }
      } else {
        if (hasAtLeastOneItem(shiftActionResponse?.created)) {
          return createShiftPunches({
            ...props,
            period,
            createdShifts: shiftActionResponse.created,
          });
        }

        if (hasAtLeastOneItem(shiftActionResponse?.deleted)) {
          return deleteShiftPunches({
            ...props,
            deletedShifts: shiftActionResponse.deleted,
          });
        }

        if (hasAtLeastOneItem(shiftActionResponse?.updated)) {
          return singleUpdateShiftPunches({
            ...props,
            period,
            updatedShifts: shiftActionResponse.updated,
          });
        }
      }
    },
    [invalidatePunches, period, props],
  );
};
