import { useCallback } from 'react';

import { isNil } from '@shared/utils';
import { TIsoFormat } from '@shared/utils/time/types';
import { hasAtLeastOneItem } from '@shared/utils/typeUtils';

import { ShiftActionResult } from '@ManagerPortal/api/schedule/shifts/types';

import { SHIFT_UPDATE_RETURN_TYPES } from '../../Main/ShiftDetailPanel/constants';
import { ShiftItem } from '../../Main/types';
import { useRemoveShifts } from './useRemoveShifts';

interface UseUpdateStateProps {
  shiftsData: ShiftItem[];
  setShifts: (shifts: ShiftItem[]) => void;
  startDate: TIsoFormat;
  endDate: TIsoFormat;
  invalidateShifts: () => void;
}

export const useUpdateState = ({
  shiftsData,
  setShifts,
  startDate,
  endDate,
  invalidateShifts,
}: UseUpdateStateProps) => {
  const removeShifts = useRemoveShifts(shiftsData);
  // TODO Michal Sadowski: Move this type declaration in usePunches to the shared location
  return useCallback(
    (...[shiftActionResponse, updateType]: ShiftActionResult) => {
      const updateShifts = (updatedShifts: ShiftItem[]) => {
        const newShiftState = shiftsData
          .map((shift) => {
            const updatedShift = updatedShifts.find(
              ({ id }) => id === shift.id,
            );

            if (!updatedShift) {
              return shift;
            }
            if (updatedShift.end < startDate || updatedShift.begin > endDate) {
              return;
            }
            return updatedShift;
          })
          .filter((item) => !isNil(item)) as ShiftItem[];

        return setShifts(newShiftState);
      };

      if (!updateType || updateType === SHIFT_UPDATE_RETURN_TYPES.SINGLE) {
        if (hasAtLeastOneItem(shiftActionResponse?.deleted)) {
          const newShiftState = removeShifts(shiftActionResponse.deleted);
          return setShifts(newShiftState);
        }

        if (hasAtLeastOneItem(shiftActionResponse?.created)) {
          const createdShiftsInPeriod = shiftActionResponse.created.filter(
            ({ begin, end }) => begin < endDate && startDate < end,
          );

          return setShifts(shiftsData.concat([...createdShiftsInPeriod]));
        }

        if (hasAtLeastOneItem(shiftActionResponse?.updated)) {
          return updateShifts(shiftActionResponse.updated);
        }
      }

      if (
        updateType === SHIFT_UPDATE_RETURN_TYPES.BULK &&
        hasAtLeastOneItem(shiftActionResponse?.updated)
      ) {
        const updated = shiftActionResponse.updated[0];
        const bulkUpdated = [
          ...(updated.rejectedShifts || []),
          ...(updated.acceptedShifts || []),
        ];
        return updateShifts(bulkUpdated);
      }

      return invalidateShifts();
    },
    [endDate, invalidateShifts, removeShifts, setShifts, shiftsData, startDate],
  );
};
