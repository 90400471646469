import React, { useState } from 'react';

import { updatePassword as updatePasswordApi } from '@shared/api/authentication';
import AuthPageWrapper from '@shared/components/AuthPageWrapper';
import Button from '@shared/components/ButtonLogin';
import { BUTTON_VARIANT } from '@shared/components/ButtonLogin/constants';
import FormControl from '@shared/components/Form/FormControl';
import FormGroup from '@shared/components/Form/FormGroup';
import Label from '@shared/components/Form/Label';
import LegacyDiv from '@shared/components/LegacyDiv';
import Link from '@shared/components/Link';
import { PASSWORD } from '@shared/constants';
import { useApi } from '@shared/containers/hooks/api';
import { separateAliasFromPath } from '@shared/containers/Login/utils';
import { useNavigate } from '@shared/utils/route';
import { checkPasswordSecurityLevel } from '@shared/utils/validators';

const SetNewPassword = ({
  location: {
    state: { passwordResetToken },
  },
}) => {
  const navigate = useNavigate();
  const [formState, setFormState] = useState({
    password: '',
    confirmPassword: '',
  });
  const [errorStatus, setError] = useState(null);
  const [{ isLoading }, updatePassword] = useApi(updatePasswordApi);
  const { companyAlias } = separateAliasFromPath();

  const handleChange = ({ target: { name, value } }) => {
    setFormState({ ...formState, [name]: value });
    if (errorStatus) {
      setError(null);
    }
  };

  const handleSetNewPassword = () => {
    if (formState.password !== formState.confirmPassword) {
      setError('Password does not match.');
      return;
    }

    if (!checkPasswordSecurityLevel.high.test(formState.password)) {
      setError(
        'Password must be at least 12 characters long and contain at least 2 numbers (0-9) and 2 letters (A-Z).',
      );
      return;
    }

    updatePassword({ hash: passwordResetToken, password: formState.password })
      .then(() => {
        navigate(companyAlias ? `/${companyAlias}` : '/');
      })
      .catch((error) => setError(error.originalError?.body?.message));
  };

  return (
    <AuthPageWrapper>
      <LegacyDiv className="flex-one">
        <LegacyDiv className="flex-center margin-4-bottom">
          <h1 className="login-header">Create new password</h1>
        </LegacyDiv>
        <FormGroup>
          <Label>
            Please create a new password to better secure your account.
          </Label>
          <FormControl
            name="password"
            type="password"
            value={formState.password}
            onChange={handleChange}
            maxLength={PASSWORD.MAX_LENGTH}
          />
        </FormGroup>
        <FormGroup
          feedback={
            errorStatus ? (
              <span className="font__xSmallRegular color-warning">
                {errorStatus}
              </span>
            ) : (
              <span className="font__xSmallRegular">
                Password must be at least 12 characters long and contain at
                least 2 numbers (0-9) and 2 letters (A-Z).
              </span>
            )
          }>
          <Label>Confirm password</Label>
          <FormControl
            name="confirmPassword"
            type="password"
            value={formState.confirmPassword}
            onChange={handleChange}
            maxLength={PASSWORD.MAX_LENGTH}
          />
        </FormGroup>
      </LegacyDiv>
      <Button
        text="Save"
        shouldGrow
        isLoading={isLoading}
        onClick={handleSetNewPassword}
        variant={BUTTON_VARIANT.LOGIN}
      />
      <LegacyDiv className="text-center padding-3-top">
        <span>
          <span>Please read our</span>
          <Link
            to="https://www.quinyx.com/privacy#quinyx-application"
            className="link-primary padding-1-left"
            target="_blank"
            external>
            Privacy Policy
          </Link>
        </span>
      </LegacyDiv>
    </AuthPageWrapper>
  );
};

export default SetNewPassword;
