import { SORT_CRITERIA, SORT_ORDER } from '@shared/constants';

import { SORT_VALUES } from '@ManagerPortal/containers/Schedule/Main/Filters/FiltersProvider/DisplayOptionsFilters/constants';

export const METRIC_DISPLAY_VALUES = {
  TOTALS: 'totals',
  BY_AGREEMENT: 'byAgreement',
} as const;

export const VIEW_OPTIONS = [
  { label: 'day', value: 'day' },
  { label: 'week', value: 'week' },
  { label: 'all', value: 'all' },
] as const;

export const SORT_OPTIONS = [
  {
    value: SORT_VALUES.START_TIME,
    label: 'Start time',
    criteria: SORT_CRITERIA.START_TIME,
    order: SORT_ORDER.ASC,
  },
  {
    value: SORT_VALUES.NAME_ASC,
    label: 'label.firstName',
    criteria: SORT_CRITERIA.EMPLOYEE_NAME,
    order: SORT_ORDER.ASC,
  },
  {
    value: SORT_VALUES.NAME_DESC,
    label: 'label.firstName',
    criteria: SORT_CRITERIA.EMPLOYEE_NAME,
    order: SORT_ORDER.DESC,
  },
  {
    value: SORT_VALUES.LAST_NAME_ASC,
    label: 'label.lastName',
    criteria: SORT_CRITERIA.EMPLOYEE_LAST_NAME,
    order: SORT_ORDER.ASC,
  },
  {
    value: SORT_VALUES.LAST_NAME_DESC,
    label: 'label.lastName',
    criteria: SORT_CRITERIA.EMPLOYEE_LAST_NAME,
    order: SORT_ORDER.DESC,
  },
] as const;

export const PERIOD_OPTIONS = [
  {
    value: 'selected-period',
    label: 'schedule.selected_period',
  },
  {
    value: 'template-period',
    label: 'schedule.template_period',
  },
] as const;

export const METRIC_DISPLAY_MODE_OPTIONS = [
  {
    value: METRIC_DISPLAY_VALUES.TOTALS,
    label: 'schedule.metricDisplayOption.totals',
  },
  {
    value: METRIC_DISPLAY_VALUES.BY_AGREEMENT,
    label: 'schedule.metricDisplayOption.byAgreement',
  },
] as const;

export const SCHEDULE_PANELS = {
  entities: {
    shift: 'shift',
    punch: 'punch',
    absence: 'absence',
    availability: 'availability',
    availabilityInfo: 'availabilityInfo',
    leaveRequest: 'leaveRequest',
    employeeKPIs: 'employeeKPIs',
  },
  filter: 'filter',
  displayOptions: 'displayOptions',
  employeeInformation: 'employeeInformation',
} as const;
