import { createContext, useContext } from 'react';

export const FiltersContext = createContext(undefined);
export const useFilters = () => {
  const context = useContext(FiltersContext);
  if (context == null) {
    throw new Error('useFilters must be used within FiltersProvider');
  }
  return context;
};
