import { useCallback, useEffect, useMemo } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import { errorMessageNot403 } from '@shared/api/utils';
import { notify } from '@shared/components/Notifications/utils';
import { indexArrayByPropName } from '@shared/utils';

import { fetchAllShifts } from '@ManagerPortal/api/schedule/shifts';

import { ShiftItem } from '../../Main/types';
import { useScheduleOptimisticUpdates } from '../../ScheduleOptimisticUpdatesProvider';
import { SCHEDULE_DATA_CACHE_TIME } from '../useFetchScheduleData';
import { useGetScheduleDataHookParams } from '../utils/useGetScheduleDataHookParams';
import { useInvalidateForEmployees } from './useInvalidateForEmployees';
import { useRemoveShifts } from './useRemoveShifts';
import { useUpdateState } from './useUpdateState';

export const QUERY_KEY = '/v2/schedule/shifts/by-group';

export const useShiftsQuery = () => {
  const { currentGroupId, start, end, isPlaceholderFilterData } =
    useGetScheduleDataHookParams();
  const queryClient = useQueryClient();
  const { checkCompletedUpdates } = useScheduleOptimisticUpdates();

  const shiftQuery = useQuery({
    queryKey: [QUERY_KEY, start, end, currentGroupId],
    queryFn: () =>
      fetchAllShifts(currentGroupId, start, end).then((res) => res?.body),
    enabled: !isPlaceholderFilterData,
    cacheTime: SCHEDULE_DATA_CACHE_TIME,
  });

  const indexedShifts = useMemo(
    () => (shiftQuery.data ? indexArrayByPropName(shiftQuery.data) : {}),
    [shiftQuery.data],
  );

  const invalidateShifts = useCallback(
    () => queryClient.invalidateQueries([QUERY_KEY]),
    [queryClient],
  );

  const setShifts = (newData: ShiftItem[]) => {
    queryClient.setQueryData<ShiftItem[]>(
      [QUERY_KEY, start, end, currentGroupId],
      newData,
    );
  };

  const handleSetShifts = (shifts: ShiftItem[]) => {
    checkCompletedUpdates(shifts);
    setShifts(shifts);
  };

  // NOTE this is due to onSettled being deprecated
  useEffect(() => {
    if (shiftQuery.isSuccess) {
      checkCompletedUpdates(shiftQuery.data || []);
    }
  }, [checkCompletedUpdates, shiftQuery.data, shiftQuery.isSuccess]);

  const commonProps = {
    groupId: currentGroupId,
    startDate: start,
    endDate: end,
    shiftsData: shiftQuery.data || [],
    setShifts: handleSetShifts,
  };

  const removeShifts = useRemoveShifts(shiftQuery.data || []);
  const invalidateForEmployees = useInvalidateForEmployees(commonProps);
  const updateState = useUpdateState({ ...commonProps, invalidateShifts });

  useEffect(() => {
    const errorMessage = errorMessageNot403(shiftQuery.error);
    if (errorMessage) {
      notify(`Shifts: ${errorMessage}`, 'error');
    }
  }, [shiftQuery?.error]);

  return useMemo(
    () => ({
      isLoading: shiftQuery.isLoading,
      isError: shiftQuery.isError,
      isSuccess: shiftQuery.isSuccess,
      errors: shiftQuery.error,
      data: indexedShifts,
      invalidate: invalidateShifts,
      removeShifts,
      invalidateForEmployees,
      updateState,
    }),
    [
      shiftQuery.isLoading,
      shiftQuery.isError,
      shiftQuery.isSuccess,
      shiftQuery.error,
      indexedShifts,
      invalidateShifts,
      removeShifts,
      invalidateForEmployees,
      updateState,
    ],
  );
};
