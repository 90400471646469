import { ShiftItem } from '@ManagerPortal/containers/Schedule/Main/types';
import { PunchApiData } from '@ManagerPortal/types/apiResponses/punch';

import { Period } from '../useInvalidateShiftPunches';
import { updatePunch } from './updatePunch';

interface UpdateShiftPunchesProps {
  groupId: number;
  updatedShifts: ShiftItem[];
  punchesData: PunchApiData[];
  setPunches: (punches: PunchApiData[]) => void;
  period: Period;
}

export function singleUpdateShiftPunches({
  updatedShifts,
  ...props
}: UpdateShiftPunchesProps) {
  const { id: updatedShiftId } = updatedShifts[0] || {};
  if (!updatedShiftId) return;
  return updatePunch({ ...props, updatedShiftId });
}
