import React, {
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';

import { MODULE_NAMES } from '@shared/constants';
import { useApi, useFetch } from '@shared/containers/hooks/api';
import { ApiResponse } from '@shared/types';
import { isNil, isNot } from '@shared/utils';

import {
  createScheduleFilter,
  getSavedFilters,
  getScheduleFilterById,
  updateScheduleFilterById,
} from '@ManagerPortal/api/filters';
import {
  ScheduleFilterById,
  ScheduleSelectedFilter,
  ScheduleSelectedFilterExtended,
} from '@ManagerPortal/api/filters/types';
import useDebounce from '@ManagerPortal/components/hooks/useDebounce';
import { useDisplayGroupsFiltersApi } from '@ManagerPortal/containers/Schedule/Main/Filters/FiltersProvider/StatisticsFilters';

import { createInitialState } from './createInitialState';
import { FiltersContext } from './FiltersContext';

export {
  useFilterValues,
  useFilterFields,
  useFilterGroups,
  useFilterFeatures,
  useNumberOfAppliedFilters,
} from './FilterGroups';
export {
  useFavoriteFilters,
  useDeleteFavoriteFilter,
  useUpdateFavoriteFilter,
  useSaveFavoriteFilter,
} from './FavoriteFilters';
export { useStatisticsFilters } from './StatisticsFilters';

type InitFn<T, Args extends unknown[]> = (
  ...args: Args
) => Promise<ApiResponse<T>> | undefined;

// Holds the current state of filters. We need it for the instantiation of
// some of the "slices"
let cache$ = {} as ScheduleFilterById; // Not perfect, but it's a start
const init =
  <T, Args extends unknown[]>(fn: InitFn<T, Args>) =>
  async (...args: Args) => {
    const response = await fn(...args);

    if (!response) throw new Error('Expected "error" of no-filters call');

    response.body = createInitialState(response.body || {}, cache$);
    return response;
  };

const isAnonymous = (filter: { id: string; name: string | null }) =>
  isNot(filter.name);

const FIVE_SECONDS = 5000; // ms
const NO_FETCH_FILTER_ID = 'NO_FETCH_FILTER_ID';

const isValidActiveFilterId = (filterId: string | null): filterId is string =>
  typeof filterId === 'string' && filterId !== NO_FETCH_FILTER_ID;

const getScheduleFilter = (
  groupId: number,
  moduleName: keyof typeof MODULE_NAMES,
  activeFilterId: string | null,
) => {
  if (isValidActiveFilterId(activeFilterId)) {
    return getScheduleFilterById(groupId, moduleName, activeFilterId);
  }
};

interface FiltersProviderProps {
  groupId: number;
  moduleName: keyof typeof MODULE_NAMES;
  children: ReactNode;
}

export const FiltersProvider = ({
  groupId,
  moduleName,
  children,
}: FiltersProviderProps) => {
  const [activeFilterId, setActiveFilterId] = useState<string | null>(null);

  const activeFilter = useFetch(init(getScheduleFilter), [
    groupId,
    moduleName,
    activeFilterId,
  ]);

  const savedFilters = useFetch(init(getSavedFilters), [groupId, moduleName]);

  const filters = useMemo(() => {
    return {
      data: {
        scheduleSelectedFilter: activeFilter.data?.scheduleSelectedFilter,
        savedScheduleFilters: savedFilters.data?.savedScheduleFilters,
      },
    };
  }, [savedFilters.data, activeFilter.data]);

  const {
    displayGroupsWithVariables,
    fetchDisplayGroups,
    updateDisplayGroups,
  } = useDisplayGroupsFiltersApi({
    groupId,
    moduleName,
    filters,
  });

  useEffect(() => {
    if (savedFilters?.data?.scheduleSelectedFilter) {
      activeFilter.setData({
        savedScheduleFilters: activeFilter.data?.savedScheduleFilters || null,
        scheduleSelectedFilter: activeFilter?.data?.scheduleSelectedFilter?.name
          ? activeFilter.data.scheduleSelectedFilter
          : savedFilters.data.scheduleSelectedFilter,
      });
    }
  }, [savedFilters.data]);

  useLayoutEffect(() => {
    cache$ = activeFilter.data || cache$;
  });
  const [, createFilter] = useApi(createScheduleFilter);
  const [, updateFilterById] = useApi(updateScheduleFilterById);

  const updateFilter = useDebounce(
    async (filterId: string, payload: ScheduleSelectedFilterExtended) => {
      // BS filters should not be saved
      if (moduleName === MODULE_NAMES.BASE_SCHEDULE) return;

      const request = isNil(filterId) ? createFilter : updateFilterById;

      const {
        data,
        isError,
      }: { data: ScheduleSelectedFilter; isError: boolean } = await request({
        groupId,
        moduleName,
        filterId,
        data: payload,
      }).catch((e) => {
        return e;
      });

      // We will update the state only when creating new filter, to save filterId
      if (isNil(filterId) && !isError) {
        activeFilter.setData({
          savedScheduleFilters: activeFilter.data?.savedScheduleFilters || null,
          scheduleSelectedFilter: data,
        });
      }
    },
    FIVE_SECONDS,
  );

  const handleFilterUnload = useCallback(() => {
    const anonymousFilterId =
      savedFilters.data?.savedScheduleFilters.find(isAnonymous)?.id;

    const payload = {
      ...(activeFilter.data?.scheduleSelectedFilter ?? {}),
      id: anonymousFilterId ?? '', // Don't know what's the logic behind anonymousFilterId
      name: undefined,
    };

    updateFilterById({
      groupId,
      moduleName,
      filterId: anonymousFilterId ?? '', // Don't know what's the logic behind anonymousFilterId
      // TODO Andrea Tota: Check & refine following Schedule/Filters refactor
      // @ts-expect-error Needs to be fixed
      data: payload,
    }).catch((e) => {
      return e;
    });
  }, [
    activeFilter?.data?.scheduleSelectedFilter,
    groupId,
    moduleName,
    savedFilters.data?.savedScheduleFilters,
    updateFilterById,
  ]);

  const handleChange = (update: ScheduleSelectedFilterExtended) => {
    const anonymousFilterId =
      savedFilters.data?.savedScheduleFilters?.find(isAnonymous)?.id;

    const payload = {
      ...(activeFilter?.data?.scheduleSelectedFilter ?? {}),
      ...update,
      id: anonymousFilterId,
      name: undefined,
    };

    setActiveFilterId(NO_FETCH_FILTER_ID);
    activeFilter.setData({
      // TODO Andrea Tota: Check & refine following Schedule/Filters refactor
      // @ts-expect-error Needs to be fixed
      scheduleSelectedFilter: payload,
    });

    updateFilter(anonymousFilterId, payload);
  };

  // This solution is working 90% of the time when reloading window,
  // since it is an edge case we can live with it in my opinion for now
  useEffect(() => {
    window.addEventListener('beforeunload', handleFilterUnload);

    return () => {
      window.removeEventListener('beforeunload', handleFilterUnload);
    };
  }, [handleFilterUnload]);

  const [isDataReceived, setIsDataReceived] = useState(false);

  useEffect(() => {
    // we are only interested in initial fetch
    if (savedFilters.isAttempted && isNot(savedFilters.isLoading)) {
      setIsDataReceived(true);
    }
  }, [savedFilters.isAttempted, savedFilters.isLoading]);

  const meta = { groupId, moduleName };
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    activeFilterId:
      activeFilterId ?? activeFilter.data?.scheduleSelectedFilter?.id,
    setActiveFilterId,
    handleChange,
    isDataReceived,
    meta,
    filters,
    activeFilter,
    savedFilters,
    displayGroupsWithVariables,
    fetchDisplayGroups,
    updateDisplayGroups,
  };

  return (
    <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>
  );
};
