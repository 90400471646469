import React, { useEffect } from 'react';

import {
  BrowserRouter,
  NavigateFunction,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import Analytics from '@ManagerPortal/utils/analytics';

const LocationMonitor = () => {
  const location = useLocation();
  useEffect(() => {
    if (location) {
      Analytics.trackLocation();
    }
  }, [location]);

  return null;
};

type TNavigatorProps = {
  navigateAction: NavigateFunction | null;
  setNavigate: (navigateFunction: NavigateFunction) => void;
  navigate: (
    to: string,
    options?: object /* TODO Ed Brown: this should be NavigateOptions */,
  ) => void;
};

const navigator: TNavigatorProps = {
  navigateAction: null,

  setNavigate: (navigateFunction: NavigateFunction) => {
    navigator.navigateAction = navigateFunction;
  },

  navigate: (to, options) => {
    console.log('todo edb - using global navigation!');
    if (!navigator.navigateAction) {
      throw new Error('Navigation action not set');
    }

    navigator.navigateAction(to, options);
  },
};

const NavigationService = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigator.setNavigate(navigate);
  }, [navigate]);

  return null;
};
export const { navigate } = navigator;

export const BrowserLocationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <BrowserRouter>
      <>
        <LocationMonitor />
        <NavigationService />
        {children}
      </>
    </BrowserRouter>
  );
};
