import { isNotNil } from '@shared/utils';

import { SCHEDULE_VIEW_MODE_VALUES } from '@ManagerPortal/containers/Schedule/Main/const';

import { useFilters } from '../FiltersContext';
import { TScheduleFilters } from '../FiltersContext/types';

const getState = (filters: TScheduleFilters) =>
  isNotNil(filters?.scheduleSelectedFilter?.scheduleViewMode)
    ? filters?.scheduleSelectedFilter
    : {
        scheduleViewMode: SCHEDULE_VIEW_MODE_VALUES.EMPLOYEE,
      };

const createInitialState = (filters: TScheduleFilters) => {
  const { scheduleViewMode } = getState(filters);
  return {
    scheduleViewMode,
  };
};

export const useScheduleViewModeFilters = () => {
  const { filters, handleChange } = useFilters();

  const { scheduleViewMode } = createInitialState(filters?.data);

  return {
    scheduleViewMode,
    onScheduleViewChange: (viewName: SCHEDULE_VIEW_MODE_VALUES) =>
      handleChange({
        scheduleViewMode: viewName,
      }),
  };
};
