import { QueryClient } from '@tanstack/react-query';

import { notifyAboutError } from '@shared/components/Notifications/utils';

import { EmployeeKpiByEmployee } from '@ManagerPortal/api/schedule/employees/types';

import { DefaultHoursDictionary, EmployeeKpiDictionary } from '../types';

export const mergeNewScheduledHours = (
  existingHours: EmployeeKpiDictionary = {},
  scheduledHoursResponse: EmployeeKpiByEmployee[],
) => {
  const newHours: DefaultHoursDictionary = {
    ...existingHours,
  };

  scheduledHoursResponse.forEach(
    ({
      employeeId,
      totals: responseTotals,
      agreements: responseAgreements,
    }) => {
      newHours[employeeId] = {
        ...newHours[employeeId],
        totals: { ...newHours[employeeId]?.totals, ...responseTotals },
        agreements: newHours[employeeId]?.agreements?.map(
          (currentAgreement) => ({
            ...currentAgreement,
            variables: {
              ...currentAgreement.variables,
              ...responseAgreements.find(
                ({ agreementId }) =>
                  agreementId === currentAgreement.agreementId,
              )?.variables,
            },
          }),
        ),
      };
    },
  );

  return newHours;
};

export const handleErrorWithDefaultHours = (
  employeeIds: string[],
  queryClient: QueryClient,
  queryKey: unknown[],
  error: unknown,
  existingHours: EmployeeKpiDictionary = {},
) => {
  const defaultHoursDictionary = employeeIds.reduce(
    (dictionary: DefaultHoursDictionary, employeeId) => {
      if (!Object.keys(existingHours).includes(employeeId)) {
        dictionary[employeeId] = { totals: {}, agreements: [] };
      }
      return dictionary;
    },
    {},
  );

  queryClient.setQueryData(queryKey, {
    ...existingHours,
    ...defaultHoursDictionary,
  });
  notifyAboutError(error);
};
