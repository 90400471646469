import {
  PersistedClient,
  Persister,
} from '@tanstack/react-query-persist-client';
import { del, get } from 'idb-keyval';

import { getUserId } from '@shared/config/user';
import { debounce } from '@shared/utils';

import { persistenceLayerToggle } from '@ManagerPortal/featureToggles';

import { reportPerstistenceLayerError } from './utils';
import { decryptData, hashClientState } from './utils/encrypt';

const INDEX_DB_KEY = 'reactQuery';

let persistClientWorker: Worker | undefined;

try {
  persistClientWorker = new Worker(
    new URL('./persistClientWorker.ts', import.meta.url),
  );
} catch (error) {
  reportPerstistenceLayerError('Failed to create worker', error);
}

export const createIDBPersister = (): Persister => ({
  // Saves the current state of the QueryClient to IndexedDB
  persistClient: debounce(async (persistedClient: PersistedClient) => {
    if (!persistenceLayerToggle) {
      return;
    }

    if (!persistClientWorker) {
      return;
    }

    try {
      const userId = getUserId();

      if (!userId) {
        reportPerstistenceLayerError('Failed to persist client, no user ID');
        return;
      }
      persistClientWorker.postMessage({ persistedClient, userId });
    } catch (error) {
      if (error instanceof DOMException && error.name === 'DataCloneError') {
        reportPerstistenceLayerError(
          'Failed to persist client, this is likely due to passing the whole response from superagent (error or success response) from React Query',
          { error },
        );
        return;
      }
      reportPerstistenceLayerError('Failed to persist client', error);
    }
  }, 1000),

  // Called when initializing the QueryClient first time, hydrates client with
  // stored state from IndexedDB
  restoreClient: async () => {
    if (!persistenceLayerToggle) {
      return;
    }

    try {
      const storedData = await get(INDEX_DB_KEY);
      const userId = getUserId();

      if (storedData && userId) {
        const decryptedClientState = await decryptData(
          storedData.encryptedClientState,
          storedData.iv,
          storedData.salt,
          userId,
        );

        // Verify integrity using the hash
        const computedHash = await hashClientState(decryptedClientState);

        if (computedHash !== storedData.clientStateHash) {
          reportPerstistenceLayerError('Client state integrity check failed.');
          await del(INDEX_DB_KEY);
          return null;
        }

        return decryptedClientState;
      }
    } catch (error) {
      reportPerstistenceLayerError('Failed to restore client', error);
    }
  },

  // Removes the stored client data from IndexedDB
  removeClient: async () => {
    if (!persistenceLayerToggle) {
      return;
    }

    try {
      await del(INDEX_DB_KEY);
    } catch (error) {
      reportPerstistenceLayerError('Failed to remove client', error);
    }
  },
});

export const idbPersister = createIDBPersister();
