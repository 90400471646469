import { TIsoFormat } from '@shared/utils/time/types';

import { fetchShiftsByEmployeeIds } from '@ManagerPortal/api/schedule/shifts';

import { ShiftItem } from '../../Main/types';

interface UseInvalidateForEmployeesProps {
  groupId: number;
  startDate: TIsoFormat;
  endDate: TIsoFormat;
  shiftsData: ShiftItem[];
  setShifts: (shifts: ShiftItem[]) => void;
}

export const useInvalidateForEmployees =
  ({
    groupId,
    startDate,
    endDate,
    shiftsData,
    setShifts,
  }: UseInvalidateForEmployeesProps) =>
  (employeeIds: number[]) =>
    fetchShiftsByEmployeeIds(groupId, startDate, endDate, employeeIds).then(
      (res) => {
        if (!res) {
          return;
        }
        const updatedShifts = shiftsData
          .filter(
            ({ employeeId }) =>
              !employeeId || !employeeIds.includes(employeeId),
          )
          .concat(res.body);

        setShifts(updatedShifts);
      },
    );
