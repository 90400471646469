import { useMemo } from 'react';

import {
  DEFAULT_OPTIONS,
  DISPLAY_OPTIONS_FILTERS,
  GROUP_KEY_OPTIONS,
  GROUP_KEY_VALUES,
  METRIC_PAIRS,
} from '../DisplayOptionsFilters/constants';
import {
  TDisplayOptionsType,
  TGroupKeyDisplayOptions,
} from '../DisplayOptionsFilters/types';
import { useEmployeeDisplayOptions } from '../EmployeeDisplayOptionsFilters';
import { useFilters } from '../FiltersContext';
import { TScheduleFilters } from '../FiltersContext/types';

interface DisplayOptionsHandlerProps {
  options: ReadonlyArray<TGroupKeyDisplayOptions>;
  property: TDisplayOptionsType;
}

const DEFAULT_OPTIONS_WITH_GROUP = {
  ...DEFAULT_OPTIONS,
  group: GROUP_KEY_VALUES.SHIFT_TYPE,
};

const getState = (filters: TScheduleFilters) => {
  return {
    ...DEFAULT_OPTIONS_WITH_GROUP,
    ...filters?.scheduleSelectedFilter?.businessListDisplayOptions,
    group:
      filters?.scheduleSelectedFilter?.businessListDisplayOptions?.group ??
      DEFAULT_OPTIONS_WITH_GROUP.group,
  };
};

export const createInitialState = (filters: TScheduleFilters) => {
  const displayOptions = getState(filters);

  return {
    ...displayOptions,
  };
};

export const useBusinessDisplayOptions = () => {
  const { filters, handleChange } = useFilters();

  const employeeDisplayOptions = useEmployeeDisplayOptions();

  const createHandler = <T extends DisplayOptionsHandlerProps>({
    options,
    property,
  }: T) => {
    return function displayOptionsHandler(
      newValue: T['options'][number]['value'],
    ) {
      if (!options.find((option) => option.value === newValue)) {
        throw new Error(`There is no option with the value "${newValue}"`);
      }

      handleChange({
        businessListDisplayOptions: {
          ...getState(filters?.data),
          [property]: newValue,
        },
      });
    };
  };

  const changeGroup = createHandler({
    options: GROUP_KEY_OPTIONS,
    property: DISPLAY_OPTIONS_FILTERS.GROUP,
  });

  const { group } = createInitialState(filters?.data);

  const displayOptionsData = useMemo(
    () => ({
      group,
    }),
    [group],
  );

  return useMemo(
    () => ({
      ...employeeDisplayOptions,
      ...DEFAULT_OPTIONS,
      metric: METRIC_PAIRS[DEFAULT_OPTIONS.metric], // override the options from employeeDisplayOptions with the default options
      // (in business view mode, the employeeDisplayOptions will remain static and unchanged)
      ...displayOptionsData,
      changeGroup,
      changeMetric: undefined,
      changeMetricDisplayMode: undefined,
      changeMetricPeriod: undefined,
      changeSorting: undefined,
    }),
    [displayOptionsData, changeGroup, employeeDisplayOptions],
  );
};
