import { stringifyUrl } from '@shared/utils/route';
import { TDateFormat } from '@shared/utils/time/types';

import { get } from '../..';
import { ScheduleAvailabilityByGroup } from './types';

export * from './index-old';

export const fetchAvailabilities = (
  groupId: number,
  start: TDateFormat,
  end: TDateFormat,
) => {
  const requestUrl = `/v1/schedule-availability/groups/${groupId}`;
  return get<ScheduleAvailabilityByGroup[]>(
    stringifyUrl(requestUrl, { start, end }),
  );
};
