import { del, get, post, put } from '../..';
import { stringifyUrl } from '../../../../shared/utils/route';

export const fetchSalaries = (employeeId, groupId, date) =>
  get(
    stringifyUrl(
      `/v1/schedule/salary-outcome/by-group/${groupId}/by-employee/${employeeId}`,
      {
        date,
      },
    ),
  );

export const addSalaryType = (timePunchId, salaryTypeId, hours, groupId) => {
  return post(`/v1/schedule/manualsalaries/by-group/${groupId}`, {
    timePunchId,
    salaryTypeId,
    hours,
  });
};

export const fetchManualSalaries = (punchId, groupId) =>
  get(
    stringifyUrl(
      `/v1/schedule/manualsalaries/salarytypes/by-group/${groupId}/by-timepunch/${punchId}`,
    ),
  );

export const updateSalaryType = (
  manualSalaryId,
  hours,
  salaryTypeId,
  timePunchId,
  groupId,
) => {
  return put(
    `/v1/schedule/manualsalaries/by-group/${groupId}/by-id/${manualSalaryId}`,
    {
      hours,
      salaryTypeId,
      timePunchId,
    },
  );
};

export const deleteSalaryType = (manualSalaryId, groupId) => {
  return del(
    `/v1/schedule/manualsalaries/by-group/${groupId}/by-id/${manualSalaryId}`,
  );
};

export const approveSalaries = (salaries, groupId) => {
  return put(
    `/v1/schedule/manualsalaries/by-group/${groupId}/approve`,
    salaries,
  );
};

export const fetchSalaryWarnings = (
  groupId,
  startDate,
  endDate,
  employeeIds = [],
) =>
  post(
    stringifyUrl(`/v1/schedule/additional-warnings/by-group/${groupId}`, {
      startDate,
      endDate,
    }),
    employeeIds,
  );

export const fetchSalaryTypesByAgreement = (groupId, agreementId) =>
  get(
    `/v1/schedule/manualsalaries/salarytypes/by-group/${groupId}/by-agreement/${agreementId}`,
  );

export const fetchSalaryOnTasksSettingsOnCustomerLevel = (groupId) =>
  get(`/v1/schedule/salary-on-tasks-customer/by-group/${groupId}`);
