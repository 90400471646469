import { post } from '../..';
import { stringifyUrl } from '../../../../shared/utils/route';

export const fetchScheduledHours = (forDate, groupId, employeeIds) => {
  const url = stringifyUrl(
    `/v1/statistics/scheduled-hours/by-group/${groupId}/by-employee-ids`,
    { forDate },
  );

  return post(url, {
    employeeIds,
  });
};

export const fetchNominalHours = (forDate, groupId, employeeIds) => {
  const url = stringifyUrl(
    `/v1/statistics/nominal-hours/by-group/${groupId}/by-employee-ids`,
    { forDate },
  );

  return post(url, { employeeIds });
};

export const fetchNominalHoursByPeriod = (
  startDate,
  endDate,
  groupId,
  employeeIds,
  period,
) => {
  return post(
    stringifyUrl(`/v1/statistics/nominal-hours/by-group/${groupId}/by-period`, {
      startDate,
      endDate,
    }),
    { employeeIds, period },
  );
};

export const fetchScheduledHoursByPeriod = (
  startDate,
  endDate,
  groupId,
  employeeIds,
  period,
) => {
  return post(
    stringifyUrl(
      `/v1/statistics/scheduled-hours/by-group/${groupId}/by-period`,
      {
        startDate,
        endDate,
      },
    ),
    { employeeIds, period },
  );
};
