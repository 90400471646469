import { PersistedClient } from '@tanstack/react-query-persist-client';

const getDerivedKey = async (salt: BufferSource, userId: number) => {
  if (!userId) {
    return;
  }

  const encoder = new TextEncoder();
  const keyMaterial = await crypto.subtle.importKey(
    'raw',
    encoder.encode(String(userId)),
    { name: 'PBKDF2' },
    false,
    ['deriveKey'],
  );

  const derivedKey = await crypto.subtle.deriveKey(
    {
      name: 'PBKDF2',
      iterations: 500,
      hash: 'SHA-256',
      salt,
    },
    keyMaterial,
    { name: 'AES-GCM', length: 128 },
    false,
    ['encrypt', 'decrypt'],
  );
  return derivedKey;
};

export const encryptData = async (
  persistedClient: PersistedClient,
  userId: number,
) => {
  const salt = crypto.getRandomValues(new Uint8Array(16));
  const key = await getDerivedKey(salt, userId);

  if (!key) {
    return;
  }

  const encoder = new TextEncoder();
  const iv = crypto.getRandomValues(new Uint8Array(12)); // 12 bytes for AES-GCM
  const encryptedData = await crypto.subtle.encrypt(
    { name: 'AES-GCM', iv },
    key,
    encoder.encode(JSON.stringify(persistedClient)),
  );
  return { encryptedData, iv, salt };
};

export const decryptData = async (
  encryptedClient: ArrayBuffer,
  iv: Uint8Array,
  salt: BufferSource,
  userId: number,
) => {
  const key = await getDerivedKey(salt, userId);

  if (!key) {
    return;
  }
  const decryptedBuffer = await crypto.subtle.decrypt(
    {
      name: 'AES-GCM',
      iv,
    },
    key,
    encryptedClient,
  );
  const decoder = new TextDecoder();
  return JSON.parse(decoder.decode(decryptedBuffer));
};

// Hash data using SHA-1 for better performance
export const hashClientState = async (persistedClient: PersistedClient) => {
  const encoder = new TextEncoder();
  const dataBuffer = encoder.encode(JSON.stringify(persistedClient));
  const hashBuffer = await crypto.subtle.digest('SHA-1', dataBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
};
