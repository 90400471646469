import {
  BankHolidayToEdit,
  DayRule,
  OverTime,
  SalaryType,
  ShiftRule,
  UnitGroup,
  UnsocialTime,
  UnsocialTimeTypes,
} from '@ManagerPortal/containers/Account/AgreementTemplates/BankHolidays/holidayTypes';
import { AdditionalField } from '@ManagerPortal/containers/Schedule/Main/autoApiTypes';

import { get, post, put } from '..';

export * from './indexJs';

export const fetchUnitGroupsByGroupId = (groupId: number) =>
  get<UnitGroup[]>(`/v1/organisation/unit-groups/by-group/${groupId}`);

export const fetchSalaryTypesOnAccount = (groupId: number) =>
  get<SalaryType[]>(`/v1/salaries/salarytypes/by-group/${groupId}`);

export const fetchBankHolidayDayRules = (bankHolidayId: string) =>
  get<DayRule[]>(`/v1/bank-holidays/${bankHolidayId}/rules`);

export const fetchUnsocialTimeTypes = (groupId: number) =>
  get<UnsocialTimeTypes>(`/v1/employee/ut-period-types/by-group/${groupId}`);

export const fetchBankHolidayShiftRules = (bankHolidayId: string) =>
  get<ShiftRule[]>(`/v1/bank-holidays/${bankHolidayId}/salaryRules`);

export const fetchBankHolidayOvertime = (bankHolidayId: string) =>
  get<OverTime[]>(`/v1/bank-holidays/${bankHolidayId}/ot`);

export const fetchBankHolidayUnsocialTime = (bankHolidayId: string) =>
  get<UnsocialTime[]>(`/v1/bank-holidays/${bankHolidayId}/ut`);

export const fetchBankHolidayById = (bankHolidayId: string) => {
  return get<BankHolidayToEdit>(`/v1/bank-holidays/${bankHolidayId}`);
};

export const updateBankHoliday = (
  bankHolidayId: string,
  params: BankHolidayToEdit,
) => put<BankHolidayToEdit>(`/v1/bank-holidays/${bankHolidayId}`, params);

export const updateBankHolidayUnsocialTime = (
  bankHolidayId: string,
  params: UnsocialTime[],
) =>
  post<UnsocialTime[]>(
    `/v1/bank-holidays/${bankHolidayId}/ut/overwrite`,
    params,
  );

export const updateBankHolidayOvertime = (
  bankHolidayId: string,
  params: OverTime[],
) =>
  post<OverTime[]>(`/v1/bank-holidays/${bankHolidayId}/ot/overwrite`, params);

export const updateBankHolidayDayRules = (
  bankHolidayId: string,
  params: { rules: DayRule[] },
) =>
  post<DayRule[]>(`/v1/bank-holidays/${bankHolidayId}/rules/overwrite`, params);

export const updateBankHolidayShiftRules = (
  bankHolidayId: string,
  params: { rules: ShiftRule[] },
) =>
  post<ShiftRule[]>(
    `/v1/bank-holidays/${bankHolidayId}/salaryRules/overwrite`,
    params,
  );

const SHIFT_OFFER_KEY = 'SHIFT_OFFER';

type OfferShiftsResponse = {
  value: 'true' | 'false';
};

export const fetchIsUsingOfferShifts = (domainGroupId: number) => {
  return get<OfferShiftsResponse>(
    `/v1/customer/domain/${domainGroupId}/settings/${SHIFT_OFFER_KEY}`,
  );
};
export const createOfferShiftsSettings = (
  domainGroupId: number,
  payload: OfferShiftsResponse,
) => {
  return post<OfferShiftsResponse>(
    `/v1/customer/domain/${domainGroupId}/settings/${SHIFT_OFFER_KEY}`,
    payload,
  );
};
export const updateOfferShiftsSettingApi = (
  domainGroupId: number,
  payload: { value: boolean },
) => {
  return put<OfferShiftsResponse>(
    `/v1/customer/domain/${domainGroupId}/settings/${SHIFT_OFFER_KEY}`,
    payload,
  );
};
export const getUsingOfferShifts = (groupId: number) => {
  return get<OfferShiftsResponse>(
    `/v1/schedule/shift-offers/groups/${groupId}/setting`,
  );
};

export const fetchAdditionalFields = (groupId: number) =>
  get<AdditionalField[]>(`/v1/employee/additional-fields/by-group/${groupId}`);
