import { BulkActionReturn } from '@ManagerPortal/api/schedule/shifts/types';
import { PunchApiData } from '@ManagerPortal/types/apiResponses/punch';

import { Period } from '../useInvalidateShiftPunches';
import { updatePunch } from './updatePunch';

interface UpdateShiftPunchesProps {
  groupId: number;
  updatedShifts: BulkActionReturn[];
  punchesData: PunchApiData[];
  setPunches: (punches: PunchApiData[]) => void;
  period: Period;
}

export function bulkUpdateShiftPunches({
  updatedShifts,
  ...props
}: UpdateShiftPunchesProps) {
  const { rejectedShifts = [], acceptedShifts = [] } = updatedShifts[0] || {};

  const updated = [...rejectedShifts, ...acceptedShifts];

  return Promise.all(
    updated.map((shift) => {
      const { id: updatedShiftId } = shift;
      if (!updatedShiftId) return;
      return updatePunch({ updatedShiftId, ...props });
    }),
  );
}
