/* eslint-disable valid-jsdoc */
import { del, get, post, put } from '../..';
import { stringifyUrl } from '../../../../shared/utils/route';

export const addPunch = (punch, query = {}) =>
  post(stringifyUrl('/v1/schedule/timepunches', query), punch);

export const updatePunch = (punch) =>
  put(`/v1/schedule/timepunches/${punch.id}`, punch);

export const deletePunch = (punchId, groupId, urlParams = {}) =>
  del(
    stringifyUrl(`/v1/schedule/timepunches/${punchId}/groups/${groupId}`, {
      ...urlParams,
    }),
  );

export const fetchPunchesByEmployeeAndDate = (groupId, employeeId, date) =>
  get(
    stringifyUrl(
      `/v1/schedule/timepunches/by-group/${groupId}/by-employee/${employeeId}`,
      {
        date,
      },
    ),
  );

export const getDraftTimePunch = (timePunch, metaData, groupId) =>
  put(`/v1/schedule/timepunches/groups/${groupId}/draft`, {
    metaData,
    timePunch,
  });

/**
 * @param {object} param0
 * @param {Array<number>} param0.punchIds
 * @param {number} param0.groupId
 * @param {boolean} [param0.includeEmployeeAttest]
 */
export const attestPunches = ({ punchIds, groupId, includeEmployeeAttest }) => {
  const url = stringifyUrl(
    `/v1/schedule/timepunches/by-ids/groups/${groupId}/attest`,
    { includeEmployeeAttest },
  );
  return put(url, punchIds);
};

/**
 * @param {object} param0
 * @param {Array<number>} param0.punchIds
 * @param {number} param0.groupId
 * @param {boolean} [param0.includeEmployeeAttest]
 */
export const denyPunches = ({ punchIds, groupId, includeEmployeeAttest }) => {
  const url = stringifyUrl(
    `/v1/schedule/timepunches/by-ids/groups/${groupId}/deny`,
    { includeEmployeeAttest },
  );
  return put(url, punchIds);
};

export const fetchPunchImagesById = (punchId, groupId) =>
  get(`/v1/schedule/timepunches/${punchId}/groups/${groupId}/pictures`);
