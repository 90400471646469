import { useApi } from '@shared/containers/hooks/api';
import { omit } from '@shared/utils';

import {
  createScheduleFilter,
  deleteScheduleFilterById,
  updateScheduleFilterById,
} from '@ManagerPortal/api/filters';

import { useFilters } from '../FiltersContext';

export const useFavoriteFilters = () => {
  const { savedFilters, activeFilterId, setActiveFilterId } = useFilters();

  const favoriteFilters = (
    savedFilters.data?.savedScheduleFilters ?? []
  ).filter((item) => Boolean(item?.name));

  return {
    favoriteFilters,
    activeFilterId,
    setActiveFilterId,
  };
};

const sanitizeFilter = (filter) =>
  omit(filter, ['viewMode', 'startDateTime', 'endDateTime', 'id', 'name']);

export const useSaveFavoriteFilter = () => {
  const {
    filters,
    setActiveFilterId,
    updateDisplayGroups,
    savedFilters,
    meta: { groupId, moduleName },
  } = useFilters();
  const [{ isLoading, isError, errors }, createFilter] =
    useApi(createScheduleFilter);
  const saveFavoriteFilter = async (name) => {
    const payload = sanitizeFilter(filters.data.scheduleSelectedFilter);
    payload.name = name;

    const response = await createFilter({
      groupId,
      moduleName,
      data: payload,
    });
    const id = response?.data?.id;
    await updateDisplayGroups({ id });
    setActiveFilterId(id);
    savedFilters.invalidate();

    return response;
  };

  return {
    saveFavoriteFilter,
    isLoading,
    isError,
    error: errors?.mainMessage ?? null,
  };
};

export const useUpdateFavoriteFilter = () => {
  const {
    filters,
    setActiveFilterId,
    meta: { groupId, moduleName },
  } = useFilters();
  const [{ isLoading, isError, errors }, updateFilterById] = useApi(
    updateScheduleFilterById,
  );
  const updateFavoriteFilter = (filterToUpdate) => {
    const { id: filterId, name: filterName } = filterToUpdate;
    const payload = sanitizeFilter(filters.data.scheduleSelectedFilter);
    payload.id = filterId;
    payload.name = filterName;

    return updateFilterById({
      groupId,
      moduleName,
      filterId,
      data: payload,
    }).then((res) => {
      setActiveFilterId(res?.data?.id);
      return res;
    });
  };

  return {
    updateFavoriteFilter,
    isLoading,
    isError,
    error: errors?.mainMessage ?? null,
  };
};

const removeById = (list, id) => list.filter((item) => item.id !== id);
export const useDeleteFavoriteFilter = () => {
  const {
    savedFilters,
    setActiveFilterId,
    meta: { groupId, moduleName },
  } = useFilters();
  const [{ isLoading, isError, errors }, deleteFilterById] = useApi(
    deleteScheduleFilterById,
  );

  const deleteFavoriteFilter = (filterId) => {
    savedFilters.setData({
      savedScheduleFilters: removeById(
        savedFilters.data?.savedScheduleFilters ?? [],
        filterId,
      ),
    });

    return deleteFilterById({ groupId, moduleName, filterId }).finally(() =>
      setActiveFilterId(undefined),
    );
  };

  return {
    deleteFavoriteFilter,
    isLoading,
    isError,
    error: errors?.mainMessage ?? null,
  };
};
