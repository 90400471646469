import { SORT_CRITERIA, SORT_ORDER } from '@shared/constants';

export const DISPLAY_OPTIONS_FILTERS = {
  SORT: 'sort',
  METRIC_PERIOD: 'metricCalculationPeriod',
  METRIC: 'metric',
  DISPLAY_MODE: 'displayMode',
  GROUP: 'group',
} as const;

export const VIEW_DISPLAY_OPTIONS_VALUES = {
  SHOW_WEEK_NUMBERS: 'showWeekNumbers',
} as const;

export const VIEW_DISPLAY_OPTIONS = [
  {
    name: VIEW_DISPLAY_OPTIONS_VALUES.SHOW_WEEK_NUMBERS,
    label: 'schedule.viewDisplayOptions.showWeekNumbers',
    defaultValue: true,
  } as const,
] as const;

export const SORT_VALUES = {
  START_TIME: 'start-time',
  NAME_ASC: 'employee-name-asc',
  NAME_DESC: 'employee-name-desc',
  LAST_NAME_ASC: 'employee-last-name-asc',
  LAST_NAME_DESC: 'employee-last-name-desc',
  DIFFERENCE_SCHEDULE_BY_NOMINAL_ASC: 'difference-schedule-by-nominal-asc',
  DIFFERENCE_SCHEDULE_BY_NOMINAL_DESC: 'difference-schedule-by-nominal-desc',
  ADDITIONAL_FIELD: 'seniority-additional-data',
} as const;

export const SORT_VALUES_BY_DIRECTION = {
  EMPLOYEENAME: {
    ASC: 'employee-name-asc',
    DESC: 'employee-name-desc',
  },
  EMPLOYEELASTNAME: {
    ASC: 'employee-last-name-asc',
    DESC: 'employee-last-name-desc',
  },
  NOMINALSCHEDULEDHOURS: {
    ASC: 'difference-schedule-by-nominal-asc',
    DESC: 'difference-schedule-by-nominal-desc',
  },
  STARTTIME: {
    ASC: 'start-time',
    DESC: 'start-time',
  },
  ADDITIONALFIELD: {
    ASC: 'seniority-additional-data',
    DESC: 'seniority-additional-data',
  },
} as const;

export const ACCEPTED_SORT_VALUES_BY_DIRECTION = [
  'EMPLOYEENAME',
  'EMPLOYEELASTNAME',
  'NOMINALSCHEDULEDHOURS',
];

export const SORT_DISPLAY_OPTIONS = [
  {
    value: SORT_VALUES.START_TIME,
    label: 'Start time',
    criteria: SORT_CRITERIA.START_TIME,
    order: SORT_ORDER.ASC,
  },
  {
    value: SORT_VALUES.NAME_ASC,
    label: 'label.firstName',
    criteria: SORT_CRITERIA.EMPLOYEE_NAME,
    order: SORT_ORDER.ASC,
  },
  {
    value: SORT_VALUES.NAME_DESC,
    label: 'label.firstName',
    criteria: SORT_CRITERIA.EMPLOYEE_NAME,
    order: SORT_ORDER.DESC,
  },
  {
    value: SORT_VALUES.LAST_NAME_ASC,
    label: 'label.lastName',
    criteria: SORT_CRITERIA.EMPLOYEE_LAST_NAME,
    order: SORT_ORDER.ASC,
  },
  {
    value: SORT_VALUES.LAST_NAME_DESC,
    label: 'label.lastName',
    criteria: SORT_CRITERIA.EMPLOYEE_LAST_NAME,
    order: SORT_ORDER.DESC,
  },
  {
    value: SORT_VALUES.DIFFERENCE_SCHEDULE_BY_NOMINAL_ASC,
    label: 'label.diffOfMetricDataInCalculationPeriod',
    criteria: SORT_CRITERIA.METRICS_DIFFERENCE,
    order: SORT_ORDER.ASC,
  },
  {
    value: SORT_VALUES.DIFFERENCE_SCHEDULE_BY_NOMINAL_DESC,
    label: 'label.diffOfMetricDataInCalculationPeriod',
    criteria: SORT_CRITERIA.METRICS_DIFFERENCE,
    order: SORT_ORDER.DESC,
  },
] as const;

export const ADDITIONAL_FIELD_SORT_DISPLAY_OPTION = {
  value: SORT_VALUES.ADDITIONAL_FIELD,
  label: 'label.additionalField',
  criteria: SORT_CRITERIA.ADDITIONAL_FIELD,
  order: SORT_ORDER.ASC,
} as const;

export const METRIC_PERIODS = {
  SELECTED_PERIOD: 'selected-period',
  SCHEDULE_PERIOD: 'schedule-period',
  BALANCE_PERIOD: 'balance-period',
} as const;

export const METRIC_PERIOD_DISPLAY_OPTIONS = [
  {
    value: METRIC_PERIODS.SELECTED_PERIOD,
    label: 'schedule.selected_period',
  },
  {
    value: METRIC_PERIODS.SCHEDULE_PERIOD,
    label: 'schedule.schedule_period',
  },
  {
    value: METRIC_PERIODS.BALANCE_PERIOD,
    label: 'schedule.balance_period',
  },
] as const;

export const METRIC_VARIABLES = {
  SCHEDULED_HOURS: 'scheduled-hours',
  NOMINAL_HOURS: 'nominal-hours',
  WORKED_HOURS: 'worked-hours',
  FORECAST_WORKED_HOURS: 'forecast-worked-hours',
  ROLLOUT_HOURS: 'rollout-hours',
  AVAILABLE_HOURS: 'availability-hours',
  REQUIRED_AVAILABLE_HOURS: 'required-availability-hours',
} as const;

export const METRIC_VALUES = {
  SCHEDULED_NOMINAL: 'scheduled-by-nominal',
  WORKED_NOMINAL: 'worked-by-nominal',
  EXPECTED_NOMINAL: 'expected-by-nominal',
  WORKED_ROLLEDOUT: 'worked-by-rolled-out',
  WORKED_SCHEDULED: 'worked-by-scheduled',
  AVAILABILITY_HOURS: 'available-by-min-available',
} as const;

export const METRIC_PAIRS = {
  [METRIC_VALUES.SCHEDULED_NOMINAL]: [
    METRIC_VARIABLES.SCHEDULED_HOURS,
    METRIC_VARIABLES.NOMINAL_HOURS,
  ],
  [METRIC_VALUES.WORKED_NOMINAL]: [
    METRIC_VARIABLES.WORKED_HOURS,
    METRIC_VARIABLES.NOMINAL_HOURS,
  ],
  [METRIC_VALUES.EXPECTED_NOMINAL]: [
    METRIC_VARIABLES.FORECAST_WORKED_HOURS,
    METRIC_VARIABLES.NOMINAL_HOURS,
  ],
  [METRIC_VALUES.WORKED_ROLLEDOUT]: [
    METRIC_VARIABLES.WORKED_HOURS,
    METRIC_VARIABLES.ROLLOUT_HOURS,
  ],
  [METRIC_VALUES.WORKED_SCHEDULED]: [
    METRIC_VARIABLES.WORKED_HOURS,
    METRIC_VARIABLES.SCHEDULED_HOURS,
  ],
  [METRIC_VALUES.AVAILABILITY_HOURS]: [
    METRIC_VARIABLES.AVAILABLE_HOURS,
    METRIC_VARIABLES.REQUIRED_AVAILABLE_HOURS,
  ],
} as const;

export const TRANSLATION_KEY = {
  [METRIC_VALUES.SCHEDULED_NOMINAL]:
    'schedule.metric.scheduledHoursNominalHours',
  [METRIC_VALUES.WORKED_NOMINAL]: 'schedule.metric.workedHoursNominalHours',
  [METRIC_VALUES.EXPECTED_NOMINAL]: 'schedule.metric.expectedHoursNominalHours',
  [METRIC_VALUES.WORKED_ROLLEDOUT]: 'schedule.metric.workedHoursRolledoutHours',
  [METRIC_VALUES.WORKED_SCHEDULED]: 'schedule.metric.workedHoursScheduledHours',
  [METRIC_VALUES.AVAILABILITY_HOURS]: 'schedule.metric.availabilityHours',
} as const;

export const METRIC_OPTIONS = [
  {
    value: METRIC_PAIRS[METRIC_VALUES.SCHEDULED_NOMINAL],
    label: TRANSLATION_KEY[METRIC_VALUES.SCHEDULED_NOMINAL],
  },
  {
    value: METRIC_PAIRS[METRIC_VALUES.WORKED_NOMINAL],
    label: TRANSLATION_KEY[METRIC_VALUES.WORKED_NOMINAL],
  },
  {
    value: METRIC_PAIRS[METRIC_VALUES.EXPECTED_NOMINAL],
    label: TRANSLATION_KEY[METRIC_VALUES.EXPECTED_NOMINAL],
  },
  {
    value: METRIC_PAIRS[METRIC_VALUES.WORKED_ROLLEDOUT],
    label: TRANSLATION_KEY[METRIC_VALUES.WORKED_ROLLEDOUT],
  },
  {
    value: METRIC_PAIRS[METRIC_VALUES.WORKED_SCHEDULED],
    label: TRANSLATION_KEY[METRIC_VALUES.WORKED_SCHEDULED],
  },
  {
    value: METRIC_PAIRS[METRIC_VALUES.AVAILABILITY_HOURS],
    label: TRANSLATION_KEY[METRIC_VALUES.AVAILABILITY_HOURS],
  },
] as const;

export const METRIC_DISPLAY_VALUES = {
  TOTALS: 'totals',
  BY_AGREEMENT: 'by-agreement',
} as const;

export const METRIC_DISPLAY_MODE_OPTIONS = [
  {
    value: METRIC_DISPLAY_VALUES.TOTALS,
    label: 'schedule.metricDisplayOption.totals',
  },
  {
    value: METRIC_DISPLAY_VALUES.BY_AGREEMENT,
    label: 'schedule.metricDisplayOption.byAgreement',
  },
] as const;

export enum GROUP_KEY_VALUES {
  SHIFT_TYPE = 'shift-type',
}

export const GROUP_KEY_OPTIONS = [
  {
    value: GROUP_KEY_VALUES.SHIFT_TYPE,
    label: 'label.shiftType',
  },
] as const;

export const DEFAULT_OPTIONS = {
  sort: SORT_VALUES.START_TIME,
  metric: METRIC_VALUES.SCHEDULED_NOMINAL,
  displayMode: METRIC_DISPLAY_VALUES.BY_AGREEMENT,
  metricCalculationPeriod: METRIC_PERIODS.SCHEDULE_PERIOD,
  showWeekNumbers: false,
} as const;
