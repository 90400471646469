import { ShiftType } from '@shared/types';
import { isNil, isNotNil } from '@shared/utils';

import { SharedEmployeeTimePunch } from '@ManagerPortal/api/schedule/employees/types';
import { scheduleDayComponentTypes } from '@ManagerPortal/utils/schedule/constants';

import { UNAVAILABILITY_REASONS } from '../../Main/const';
import { UnavailabilityItem } from '../../Main/types';

export const transformAwayGroupPunches = (
  punches: SharedEmployeeTimePunch[],
  unavailabilities: UnavailabilityItem[],
  shiftTypes: Record<number, ShiftType>,
) => {
  return punches.map((punch) => {
    const shiftTypeId = (() => {
      if (isNil(punch.shiftId)) return;

      return unavailabilities
        .map((unavailability) =>
          unavailability.reason === UNAVAILABILITY_REASONS.SHIFT
            ? unavailability
            : undefined,
        )
        .find((unavailability) => unavailability?.info.id === punch.shiftId)
        ?.info.shiftTypeId;
    })();

    const shiftTypeName = isNotNil(shiftTypeId)
      ? shiftTypes[shiftTypeId]?.name
      : null;

    return {
      ...punch,
      componentType: scheduleDayComponentTypes.SCHEDULE_AWAY_GROUP_PUNCH_ITEM,
      shiftTypeName,
    };
  });
};
