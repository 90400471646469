import { fetchPunchesByShiftId } from '@ManagerPortal/api/schedule/punches';
import { PunchApiData } from '@ManagerPortal/types/apiResponses/punch';

import {
  DoesPunchBelongToPeriod,
  IsOpenPunchInPeriod,
  Period,
} from '../useInvalidateShiftPunches';

interface UpdateShiftPunchesProps {
  updatedShiftId: number | string;
  groupId: number;
  punchesData: PunchApiData[];
  setPunches: (punches: PunchApiData[]) => void;
  period: Period;
}

export const updatePunch = ({
  groupId,
  updatedShiftId,
  period,
  punchesData,
  setPunches,
}: UpdateShiftPunchesProps) =>
  fetchPunchesByShiftId(groupId, updatedShiftId).then((res) => {
    const doesPunchBelongToPeriod = DoesPunchBelongToPeriod(period);
    const isOpenPunchInPeriod = IsOpenPunchInPeriod(period);
    const updatedShiftPunches = res?.body || [];
    const updatedShiftPunchesInPeriod = updatedShiftPunches.filter(
      (punch) => doesPunchBelongToPeriod(punch) || isOpenPunchInPeriod(punch),
    );

    const updatedPunchesList = [
      ...punchesData.filter(({ shiftId }) => shiftId !== updatedShiftId),
      ...updatedShiftPunchesInPeriod,
    ];

    return setPunches(updatedPunchesList);
  });
