import { stringifyUrl } from '@shared/utils/route';

import { get, put } from '../..';

export const fetchLeaveRequestsByEmployeeIds = (
  groupId,
  startDate,
  endDate,
  employeeId,
) =>
  get(
    stringifyUrl(`/v1/absences/requests/by-group/${groupId}`, {
      startDate,
      endDate,
      employeeId,
    }),
  );

export const approveLeaveRequests = (
  leaveRequestId,
  absenceScheduleId,
  shifts,
  leaveOverlappingActionType,
  groupId,
  managerComment,
  absenceReasonId,
  begin,
  end,
  ignoreValidationRules = false,
  acceptedValidationErrors,
) => {
  return put(
    stringifyUrl(
      `/v1/schedule/leaverequests/by-group/${groupId}/by-id/${leaveRequestId}/approve`,
      { leaveOverlappingActionType, ignoreValidationRules },
    ),
    {
      absenceScheduleId,
      shifts,
      absenceReasonId,
      begin,
      end,
      managerComment,
      ...acceptedValidationErrors,
    },
  );
};

export const denyLeaveRequests = (leaveRequestId, managerComment, groupId) => {
  return put(
    `/v1/schedule/leaverequests/by-group/${groupId}/by-id/${leaveRequestId}/deny-request`,
    { managerComment },
  );
};
