import { useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { indexArrayByPropName } from '@shared/utils';

import { fetchEmployeeHoursByPeriod } from '@ManagerPortal/api/schedule/employees';
import { METRIC_DISPLAY_VALUES } from '@ManagerPortal/containers/BaseSchedule/Edit/constants';
import { SCHEDULE_DATA_CACHE_TIME } from '@ManagerPortal/containers/Schedule/Data/useFetchScheduleData';
import { useGetScheduleDataHookParams } from '@ManagerPortal/containers/Schedule/Data/utils/useGetScheduleDataHookParams';
import { useDisplayOptions } from '@ManagerPortal/containers/Schedule/Main/Filters/FiltersProvider/DisplayOptionsFilters';
import { WorkerItem } from '@ManagerPortal/containers/Schedule/Main/types';

import { EmployeeKpiDictionary } from '../types';
import { handleErrorWithDefaultHours } from '../utils';

export const API_ENDPOINT = 'v1/statistics/employee-kpi/by-employees/by-group';

export const usePrefetchEmployeeHoursQuery = (
  employees: Record<number, WorkerItem>,
) => {
  const queryClient = useQueryClient();

  const {
    start,
    end,
    currentGroupId: groupId,
  } = useGetScheduleDataHookParams();

  const {
    metricPeriodValue: period,
    metricDisplayModeValue: metricDisplayMode,
    metric: variables,
  } = useDisplayOptions();

  const employeeIds = Object.keys(employees);

  useEffect(() => {
    if (employeeIds.length < 1) {
      return;
    }

    const params = {
      variables,
      period,
      groupId,
      employeeIds,
      onlyTotals: metricDisplayMode === METRIC_DISPLAY_VALUES.TOTALS,
      periodBegin: start,
      periodEnd: end,
    };

    const queryKey = [API_ENDPOINT, params];

    (async () => {
      try {
        await queryClient.prefetchQuery({
          queryKey,
          queryFn: async () => {
            const response = await fetchEmployeeHoursByPeriod(params);

            if (!response) return;

            return indexArrayByPropName(response.body, 'employeeId');
          },
          staleTime: Infinity,
          cacheTime: SCHEDULE_DATA_CACHE_TIME,
        });
      } catch (error) {
        const cache = queryClient.getQueryData<EmployeeKpiDictionary>(queryKey);

        handleErrorWithDefaultHours(
          employeeIds,
          queryClient,
          queryKey,
          error,
          cache,
        );
      }
    })();
  }, [
    employeeIds,
    end,
    groupId,
    metricDisplayMode,
    period,
    queryClient,
    start,
    variables,
  ]);
};
