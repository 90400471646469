import { useCallback, useMemo } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import { isOriginalError } from '@shared/containers/hooks/api/utils';

import { fetchAvailabilities } from '@ManagerPortal/api/schedule/availabilities';

import { SCHEDULE_DATA_CACHE_TIME } from '../useFetchScheduleData';
import { useGetScheduleDataHookParams } from '../utils/useGetScheduleDataHookParams';

export const QUERY_KEY = '/v1/schedule-availability/groups';

export const useAvailabilitiesQuery = () => {
  const { currentGroupId, start, end, isPlaceholderFilterData } =
    useGetScheduleDataHookParams();
  const queryClient = useQueryClient();

  const availabilitiesQuery = useQuery({
    queryKey: [QUERY_KEY, currentGroupId, start, end],
    queryFn: () =>
      fetchAvailabilities(currentGroupId, start, end).then((res) => res?.body),
    retry: (failureCount, error) => {
      if (isOriginalError(error) && error.status === 402) {
        return false;
      }
      return failureCount < 2;
    },
    enabled: !isPlaceholderFilterData,
    cacheTime: SCHEDULE_DATA_CACHE_TIME,
  });

  const invalidate = useCallback(
    () => queryClient.invalidateQueries([QUERY_KEY]),
    [queryClient],
  );

  return useMemo(
    () => ({
      isLoading: availabilitiesQuery.isLoading,
      isError: availabilitiesQuery.isError,
      isSuccess: availabilitiesQuery.isSuccess,
      errors: availabilitiesQuery.error,
      data: availabilitiesQuery.data,
      invalidate,
    }),
    [
      availabilitiesQuery.data,
      availabilitiesQuery.error,
      availabilitiesQuery.isError,
      availabilitiesQuery.isLoading,
      availabilitiesQuery.isSuccess,
      invalidate,
    ],
  );
};
