import request from 'superagent';

import { idbPersister } from '@ManagerPortal/containers/ManagerPortal/IDBPersister';

import {
  authCheck,
  authWithDomain,
  authWithDomainPost,
  buildUrl as buildManagerPortalUrl,
  get,
  handleError as handleManagerPortalError,
  post,
} from '../../../ManagerPortal/api';
import { stringifyUrl } from '../../utils/route';
import { b64UTF8Encoder } from './utils';

const agent = request.agent();
agent.set('x-app-version', window.version);

export const authenticated = (targetPath, context) =>
  authCheck('/authenticated', targetPath, context);

export const login = (username, password, domainGroupId, targetPath, context) =>
  authWithDomain(
    '/login',
    username,
    password,
    domainGroupId,
    targetPath,
    context,
  );

export const loginMFA = (
  username,
  password,
  domainGroupId,
  targetUri,
  context,
  phoneNumber,
) =>
  authWithDomainPost(
    '/v1/mfaprovider/login',
    username,
    password,
    domainGroupId,
    targetUri,
    context,
    phoneNumber,
  );

export const logout = () => {
  // TODO Marcus Hultkrantz: Remove once backend sends encryption key
  idbPersister.removeClient();
  return get('/logout');
};

export const updatePassword = (data) =>
  post('/v1/security/updatepassword', data);

export const fetchRegion = (qshard) =>
  get(stringifyUrl(`/v1/loginproviders/region`, { qshard }));

export const ssoLogoutApi = () => {
  // TODO Marcus Hultkrantz: Remove once backend sends encryption key
  idbPersister.removeClient();
  return get(`/v1/security/ssosession/logout`);
};

/* export const fetchLoginProviders = (
  emailOrProviderId,
  targetPath,
  loginType,
  context,
) => {
  return request
    .post(buildManagerPortalUrl('/v1/loginproviders/loginproviders/by_user'))
    .set('userroute', b64UTF8Encoder(emailOrProviderId))
    .send({ [loginType]: emailOrProviderId, targetPath, context })
    .query({ context })
    .withCredentials()
    .catch(handleManagerPortalError);
}; */

export const validateAuthCode = (code) =>
  agent
    .post(buildManagerPortalUrl('/v1/mfaprovider/otp/validate'))
    .send({ code })
    .withCredentials()
    .catch(handleManagerPortalError);

export const resendAuthCode = () =>
  agent
    .post(buildManagerPortalUrl('/v1/mfaprovider/otp/resend'))
    .withCredentials()
    .catch(handleManagerPortalError);

// /external/loginproviders/by-user/target-url

export const fetchLoginTenants = (
  emailOrProviderId,
  targetPath,
  loginType,
  companyAlias,
  context,
) => {
  return agent
    .post(
      buildManagerPortalUrl(
        '/v1/loginproviders/loginproviders/by-user/target-url',
      ),
    )
    .set('userroute', b64UTF8Encoder(emailOrProviderId))
    .send({
      [loginType]: emailOrProviderId,
      targetPath,
      companyAlias,
      context,
    })
    .query({ context })
    .withCredentials()
    .catch(handleManagerPortalError);
};

// /external/loginproviders/domaingroups

export const fetchDomains = (token) =>
  get(
    stringifyUrl(`/v1/loginproviders/loginproviders/domaingroups`, { token }),
  );

// /external/loginproviders/domain-group-id/{domainGroupId}/token/{token}

export const fetchLoginProviders = (domainGroupId, token) =>
  get(
    `/v1/loginproviders/loginproviders/domain-group-id/${domainGroupId}/token/${token}`,
  );
