import { formatToISO } from '@shared/utils/time';

import { useCurrentGroup } from '@ManagerPortal/containers/hooks/account';
import { useScheduleDates } from '@ManagerPortal/containers/Schedule/Main/Filters/FiltersProvider/ViewFilters';
import {
  getTimeperiodString,
  getTimeperiodWithDaybreak,
} from '@ManagerPortal/utils/timeperiod';

export const useGetScheduleDataHookParams = () => {
  const { id: currentGroupId, dayBreak } = useCurrentGroup();
  const {
    startDateTime: initStart,
    endDateTime: initEnd,
    isPlaceholderData,
  } = useScheduleDates();
  const timeperiod = getTimeperiodString(initStart, initEnd);
  const { start: calculatedStart, end: calculatedEnd } =
    getTimeperiodWithDaybreak(timeperiod, dayBreak);
  const start = formatToISO(calculatedStart);
  const end = formatToISO(calculatedEnd);
  return {
    start,
    end,
    currentGroupId,
    isPlaceholderFilterData: isPlaceholderData,
  };
};
