import { MODULE_NAMES } from '@shared/constants';

import { del, get, put } from '..';

export const deleteScheduleFilterById = ({ groupId, moduleName, filterId }) => {
  const url = `/v1/schedule-filters/schedule-view-filters/groups/${groupId}/module/${moduleName}/${filterId}`;
  return del(url);
};

export const getDisplayGroupsFiltersByFilterId = (
  groupId,
  moduleName,
  filterId,
) => {
  const url = `/v1/schedule-filters/selected-statistics-variables/with-display-groups/groups/${groupId}/module/${moduleName}/${filterId}`;
  return get(url);
};

export const updateDisplayGroupsFiltersByFilterId = ({
  groupId,
  moduleName,
  filterId,
  data,
}) => {
  const url = `/v1/schedule-filters/selected-statistics-variables/with-display-groups/groups/${groupId}/module/${moduleName}/${
    moduleName === MODULE_NAMES.BASE_SCHEDULE ? '' : filterId
  }`;
  return put(url, data);
};
