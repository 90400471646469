import { useMemo } from 'react';

import { useReadyForPayrollStatusQuery } from '../../Main/hooks/useReadyForPayrollStatusQuery';
import { useAbsencesQuery } from '../useAbsencesQuery';
import { useAvailabilitiesQuery } from '../useAvailabilitiesQuery';
import { useEmployeesQuery } from '../useEmployeesQuery';
import { useLeaveRequestsQuery } from '../useLeaveRequestsQuery';
import { usePunchesQuery } from '../usePunchesQuery';
import { useShiftsQuery } from '../useShiftsQuery';
import { useShiftTypesQuery } from '../useShiftTypesQuery';
import { useUnavailabilitiesQuery } from '../useUnavailabilitiesQuery';

export const SCHEDULE_DATA_CACHE_TIME = 1000 * 60 * 60 * 24; // 24 hours

export const useFetchScheduleData = () => {
  const employeesQuery = useEmployeesQuery();
  const unavailabilitiesQuery = useUnavailabilitiesQuery();
  const leaveRequestsQuery = useLeaveRequestsQuery();
  const absencesQuery = useAbsencesQuery();
  const punchesQuery = usePunchesQuery();
  const shiftsQuery = useShiftsQuery();
  const availabilitiesQuery = useAvailabilitiesQuery();
  const shiftTypesQuery = useShiftTypesQuery();
  const readyForPayrollStatusQuery = useReadyForPayrollStatusQuery();

  // TODO Marcus Hultkrantz: More calls needs to be in here and moved to react
  // query for even more efficient caching and less rerendering
  //
  // https://web-test.quinyx.com/extapi/v1/bank-holidays/with-special-days/by-group/
  // https://web-test.quinyx.com/extapi/v1/employee/additional-fields/by-group/170305
  // https://web-test.quinyx.com/extapi/v1/employee/agreement-templates/by-group/170305/for-workers*
  // https://web-test.quinyx.com/extapi/v1/schedule-filters/schedule-view-filters/groups/170305/module/SCHEDULE/*
  // https://web-test.quinyx.com/extapi/v1/employee/groups/170305/workersof/by-ids

  return useMemo(
    () => ({
      employeesQuery,
      unavailabilitiesQuery,
      leaveRequestsQuery,
      absencesQuery,
      punchesQuery,
      shiftsQuery,
      availabilitiesQuery,
      shiftTypesQuery,
      readyForPayrollStatusQuery,
    }),
    [
      absencesQuery,
      availabilitiesQuery,
      unavailabilitiesQuery,
      employeesQuery,
      leaveRequestsQuery,
      punchesQuery,
      readyForPayrollStatusQuery,
      shiftTypesQuery,
      shiftsQuery,
    ],
  );
};
