import { del, get, patch, post, put } from '..';
import { stringifyUrl } from '../../../shared/utils/route';

const doOnce = (func) => {
  let result = null;

  return () => {
    if (result !== null) {
      return result;
    }

    result = func();
    return result;
  };
};

export const fetchFetchPdfTemplates = (groupId) =>
  get(`/v1/employee/forms/by-group/${groupId}/by-type/2`);

export const fetchAccounts = doOnce(() =>
  get('/v1/organisation/groups/customers'),
);

export const fetchAccountSettings = (groupId) =>
  get(`/v1/settings/account/${groupId}`);

export const fetchAppearance = (groupId) =>
  get(`/v1/settings/account/${groupId}/appearance-settings`);

export const updateAppearance = (groupId, appearance) =>
  put(`/v1/settings/account/${groupId}/appearance-settings`, appearance);

export const addAgreementTemplate = (template) =>
  post('/v1/employee/agreement-templates', template);

export const copyAgreementTemplate = (id, template) =>
  post(`/v1/employee/agreement-templates/${id}/copy`, template);

export const updateAgreementTemplate = (id, template) =>
  put(`/v1/employee/agreement-templates/${id}`, template);

export const deleteAgreementTemplate = (id) =>
  del(`/v1/employee/agreement-templates/${id}`);

export const fetchStaffCategoryList = (groupId) =>
  get(`/v1/staff-category/by-group/${groupId}`);

export const fetchStaffCategory = (groupId, id) =>
  get(`/v1/staff-category/by-group/${groupId}/category/${id}`);

export const updateStaffCategory = (groupId, id, staffCategory) =>
  put(`/v1/staff-category/by-group/${groupId}/category/${id}`, staffCategory);

export const addStaffCategory = (groupId, name) =>
  post(`/v1/staff-category/by-group/${groupId}`, { name });

export const deleteStaffCategory = (groupId, id) =>
  del(`/v1/staff-category/by-group/${groupId}/category/${id}`);

export const fetchAgrementSalaryType = (groupId, salaryTypeId, templateId) =>
  get(
    `/v1/salaries/salarytypes/by-group/${groupId}/by-id/${salaryTypeId}/by-agreement-template/${templateId}`,
  );

export const fetchUnitGroups = (groupId) =>
  get(`/v1/organisation/unit-groups/by-customer-group-id/${groupId}`);

export const fetchAbsenceReasonTypes = (groupId) =>
  get(`/v1/absence-types-settings/reason-types/by-group-id/${groupId}`);

export const fetchTimeAndWarningConfigurations = (groupId) =>
  get(`/v1/settings/timeparams/by-group/${groupId}`);

export const updateTimeAndWarningConfigurations = (id, settingsParams) =>
  patch(`/v1/settings/timeparams/${id}`, settingsParams);

export const fetchTimeTrackersFullData = (groupId) =>
  get(`/v1/timetracking/timetrackers/by-group/${groupId}/full`);

export const fetchSalaryTypesFullItem = (salaryTypeId) =>
  get(`/v1/salaries/salarytypes/${salaryTypeId}`);

export const createSalaryTypeApi = (groupId, item) =>
  post(`/v1/salaries/salarytypes/by-group/${groupId}`, item);

export const updateSalaryTypeApi = (salaryTypeId, item) =>
  put(`/v1/salaries/salarytypes/${salaryTypeId}`, item);

export const fetchAbsenceReasonTypesOnAccount = (groupId) =>
  get(`/v1/absence-types-settings/reason-types/by-group-id/${groupId}`);

export const createAbsenceReasonTypeApi = (groupId, params) =>
  post(
    `/v1/absence-types-settings/reason-types/by-group-id/${groupId}`,
    params,
  );

export const updateAbsenceReasonTypeApi = (absenceReasonId, params) =>
  put(`/v1/absence-types-settings/reason-types/${absenceReasonId}`, params);

export const deleteAbsenceReasonTypeApi = (absenceReasonId) =>
  del(`/v1/absence-types-settings/reason-types/${absenceReasonId}`);

export const fetchAbsenceTypes = (groupId) =>
  get(`/v1/absence-types-settings/absence-types/by-group-id/${groupId}`);

export const fetchAbsenceTypeById = (absenceTypeId) =>
  get(
    `/v1/absence-types-settings/absence-types/${absenceTypeId}?expand=SALARY_TYPES`,
  );

export const createAbsenceTypeApi = (groupId, params) =>
  post(
    `/v1/absence-types-settings/absence-types/by-group-id/${groupId}`,
    params,
  );

export const updateAbsenceTypeApi = (absenceTypeId, params) =>
  put(`/v1/absence-types-settings/absence-types/${absenceTypeId}`, params);

export const deleteAbsenceTypeApi = (absenceTypeId) =>
  del(`/v1/absence-types-settings/absence-types/${absenceTypeId}`);

export const createAbsenceTypeSalaryType = (absenceTypeId, params) =>
  post(
    `/v1/absence-types-settings/absence-types/${absenceTypeId}/salary-types`,
    params,
  );

export const updateAbsenceTypeSalaryType = (
  absenceTypeId,
  salaryTypeId,
  params,
) =>
  put(
    `/v1/absence-types-settings/absence-types/${absenceTypeId}/salary-types/${salaryTypeId}`,
    params,
  );

export const deleteAbsenceTypeSalaryType = (absenceTypeId, salaryTypeId) =>
  del(
    `/v1/absence-types-settings/absence-types/${absenceTypeId}/salary-types/${salaryTypeId}`,
  );

export const fetchOverTimeMethods = (groupId, name) => {
  return get(
    stringifyUrl(`/v1/overtime-methods/by-group-id/${groupId}`, {
      name,
    }),
  );
};

export const createOverTimeMethodApi = (groupId, params) =>
  post(`/v1/overtime-methods/by-group-id/${groupId}`, params);

export const createMinusTimeMethodApi = (groupId, params) =>
  post(`/v1/overtime-methods/by-group-id/${groupId}`, params);

export const updateOverTimeMethodApi = (overtimeId, params) =>
  put(`/v1/overtime-methods/${overtimeId}`, params);

export const deleteOverTimeMethodApi = (overtimeId) =>
  del(`/v1/overtime-methods/${overtimeId}`);

export const fetchAbsenceScheduleApi = (groupId) =>
  get(`/v1/absence-schedule/by-group-id/${groupId}`);

export const deleteAbsenceSchedule = (absenceScheduleId) =>
  del(`/v1/absence-schedule/${absenceScheduleId}`);

export const fetchDayCountGroupApi = (groupId) =>
  get(`/v1/absence-types-settings/day-count-groups/by-group-id/${groupId}`);

export const createAbsenceScheduleApi = (groupId, params) =>
  post(`/v1/absence-schedule/by-group/${groupId}`, params);

export const updateAbsenceScheduleApi = (absenceScheduleId, params) =>
  put(`/v1/absence-schedule/by-id/${absenceScheduleId}`, params);

export const fetchAbsenceScheduleById = (absenceScheduleId) =>
  get(`/v1/absence-schedule/by-id/${absenceScheduleId}`);

export const absenceScheduleOverwriteShifts = (absenceScheduleId, params) =>
  post(`/v1/absence-schedule/${absenceScheduleId}/shifts/overwrite`, params);

export const fetchShiftTypesOnAccountApi = (groupId, params = {}) =>
  get(
    stringifyUrl(`/v1/shifttypes/types/group/${groupId}`, params, {
      encode: true,
    }),
  );

export const fetchBankHolidaysByGroupIdApi = (groupId) =>
  get(`/v1/bank-holidays/by-group-id/${groupId}`);

export const deleteBankHoliday = (bankHolidayId) =>
  del(`/v1/bank-holidays/${bankHolidayId}`);

export const copyBankHoliday = (bankHolidayId, params) =>
  post(`/v1/bank-holidays/${bankHolidayId}/clone`, params);

export const shareBankHolidays = (configuredHolidayId, params) =>
  post(`/v1/bank-holidays/${configuredHolidayId}/share-setup`, params);

export const fetchUnsocialTime = (groupId) =>
  get(`/v1/overtime/unsocial-time/group-id/${groupId}`);

export const updateUnsocialTimeApi = (groupId, params) =>
  post(`/v1/overtime/unsocial-time/group-id/${groupId}/overwrite`, params);

export const deleteUnitGroup = (unitGroupId) =>
  del(`/v1/organisation/unit-groups/${unitGroupId}`);

export const createUnitGroup = (groupId, params) =>
  post(`/v1/organisation/unit-groups/by-group/${groupId}`, params);

export const updateUnitGroup = (params) =>
  put(`/v1/organisation/unit-groups`, params);

export const fetchOvertimePeriods = (groupId) =>
  get(`/v1/overtime/overtime-periods/group-id/${groupId}`);

export const updateOvertimePeriods = (groupId, params) =>
  post(`/v1/overtime/overtime-periods/group-id/${groupId}/overwrite`, params);

export const fetchPdfFormsApi = (groupId, typeId) =>
  get(`/v1/employee/forms/by-group/${groupId}/by-type/${typeId}`);

export const uploadPdfFormApi = (groupId, formData) =>
  put(`/v1/employee/forms/pdf-forms/by-group/${groupId}`, formData);

export const updatePdfFormApi = (pdfId, params) =>
  put(`/v1/employee/forms/pdf-forms/${pdfId}`, params);

export const deletePdfFormApi = (pdfFormId) =>
  del(`/v1/employee/forms/pdf-forms/${pdfFormId}`);

export const fetchPdfFormApi = (pdfFormId) =>
  get(`/v1/employee/forms/pdf-forms/${pdfFormId}/download`);

export const createAdditionalField = (groupId, formData) =>
  post(`/v1/employee/additional-fields/by-group/${groupId}`, formData);

export const updateAdditionalField = (additionalFieldId, formData) =>
  put(`/v1/employee/additional-fields/${additionalFieldId}`, formData);

export const updateAdditionalFieldSorting = (payload) =>
  put(`/v1/employee/additional-fields/order`, payload);

export const deleteAdditionalField = (additionalFieldId) =>
  del(`/v1/employee/additional-fields/${additionalFieldId}`);

export const fetchOpenIdProviders = (domainGroupId) =>
  get(`/v1/openidsso/clientregistration/groups/${domainGroupId}`);

export const fetchOpenIdPreset = (url) =>
  post('/v1/openidsso/metadata/parse-well-known', {
    url,
  });
export const createOpenIdProvider = (domainGroupId, payload) =>
  post(
    `/v1/openidsso/clientregistration/groups/${domainGroupId}/create`,
    payload,
  );
export const updateOpenIdProvider = (domainGroupId, id, payload) =>
  put(
    `/v1/openidsso/clientregistration/groups/${domainGroupId}/registrationId/${id}/update`,
    payload,
  );

export const deleteOpenIdProvider = (domainGroupId, id) =>
  del(
    `/v1/openidsso/clientregistration/groups/${domainGroupId}/registrationId/${id}`,
  );

export const deleteSamlProviderEndpoint = (id) =>
  del(`/v1/samlprovider/by-registration-id/${id}`);

export const createSamlProviderEndpoint = (payload) =>
  post(`/v1/samlprovider/register`, payload);

export const updateSamlProviderEndpoint = (payload) =>
  put(`/v1/samlprovider/update`, payload);

export const fetchSamlProvidersEndpoint = (domainGroupId) =>
  get(`/v1/samlprovider/by-domain-id/${domainGroupId}`);

export const fetchSamlProviderEndpoint = (id) =>
  get(`/v1/samlprovider/by-registration-id/${id}`);

export const fetchIsUsingMealBreaks = (domainGroupId) =>
  get(`/v1/meal-break/domain-group/${domainGroupId}/meal-break-setting`);

export const updateMealBreaksSettingApi = (domainGroupId, payload) =>
  put(
    `/v1/meal-break/domain-group/${domainGroupId}/meal-break-setting`,
    payload,
  );

export const updateWaivingTypeSettingApi = (domainGroupId, payload) =>
  put(
    `/v1/meal-break/domain-group/${domainGroupId}/waiving-type-setting`,
    payload,
  );

export const fetchMealBreaksEnabledForAllUnits = (domainGroupId) =>
  get(`/v1/meal-break/domain-group/${domainGroupId}/all-units-setting`);

export const updateMealBreaksEnabledForAllUnits = (domainGroupId, payload) =>
  put(
    `/v1/meal-break/domain-group/${domainGroupId}/all-units-setting`,
    payload,
  );

export const fetchMealBreaksEnabledUnits = (domainGroupId) =>
  get(`/v1/meal-break/domain-group/${domainGroupId}/units-setting`);

export const updateMealBreaksEnabledForUnits = (domainGroupId, payload) =>
  post(`/v1/meal-break/domain-group/${domainGroupId}/units-setting`, payload);

export const fetchMealWaiverTexts = (domainGroupId) =>
  get(`/v1/meal-break/waiving-rules/domain-group/${domainGroupId}/texts`);

export const updateMealWaiverTextsApi = (domainGroupId, payload) =>
  put(
    `/v1/meal-break/waiving-rules/domain-group/${domainGroupId}/texts`,
    payload,
  );

export const fetchMealBreakRules = (domainGroupId) =>
  get(`/v1/meal-break/domain-group/${domainGroupId}/all-rules`);

export const createMealBreakPunchInRule = (domainGroupId, payload) =>
  post(`/v1/meal-break/punch-rules/domain-group/${domainGroupId}`, payload);

export const updateMealBreakPunchInRule = (domainGroupId, payload) =>
  put(`/v1/meal-break/punch-rules/domain-group/${domainGroupId}`, payload);

export const deletePunchInRuleAPI = (domainGroupId, ruleId) =>
  del(
    `/v1/meal-break/punch-rules/domain-group/${domainGroupId}/rule-id/${ruleId}`,
  );

export const createMealBreakCompensationRule = (domainGroupId, payload) =>
  post(`/v1/meal-break/waiving-rules/domain-group/${domainGroupId}`, payload);

export const deleteWaiverRuleAPI = (domainGroupId, ruleId) =>
  del(
    `/v1/meal-break/waiving-rules/domain-group/${domainGroupId}/rule-id/${ruleId}`,
  );

export const updateMealBreakCompensationRule = (domainGroupId, payload) =>
  put(`/v1/meal-break/waiving-rules/domain-group/${domainGroupId}`, payload);

export const fetchPunchInWarningText = (domainGroupId) =>
  get(`/v1/meal-break/punch-rules/domain-group/${domainGroupId}/warning-text`);

export const updatePunchInWarningText = (domainGroupId, payload) =>
  put(
    `/v1/meal-break/punch-rules/domain-group/${domainGroupId}/warning-text`,
    payload,
  );
