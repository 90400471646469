import { useCallback, useEffect, useMemo } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import { errorMessageNot403 } from '@shared/api/utils';
import { notify } from '@shared/components/Notifications/utils';
import { isOriginalError } from '@shared/containers/hooks/api/utils';
import { indexArrayByPropName } from '@shared/utils';

import { fetchAllPunches } from '@ManagerPortal/api/schedule/punches';
import { PunchApiData } from '@ManagerPortal/types/apiResponses/punch';

import { useFilterFeatures } from '../../Main/Filters/FiltersProvider';
import { SCHEDULE_DATA_CACHE_TIME } from '../useFetchScheduleData';
import { useGetScheduleDataHookParams } from '../utils/useGetScheduleDataHookParams';
import { useInvalidateForEmployees } from './useInvalidateForEmployees';
import { useInvalidateShiftPunches } from './useInvalidateShiftPunches';
import { useUpdateShiftPunches } from './useUpdateShiftPunches';
import { useUpdateState } from './useUpdateState';

export const PUNCHES_QUERY_KEY = '/v2/schedule/timepunches/by-group';

export const usePunchesQuery = () => {
  const { currentGroupId, start, end, isPlaceholderFilterData } =
    useGetScheduleDataHookParams();
  const features = useFilterFeatures();
  const enabled = !isPlaceholderFilterData && features.includes('punches');

  const punchQueryClient = useQueryClient();

  const punchesQuery = useQuery({
    queryKey: [PUNCHES_QUERY_KEY, currentGroupId, start, end],
    queryFn: () =>
      fetchAllPunches(currentGroupId, start, end).then((res) => res?.body),
    retry: (failureCount, error) => {
      if (isOriginalError(error) && error.status === 403) {
        return false;
      }
      return failureCount < 2;
    },
    enabled,
    cacheTime: SCHEDULE_DATA_CACHE_TIME,
  });

  const invalidatePunches = useCallback(
    () =>
      punchQueryClient.invalidateQueries({
        queryKey: [PUNCHES_QUERY_KEY],
      }),
    [punchQueryClient],
  );
  const setPunches = useCallback(
    (newData: PunchApiData[]) => {
      punchQueryClient.setQueryData(
        [PUNCHES_QUERY_KEY, currentGroupId, start, end],
        newData,
      );
    },
    [end, currentGroupId, punchQueryClient, start],
  );

  const commonProps = {
    groupId: currentGroupId,
    startDate: start,
    endDate: end,
    punchesData: punchesQuery.data || [],
    setPunches,
  };
  const invalidateForEmployees = useInvalidateForEmployees(commonProps);
  const updateShiftPunches = useUpdateShiftPunches(commonProps);
  const invalidateShiftPunches = useInvalidateShiftPunches({
    ...commonProps,
    invalidatePunches,
  });
  const updateState = useUpdateState({
    punchesData: punchesQuery.data,
    setPunches,
  });

  useEffect(() => {
    const errorMessage = errorMessageNot403(punchesQuery.error);
    if (errorMessage) {
      notify(`Shifts: ${errorMessage}`, 'error');
    }
  }, [punchesQuery?.error]);

  const indexedPunches = useMemo(
    () => (punchesQuery.data ? indexArrayByPropName(punchesQuery.data) : {}),
    [punchesQuery.data],
  );

  return useMemo(
    () => ({
      isLoading: punchesQuery.isLoading,
      isError: punchesQuery.isError,
      isSuccess: punchesQuery.isSuccess,
      errors: punchesQuery.error,
      setData: setPunches,
      invalidate: invalidatePunches,
      data: indexedPunches,
      invalidateForEmployees,
      updateState,
      updateShiftPunches,
      invalidateShiftPunches,
    }),
    [
      punchesQuery.isLoading,
      punchesQuery.isError,
      punchesQuery.isSuccess,
      punchesQuery.error,
      setPunches,
      invalidatePunches,
      indexedPunches,
      invalidateForEmployees,
      updateState,
      updateShiftPunches,
      invalidateShiftPunches,
    ],
  );
};
