import { stringifyUrl } from '@shared/utils/route';
import { TIsoFormat } from '@shared/utils/time/types';

import { get } from '@ManagerPortal/api';
import { AbsenceItem } from '@ManagerPortal/containers/Schedule/Main/types';

export * from './index-old';

export const fetchAbsences = (
  groupId: number,
  start: TIsoFormat,
  end: TIsoFormat,
  employeeId: number | null = null,
) =>
  get<AbsenceItem[]>(
    stringifyUrl(`/v1/absences/by-group/${groupId}`, {
      start,
      end,
      employeeId,
    }),
  );
