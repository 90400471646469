import { TTimeWithSecondsFormat } from '@shared/utils/time/types';

import { TUnavailability } from '@ManagerPortal/containers/Schedule/Main/types';

import { get } from '../..';

export const fetchUnavailabilityList = (
  from: TTimeWithSecondsFormat,
  to: TTimeWithSecondsFormat,
  groupId: number,
) =>
  get<Record<number, TUnavailability[]>>(
    `/v2/employee/unavailability/groups/${groupId}?startDate=${from}&endDate=${to}`,
  );
