import { useState } from 'react';

import { createProviderHookPair } from '@shared/utils/createProviderHookPair';
import { formatToDate } from '@shared/utils/time';
import { TDateFormat } from '@shared/utils/time/types';
import { getTypedObjectEntries } from '@shared/utils/typeUtils';

import { scheduleOptimisticUpdates } from '@ManagerPortal/featureToggles';

import { AnyScheduleItemType, ScheduleRow, ShiftItem } from '../Main/types';
import {
  ShiftItemWithOptimisticId,
  TOptimisticUpdateId,
  TOptimisticUpdates,
} from './types';

const isShiftItem = (shift: AnyScheduleItemType): shift is ShiftItem => {
  return shift.componentType === 'SCHEDULE_SHIFT_ITEM';
};

export const useLogic = () => {
  const [optimisticUpdates, setOptimisticUpdates] =
    useState<TOptimisticUpdates>({});

  const pushOptimisticUpdate = (
    fromDate: null | { date: string; employeeId: number | null },
    toDate: { date: string; employeeId: number | null },
    data: ShiftItem,
  ): TOptimisticUpdateId | null => {
    if (!scheduleOptimisticUpdates) {
      return null;
    }

    const optimisticUpdateId: TOptimisticUpdateId = crypto.randomUUID();

    const dataWithId = {
      ...data,
      optimisticUpdateId,
    };

    if (fromDate) {
      if (optimisticUpdates[fromDate.employeeId || 'UNASSIGNED']) {
        optimisticUpdates[fromDate.employeeId || 'UNASSIGNED']?.push({
          type: 'from',
          date: fromDate.date,
          employeeId: fromDate.employeeId,
          data: dataWithId,
        });
      } else {
        optimisticUpdates[fromDate.employeeId || 'UNASSIGNED'] = [
          {
            type: 'from',
            date: fromDate.date,
            employeeId: fromDate.employeeId,
            data: dataWithId,
          },
        ];
      }
    }

    if (optimisticUpdates[toDate.employeeId || 'UNASSIGNED']) {
      optimisticUpdates[toDate.employeeId || 'UNASSIGNED']?.push({
        type: 'to',
        date: toDate.date,
        employeeId: toDate.employeeId,
        data: dataWithId,
      });
    } else {
      optimisticUpdates[toDate.employeeId || 'UNASSIGNED'] = [
        {
          type: 'to',
          date: toDate.date,
          employeeId: toDate.employeeId,
          data: dataWithId,
        },
      ];
    }

    setOptimisticUpdates({
      ...optimisticUpdates,
    });

    return optimisticUpdateId;
  };

  const removeOptimisticUpdates = (shifts: ShiftItemWithOptimisticId[]) => {
    if (!scheduleOptimisticUpdates) {
      return;
    }

    let didChangeOptimisticUpdates = false;
    const allOngoingUpdatesArr = Object.values(optimisticUpdates).flatMap(
      (updates) => updates,
    );
    shifts.forEach((shift) => {
      const theseUpdates = allOngoingUpdatesArr.filter(
        (update) => update.data.optimisticUpdateId === shift.optimisticUpdateId,
      );

      theseUpdates.forEach((thisUpdate) => {
        const updatesForEmployeeInOriginalArray =
          optimisticUpdates[thisUpdate?.employeeId || 'UNASSIGNED'];
        if (thisUpdate && updatesForEmployeeInOriginalArray) {
          didChangeOptimisticUpdates = true;

          optimisticUpdates[thisUpdate.employeeId || 'UNASSIGNED'] =
            updatesForEmployeeInOriginalArray.filter((update) => {
              return (
                update.data.optimisticUpdateId !==
                thisUpdate.data.optimisticUpdateId
              );
            });

          if (
            optimisticUpdates[thisUpdate.employeeId || 'UNASSIGNED']?.length ===
            0
          ) {
            delete optimisticUpdates[thisUpdate.employeeId || 'UNASSIGNED'];
          }
        }
      });
    });

    if (didChangeOptimisticUpdates) {
      setOptimisticUpdates({ ...optimisticUpdates });
    }
  };

  const checkCompletedUpdates = (shifts: ShiftItem[]) => {
    if (!scheduleOptimisticUpdates) {
      return;
    }

    let didChangeOptimisticUpdates = false;
    const allOngoingUpdatesArr = Object.values(optimisticUpdates).flatMap(
      (updates) => updates,
    );
    shifts.forEach((shift) => {
      // if we find this then it was updated, so it can be removed from the array
      const thisUpdateTo = (() => {
        return allOngoingUpdatesArr.find((update) => {
          return (
            update.type === 'to' &&
            shift.optimisticUpdateId !== null &&
            update.data.optimisticUpdateId === shift.optimisticUpdateId &&
            update.date === formatToDate(shift.begin)
          );
        });
      })();

      if (thisUpdateTo) {
        didChangeOptimisticUpdates = true;
        // now find the from to remove
        const thisUpdateFrom = allOngoingUpdatesArr.find(
          (update) =>
            update.type === 'from' &&
            update.data.optimisticUpdateId === shift.optimisticUpdateId,
        );

        if (thisUpdateFrom) {
          const updatesForEmployeeInOriginalArray =
            optimisticUpdates[thisUpdateFrom?.employeeId || 'UNASSIGNED'];
          if (thisUpdateFrom && updatesForEmployeeInOriginalArray) {
            optimisticUpdates[thisUpdateFrom.employeeId || 'UNASSIGNED'] =
              updatesForEmployeeInOriginalArray.filter((update) => {
                return (
                  update.data.optimisticUpdateId !==
                  thisUpdateFrom.data.optimisticUpdateId
                );
              });
          }
        }

        const updatesForEmployeeInOriginalArray =
          optimisticUpdates[thisUpdateTo?.employeeId || 'UNASSIGNED'];
        if (thisUpdateTo && updatesForEmployeeInOriginalArray) {
          optimisticUpdates[thisUpdateTo.employeeId || 'UNASSIGNED'] =
            updatesForEmployeeInOriginalArray.filter((update) => {
              return (
                update.data.optimisticUpdateId !==
                thisUpdateTo.data.optimisticUpdateId
              );
            });
        }
      }
    });

    if (didChangeOptimisticUpdates) {
      setOptimisticUpdates({ ...optimisticUpdates });
    }
  };

  const getScheduleDataWithOptimisticUpdates = (
    scheduleData: ScheduleRow[],
  ): ScheduleRow[] => {
    if (!scheduleOptimisticUpdates) {
      return scheduleData;
    }

    if (Object.keys(optimisticUpdates).length === 0) {
      return scheduleData;
    }

    return scheduleData.map((row: ScheduleRow) => {
      const employeeIdIndex = row.employee.id ?? 'UNASSIGNED';

      const daysTypedEntries = getTypedObjectEntries(row.days);

      const updatesForEmployee = optimisticUpdates[employeeIdIndex];

      if (!updatesForEmployee || updatesForEmployee.length === 0) {
        return row;
      }

      const daysData: Record<TDateFormat, AnyScheduleItemType[]> = {};

      daysTypedEntries.forEach(([dateKey, dayComponents]) => {
        const optimisticUpdatesOnThisDate = updatesForEmployee.filter((val) => {
          return val.date === dateKey;
        });

        // remove the shifts that are currently being moved from today
        const dayComponentsNew: AnyScheduleItemType[] = dayComponents.filter(
          (component) => {
            return !optimisticUpdatesOnThisDate.find((update) => {
              const shift = isShiftItem(component);

              if (shift) {
                // we need to check for the id because when you do a move
                // then we need the 'from' shift to disappear without amending
                // the shift data (it only gets an optimistic update id once
                // the save action is fully finished)
                return (
                  update.data.optimisticUpdateId ===
                    component.optimisticUpdateId ||
                  update.data.id === component.id
                );
              }

              return false;
            });
          },
        );

        // add the shifts are that being moved TO today
        const shiftsBeingMovedToThisDate = optimisticUpdatesOnThisDate
          .filter((optimisticUpdate) => {
            if (optimisticUpdate.type === 'to') {
              return true;
            }

            return false;
          })
          .map((optimisticUpdate) => {
            return { ...optimisticUpdate.data, optimisticUpdate: true };
          });

        shiftsBeingMovedToThisDate.forEach((obj) => {
          dayComponentsNew.push(obj);
        });

        daysData[dateKey] = dayComponentsNew;
      });

      return {
        ...row,
        days: daysData,
      };
    });
  };

  return {
    optimisticUpdates,
    setOptimisticUpdates,
    pushOptimisticUpdate,
    checkCompletedUpdates,
    removeOptimisticUpdates,
    getScheduleDataWithOptimisticUpdates,
  };
};

export const [ScheduleOptimisticUpdatesProvider, useScheduleOptimisticUpdates] =
  createProviderHookPair(useLogic);
