import { stringifyUrl } from '@shared/utils/route';
import { TIsoFormat } from '@shared/utils/time/types';

import { get, post } from '@ManagerPortal/api';
import { PunchApiData } from '@ManagerPortal/types/apiResponses/punch';

export * from './index-old';

export const fetchAllPunches = (
  groupId: number,
  startDate: TIsoFormat,
  endDate: TIsoFormat,
) =>
  get<PunchApiData[]>(
    stringifyUrl(`/v2/schedule/timepunches/by-group/${groupId}`, {
      startDate,
      endDate,
    }),
  );

export const fetchPunchesByEmployeeIds = (
  groupId: number,
  startDate: TIsoFormat,
  endDate: TIsoFormat,
  employeeIds: number[],
) =>
  post<PunchApiData[]>(
    stringifyUrl(
      `/v1/schedule/timepunches/by-group/${groupId}/by-employee-ids`,
      { start: startDate, end: endDate },
    ),
    employeeIds,
  );

export const fetchPunchesByShiftId = (
  groupId: number,
  shiftId: number | string,
) =>
  get<PunchApiData[]>(
    `/v1/schedule/timepunches/by-group/${groupId}/by-shift/${shiftId}`,
  );

export const fetchPunchesByIds = (punchIds: number[], groupId: number) =>
  post<PunchApiData[]>(
    `/v1/schedule/timepunches/by-ids/groups/${groupId}`,
    punchIds,
  );

export const fetchPunchById = (punchId: number) =>
  get<PunchApiData>(`/v1/schedule/timepunches/${punchId}`);
