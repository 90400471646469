import { useCallback, useMemo } from 'react';

import { isNil } from '@shared/utils';
import {
  createTypedObjectFromEntries,
  getTypedObjectEntries,
} from '@shared/utils/typeUtils';

import { useSortOptionsWithAdditionalField } from '../DisplayOptionsFilters';
import {
  DEFAULT_OPTIONS,
  DISPLAY_OPTIONS_FILTERS,
  METRIC_DISPLAY_MODE_OPTIONS,
  METRIC_PAIRS,
  METRIC_PERIOD_DISPLAY_OPTIONS,
  SORT_DISPLAY_OPTIONS,
  VIEW_DISPLAY_OPTIONS,
} from '../DisplayOptionsFilters/constants';
import {
  SortOptionArr,
  TDisplayOptionsType,
  TMetricDisplayModeOptions,
  TMetricPairs,
  TMetricPeriodDisplayOptions,
} from '../DisplayOptionsFilters/types';
import { useFilters } from '../FiltersContext';
import { TScheduleFilters } from '../FiltersContext/types';

type ChangeViewDisplayOptionsProps = {
  name: (typeof VIEW_DISPLAY_OPTIONS)[number]['name'];
  value: boolean;
};
interface DisplayOptionsHandlerProps {
  options:
    | SortOptionArr
    | ReadonlyArray<TMetricPeriodDisplayOptions | TMetricDisplayModeOptions>;
  property: TDisplayOptionsType;
}

const getState = (filters: TScheduleFilters) => ({
  ...DEFAULT_OPTIONS,
  ...filters?.scheduleSelectedFilter?.employeeListDisplayOptions,
});

export const createInitialState = (filters: TScheduleFilters) => {
  const displayOptions = getState(filters);

  const viewDisplayOptionsValues = createTypedObjectFromEntries(
    VIEW_DISPLAY_OPTIONS.map(({ name, defaultValue }) => [
      name,
      displayOptions?.[name] ?? defaultValue,
    ]),
  );

  return {
    ...displayOptions,
    ...viewDisplayOptionsValues,
  };
};

export const useEmployeeDisplayOptions = () => {
  const { filters, handleChange } = useFilters();
  const sortOptionsWithAdditionalField =
    useSortOptionsWithAdditionalField(SORT_DISPLAY_OPTIONS);

  const createHandler = <T extends DisplayOptionsHandlerProps>({
    options,
    property,
  }: T) => {
    return function displayOptionsHandler(
      newValue: T['options'][number]['value'],
    ) {
      if (!options.find((option) => option.value === newValue)) {
        throw new Error(`There is no option with the value "${newValue}"`);
      }

      handleChange({
        employeeListDisplayOptions: {
          ...getState(filters?.data),
          [property]: newValue,
        },
      });
    };
  };

  const changeSorting = createHandler({
    options: sortOptionsWithAdditionalField,
    property: DISPLAY_OPTIONS_FILTERS.SORT,
  });

  const changeMetricPeriod = createHandler({
    options: METRIC_PERIOD_DISPLAY_OPTIONS,
    property: DISPLAY_OPTIONS_FILTERS.METRIC_PERIOD,
  });

  const changeMetricDisplayMode = createHandler({
    options: METRIC_DISPLAY_MODE_OPTIONS,
    property: DISPLAY_OPTIONS_FILTERS.DISPLAY_MODE,
  });

  const changeViewDisplayOptions = useCallback(
    ({ name, value }: ChangeViewDisplayOptionsProps) => {
      if (!VIEW_DISPLAY_OPTIONS.find((option) => option.name === name)) {
        throw new Error(
          `There is no display option with the name "${name}".` +
            `Please, check usage of "changeViewDisplayOptions" function`,
        );
      }
      if (isNil(value)) {
        throw new Error(
          '"changeViewDisplayOptions" function expect `value` param not to be nullish',
        );
      }

      handleChange({
        employeeListDisplayOptions: {
          ...getState(filters?.data),
          [name]: value,
        },
      });
    },
    [filters?.data, handleChange],
  );

  const changeMetric = useCallback(
    (newPair: TMetricPairs) => {
      const newValue = getTypedObjectEntries(METRIC_PAIRS).find(
        ([, pair]) => newPair === pair,
      )?.[0];

      if (isNil(newValue)) {
        throw new Error(
          `There is no metric option corresponding with "['${newPair[0]}', '${newPair[1]}']".` +
            `Please, check usage of "changeMetric" function`,
        );
      }

      handleChange({
        employeeListDisplayOptions: {
          ...getState(filters?.data),
          [DISPLAY_OPTIONS_FILTERS.METRIC]: newValue,
        },
      });
    },
    [filters?.data, handleChange],
  );

  const {
    sort,
    metric,
    metricCalculationPeriod,
    displayMode,
    ...viewDisplayOptionsValues
  } = createInitialState(filters?.data);

  const displayOptionsData = useMemo(
    () => ({
      metric: METRIC_PAIRS[metric],
      metricPeriodValue: metricCalculationPeriod,
      metricDisplayModeValue: displayMode,
      currentSorting:
        sortOptionsWithAdditionalField.find(
          (option) => option.value === sort,
        ) || SORT_DISPLAY_OPTIONS[0],
      viewDisplayOptionsValues,
    }),
    [
      displayMode,
      metric,
      metricCalculationPeriod,
      sort,
      sortOptionsWithAdditionalField,
      viewDisplayOptionsValues,
    ],
  );

  return useMemo(
    () => ({
      ...displayOptionsData,
      changeViewDisplayOptions,
      changeMetric,
      changeSorting,
      changeMetricPeriod,
      changeMetricDisplayMode,
    }),
    [
      changeMetric,
      changeMetricDisplayMode,
      changeMetricPeriod,
      changeSorting,
      changeViewDisplayOptions,
      displayOptionsData,
    ],
  );
};
