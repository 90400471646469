import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import { isOriginalError } from '@shared/containers/hooks/api/utils';

import { indexArrayByPropName } from '../../../../../shared/utils';
import { fetchEmployeesForPeriod } from '../../../../api/schedule/employees';
import { SCHEDULE_DATA_CACHE_TIME } from '../useFetchScheduleData';
import { useGetScheduleDataHookParams } from '../utils/useGetScheduleDataHookParams';

export const QUERY_KEY = '/v2/employee/workersof/by-group';

export const useEmployeesQuery = (isEnabled = true) => {
  const { currentGroupId, start, end, isPlaceholderFilterData } =
    useGetScheduleDataHookParams();
  const employeesQuery = useQuery({
    queryKey: [QUERY_KEY, currentGroupId, start, end],
    queryFn: () =>
      fetchEmployeesForPeriod(start, end, currentGroupId).then(
        (res) => res?.body,
      ),
    retry: (failureCount, error) => {
      if (isOriginalError(error) && error.status === 403) {
        return false;
      }
      return failureCount < 2;
    },
    enabled: !isPlaceholderFilterData && isEnabled,
    cacheTime: SCHEDULE_DATA_CACHE_TIME,
  });

  const indexedEmployees = useMemo(
    () => indexArrayByPropName(employeesQuery.data || []),
    [employeesQuery.data],
  );

  return useMemo(
    () => ({
      isLoading: employeesQuery.isLoading,
      isError: employeesQuery.isError,
      isSuccess: employeesQuery.isSuccess,
      errors: employeesQuery.error,
      data: indexedEmployees,
    }),
    [
      employeesQuery.error,
      employeesQuery.isError,
      employeesQuery.isLoading,
      employeesQuery.isSuccess,
      indexedEmployees,
    ],
  );
};
